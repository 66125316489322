import React from 'react' ;
import '../../scss/ZakazPage/zaktipsayt.scss';
import jsheadLg from '../../assets/language/zaktipsayt-lang.json';

function ZakTipsayt ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="zaktipsayt">
         <div className="zaktipsayt-center">

            {/* блок с названием раздела */}
            <div className="zaktipsayt-span">
               <div className="zaktipsayt-span-num">
                  <p>1</p>
               </div>
               <div className="zaktipsayt-span-txt">
                  <p>{lang.menu_01}</p>
               </div>
            </div>

            {/* блок с лендиногом */}
            <div className="zaktipsayt-land" id="zaklending">
               {/* блок с описанием и ценами */}
               <div className="land-left">
                  <div className="land-left-top">
                     <div className="land-left-top-text">
                        <p>{lang.menu_02}</p>
                        <ul>
                           <li>{lang.menu_03}</li>
                           <li>{lang.menu_04}</li>
                           <li>{lang.menu_05}</li>
                        </ul>
                     </div>
                     <div className="land-left-top-round">
                        <p className="red-round">150$</p>
                     </div>
                  </div>
                  <div className="land-left-down">
                     <div className="land-left-down-blue col-01">
                        <p>{lang.menu_06}</p>
                     </div>
                     <div className="land-left-down-red">
                        <p className="red-pos">+50$</p>
                     </div>
                     <div className="land-left-down-blue col-02">
                        <p>{lang.menu_07}</p>
                     </div>
                     <div className="land-left-down-red mobcol-01">
                        <p className="red-pos">+30$</p>
                     </div>
                  </div>
               </div>
               {/* блок с картинкой */}
               <div className="land-right">
                  <div className="land-right-img">
                     <img className="image" src="fon\site_01.png" alt=""/>
                  </div>
               </div>
            </div>

         {/* блок с корпоративным сайтом */}
         <div className="zaktipsayt-korp" id="zakvizit">
            {/* блок с картинкой */}
            <div className="korp-left">
               <div className="korp-left-img">
                  <img className="image" src="fon\site_02.jpg" alt=""/>
               </div>
            </div>

            {/* блок с описанием */}
            <div className="korp-right">
               <div className="korp-right-top">
                  <div className="korp-right-top-round">
                     <p className="red-round">300$</p>
                  </div>
                  <div className="korp-right-top-text">
                     <p>{lang.menu_08}</p>
                     <ul>
                        <li>{lang.menu_09}</li>
                        <li>{lang.menu_10}</li>
                        <li>{lang.menu_11}</li>
                     </ul>
                  </div>
               </div>
               <div className="korp-right-down">
                  <div className="korp-right-down-blue col-01">
                     <p>{lang.menu_12}</p>
                  </div>
                  <div className="korp-right-down-red">
                     <p className="red-pos">+60$</p>
                  </div>
                  <div className="korp-right-down-blue col-02">
                     <p>{lang.menu_13}</p>
                  </div>
                  <div className="korp-right-down-red mobcol-03">
                     <p className="red-pos">+80$</p>
                  </div>
               </div>
            </div>

         </div>

         {/* блок с интернет порталом и сервисом */}
         <div className="zaktipsayt-serv" id="zakkorp">
            {/* верхний блок с изображением сайта и зеленым фоном */}
            <div className="serv-top">
               <div className="serv-top-mobimg">
                  <img className="image" src="fon\site_04.png" alt=""/>
               </div>
               <div className="serv-top-deskimg">
                  <img className="image" src="fon\site_03.jpg" alt=""/>
               </div>
               <div className="serv-top-foncolor">
               </div>       
            </div>

            {/* нижний слок с описнаием и котом */}
            <div className="serv-down">
               <div className="serv-down-left">
                  <div className="serv-down-left-top">
                     <div className="serv-down-left-top-text">
                        <p>{lang.menu_14}</p>
                        <ul>
                           <li>{lang.menu_15}</li>
                           <li>{lang.menu_16}</li>
                           <li>{lang.menu_17}</li>
                        </ul>
                     </div>
                     <div className="serv-down-left-top-round">
                        <p className="red-round">500$</p>
                     </div>
                  </div>
                  <div className="serv-down-left-down">
                     <div className="serv-down-left-down-blue col-01">
                        <p>{lang.menu_18}</p>
                     </div>
                     <div className="serv-down-left-down-red">
                        <p className="red-pos">+70$</p>
                     </div>
                     <div className="serv-down-left-down-blue col-02">
                        <p>{lang.menu_19}</p>
                     </div>
                     <div className="serv-down-left-down-red mobcol-04">
                        <p className="red-pos">+70$</p>
                     </div>
                  </div>
               </div>
               <div className="serv-down-right">
                  <div className="serv-down-right-img">
                     <img className="image" src="img\g_009.gif" alt=""/>
                  </div>
               </div>
            </div>
         </div>

         {/* блок с интернет магазином */}
         <div className="zaktipsayt-zakshop" id="zakshop">
            <div className="zakshop-top">
               <div className="zakshop-top-left">
                  <div className="zakshop-top-left-coll">

                  </div>
                  <div className="zakshop-top-left-price">
                     <div className="zakshop-top-left-price-blue">
                        <p>{lang.menu_20}</p>
                     </div>
                     <div className="zakshop-top-left-price-red">
                        <p className="red-pos">+80$</p>
                     </div>
                  </div>
               </div>
               <div className="zakshop-top-right">
                  <div className="zakshop-top-right-img">
                     <img className="image" src="fon\site_05.png" alt=""/>
                  </div>
               </div>
            </div>
            <div className="zakshop-down">
               <div className="zakshop-down-top">
                  <div className="zakshop-down-top-text">
                     <p>{lang.menu_21}</p>
                     <ul>
                        <li>{lang.menu_22}</li>
                        <li>{lang.menu_23}</li>
                        <li>{lang.menu_24}</li>
                     </ul>
                  </div>
                  <div className="zakshop-down-top-round">
                     <p className="red-round">600$</p>
                  </div>
               </div>
               <div className="zakshop-down-down">
                  <div className="zakshop-down-down-blue col-01">
                     <p>{lang.menu_25}</p>
                  </div>
                  <div className="zakshop-down-down-red">
                     <p className="red-pos">+80$</p>
                  </div>
                  <div className="zakshop-down-down-blue col-02">
                     <p>{lang.menu_26}</p>
                  </div>
                  <div className="zakshop-down-down-red">
                     <p className="red-pos">+80$</p>
                  </div>
               </div>
            </div>

         </div>



         </div>
      </section>
   )
}

export default ZakTipsayt;