import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x011 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 1   // первая статья
   const i2 = 0   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>VBA#03 - Опции, настройки и окна</span>
               <img className="image" src="../../content/st-011/001.jpg" alt=""/>
               
               <p className="sp-01"><b>Microsoft Visual Basic</b> — язык программирования, разработанный корпорацией Microsoft. Как мы видим, от простого институтского проекта он переродился и плотно стал завязанным с Майкрософт. Язык Visual Basic унаследовал дух, стиль и отчасти синтаксис своего предка — языка BASIC, у которого есть немало диалектов. При этом важной особенностью, унаследованной от предка, является простота и дружелюбность с пользователями, например инструменты для визуального проектирования пользовательского интерфейса, редактор кода с возможностью IntelliSense. IntelliSense — технология автодополнения Microsoft, наиболее известная в Microsoft Visual Studio, которая дописывает название функции при вводе начальных букв. Также реализована подсветка синтаксиса, и инструменты для отладки приложений.</p>
               <p className="sp-01">Сегодня мы обсудим основные окна и настроки в VBA</p>
               <p className="sp-01"><b>Вкладка «Редактор»</b> — эта вкладка управляет окном кода и настройками Project Explorer.</p>
               <p className="sp-01"><b>Editor FormatTab</b> — эта вкладка управляет тем, как ваш код будет выглядеть в окне «Код».</p>
               <p className="sp-01"><b>Вкладка «Общие»</b> — эта вкладка управляет настройками отладки и обработки ошибок.</p>
               <p className="sp-01"><b>Вкладка «Стыковка»</b> — эта вкладка определяет, какие окна можно закреплять, а какие плавающие.</p>

               <img className="image" src="../../content/st-011/002.jpg" alt=""/>
               <p className="sp-02">Вкладка «Редактор»</p>
               <p className="sp-01">Эта вкладка управляет окном кода и настройками Project Explorer.</p>
               <p className="sp-01"><b>Автоматическая проверка синтаксиса</b> — определяет, должен ли Visual Basic автоматически проверять правильность синтаксиса после ввода строки кода. Это полезно при первоначальном изучении VBA, хотя после того, как вы ознакомитесь с синтаксисом и отредактируете большое количество кода, вы можете отключить эту функцию. Когда этот флажок снят, строка отображается другим цветом (по умолчанию красным), что указывает на синтаксическую ошибку. </p>
               <p className="sp-01"><b>Требование объявления переменных</b> — это обязательно следует включать, поскольку это предотвращает определение неправильных переменных в вашем коде. Установка этого флажка автоматически добавит оператор Option Explicit ко всем новым модулям (еще не существующих). Когда используется этот оператор, вы должны явно объявить все свои переменные перед их использованием. По умолчанию этот флажок не установлен.</p>
               <p className="sp-01"><b>Auto List Members</b> — это также обязательно нужно подключать, так как это поможет вам найти правильные свойства и методы для любых объектов. Вы можете вставить соответствующее свойство или метод, выбрав его, а затем нажав Tab. Это особенность IntelliSense. Отмечено по умолчанию.</p>
               <p className="sp-01"><b>Auto Quick Info</b> — тоеж очень полезная натсройка, так как она отображает информацию о функциях и соответствующих им параметрах. Это особенность Intellisense.</p>
               <p className="sp-01"><b>Советы по автоматическим данным</b> - Отображает значение переменной или выражения при наведении курсора. Это доступно только в режиме прерывания, когда вы выполняете пошаговое выполнение кода. Отмечено по умолчанию.</p>
               <p className="sp-01"><b>Автоматический отступ</b> — повторяет отступ предыдущей строки при нажатии Enter — все последующие строки будут начинаться с отступа. Вы можете нажать Backspace, чтобы удалить автоматические отступы. Вы должны использовать клавишу Tab для отступа вашего кода (не пробел). Затем вы также можете использовать (Shift + Tab), чтобы удалить отступ.</p>
               <p className="sp-01"><b>Ширина вкладки</b> - Определяет количество символов, которые передаются табуляцией. Может быть от 1 до 32 символов (по умолчанию 4). Отступы вашего кода облегчают чтение. Изменение этого параметра может вызвать проблемы при чтении или редактировании кода на разных машинах. По умолчанию 4.</p>

               <img className="image" src="../../content/st-011/003.jpg" alt=""/>
               <p className="sp-01"><b>Редактирование текста с помощью перетаскивания</b> — позволяет перетаскивать выделенный текст из окна кода в окна Immediate & Watch.</p>
               <p className="sp-01"><b>Отображать процедуры в одном модуле</b> - это решает, видите ли вы только одну процедуру за раз или все процедуры в виде единого прокручиваемого списка в любых новых модулях (не существующих). Когда эта опция отключена, вы будете видеть только одну процедуру за раз.</p>
               <p className="sp-01"><b>Разделитель процедур</b> — позволяет отображать или скрывать разделительные полосы, которые появляются в конце каждой процедуры в окне кода. Отмечено по умолчанию.</p>

               <img className="image" src="../../content/st-011/004.jpg" alt=""/>
               <p className="sp-02">Вкладка «Формат редактора»</p>
               <p className="sp-01">Эта вкладка управляет тем, как ваш код будет выглядеть в окне кода. Все эти параметры позволяют вам контролировать цвет текста и цвета фона для различных элементов в вашем окне кода. Большинство людей не меняют эти параметры. </p>
               <p className="sp-01"><b>Цветовой код</b> — можно настроить форматирование для всех этих элементов: обычный текст, текст выбора, текст синтаксической ошибки, текст точки выполнения, текст точки остановки, текст комментария, текст ключевого слова, текст идентификатора, текст закладки, текст возврата вызова.</p>
               <p className="sp-04">Foreground — это цвет, определяющий цвет текста. <br></br>
               Background — это цвет, определяющий цвет фона текста. <br></br>
               Indicator — это цвет индикатора маржи, который будет отображаться рядом с этим конкретным товаром.</p>
               <p className="sp-01"><b>Шрифт</b> - шрифт, используемый для отображения вашего кода. Шрифт по умолчанию — Courier New (западный). Чтобы сделать ваш код легко читаемым, вы всегда должны использовать шрифт фиксированной ширины (т. е. все символы имеют одинаковую ширину).</p>
               <p className="sp-01"><b>Размер</b> — размер шрифта, используемый для отображения вашего кода. Размер шрифта по умолчанию — 10.</p>
               <p className="sp-01"><b>Полоса индикатора поля</b> — это поле, которое отображается слева от вашего модуля кода. Эта опция позволяет переключать видимость полосы индикатора полей.</p>

               <img className="image" src="../../content/st-011/005.jpg" alt=""/>
               <p className="sp-02">Вкладка «Общие»</p>
               <p className="sp-01">Эта вкладка управляет настройками отладки и обработки ошибок</p>
               <p className="sp-01"><b>Показать сетку</b> — определяет, отображать ли сетку в пользовательской форме. Это может быть полезно, так как может помочь вам выровнять элементы управления. Установлена по умолчанию.</p>
               <p className="sp-04">Ширина - ширина ячеек сетки от 2 до 60 точек. По умолчанию 6.<br></br>
               Высота - высота ячеек сетки от 2 до 60 точек. Значение по умолчанию 6.</p>
               <p className="sp-01"><b>Выровнять элементы управления по сетке</b> — автоматически размещает внешние края элементов управления на линиях сетки.</p>
               <p className="sp-01"><b>Уведомлять перед потерей состояния</b> — определяет, будете ли вы получать сообщение, уведомляющее вас о том, что действие приведет к сбросу всех переменных уровня модуля перед запуском проекта. По умолчанию этот флажок не установлен.</p>
               <p className="sp-01"><b>Break on All Errors </b> — если этот флажок установлен, любая ошибка приводит к переходу проекта в режим остановки, независимо от того, активен ли обработчик ошибок и находится ли код в модуле класса. Это может быть полезно при отладке. По умолчанию этот флажок не установлен.</p>
               <p className="sp-01"><b>Прерывание в модуле класса</b> - любая необработанная ошибка, возникающая в модуле класса, приводит к тому, что проект переходит в режим останова в строке кода в модуле класса, вызвавшем ошибку. По умолчанию этот флажок не установлен.</p>
               <p className="sp-01"><b>Перерыв на необработанные ошибки</b> - (выставлено по умолчанию) Если обработчик ошибок активен, ошибка перехватывается без входа в режим прерывания. Если нет активного обработчика ошибок, ошибка переводит проект в режим остановки. Однако необработанная ошибка в модуле класса приводит к тому, что проект переходит в режим остановки в строке кода, вызывающей нарушение процедуры класса.</p>
               <p className="sp-01"><b>Компиляция по требованию</b> — определяет, компилируется ли код, когда он необходим, позволяя приложению запускаться раньше, или проект полностью компилируется до его запуска. Отмечено по умолчанию.</p>
               <p className="sp-03">Если этот флажок не установлен, вам будет выдано сообщение об ошибке, если проект не компилируется. Когда флажок установлен, код компилируется автоматически при сохранении/закрытии проекта. Удаление этой опции приведет к компиляции всего проекта в начале и немедленно предупредит вас о любых синтаксических ошибках.</p>
               <p className="sp-01"><b>Фоновая компиляция</b> - определяет, используется ли время простоя во время выполнения для завершения компиляции проекта в фоновом режиме. Фоновая компиляция может улучшить скорость выполнения во время выполнения. Эта функция недоступна, если не выбран параметр «Компилировать по запросу».</p>
               <p className="sp-01"><b>Show ToolTips</b> — отображать ли всплывающие подсказки для кнопок панели инструментов в редакторе Visual Basic. Отмечено по умолчанию.</p>
               <p className="sp-01"><b>Свернуть проект и скрыть окна</b> — определяет, будут ли автоматически закрываться окна проекта, пользовательской формы, объекта или модуля, когда проект сворачивается в окне Project Explorer.</p>

               <img className="image" src="../../content/st-011/006.jpg" alt=""/>
               <p className="sp-02">Вкладка «Привязка» (dockable)</p>
               <p className="sp-01">Эта вкладка определяет, какие из окон можно закреплять, а какие плавающие. Когда окно является закрепляемым, оно может быть привязано к одной из сторон редактора Visual Basic. Всякий раз, когда вы перемещаете закрепляемое окно, появляется прямоугольник, показывающий, где оно будет закреплено, если отпустить его из текущей позиции. Это глобальные параметры, используемые в Excel, Word, PowerPoint, Access и Outlook.</p>
               <p className="sp-02">Окна в VBA - Обозреватель проектов </p>
               <p className="sp-01">А теперь поговрим о самих окнах, ведь это то, в чем вы будете работать постоянно и что поможет вам в вашей работе.</p>
               <p className="sp-01"><b>Обозреватель проектов</b> — отображает иерархический список проектов и всех элементов, содержащихся в каждом проекте и на которые он ссылается. Отображает все рабочие книги, открытые в данный момент (включая надстройки и скрытые рабочие книги). Панель идентична Проводнику Windows. Каждый проект или узел можно расширить или закрыть. Вы можете добавить в проект столько модулей кода, сколько захотите, хотя для небольших приложений, вероятно, проще хранить все ваши макросы (процедуры и функции) в одном модуле.</p>
               <p className="sp-01">Каждый проект может содержать несколько различных типов объектов, объектов приложений, стандартных модулей, модулей классов, объектов пользовательских форм и ссылок. Объект пользовательской формы на самом деле состоит из двух компонентов: визуального диалогового окна и связанного с ним модуля кода. Наряду с этим, каждое приложение также имеет определенные объекты (например, рабочие книги и рабочие листы в Excel; документы и шаблоны в Word).</p>
               <img className="image" src="../../content/st-011/007.jpg" alt=""/>
               <p className="sp-05">СТАНДАРТНЫЙ МОДУЛЬ</p>
               <p className="sp-01">Самый распространенный тип модулей, который используется в большинстве случаев. Именно в них макрорекордер создает записываемые макросы. Все коды и процедуры в таких модулях пишутся вручную, либо копируются из других источников(другого модуля, с этого сайта и т.п.). В основном именно в стандартных модулях содержится большая часть кодов. Они предназначены для хранения основных процедур и Public переменных, которые могут быть доступны впоследствии из любого модуля.</p>
               <p className="sp-05">МОДУЛЬ ЛИСТА/КНИГИ</p>
               <p className="sp-01">Для каждого листа книги имеется свой отдельный модуль. Размещая код в модуле листа следует помнить, что при копировании или переносе данного листа в другую книгу код так же будет скопирован, т.к. является частью листа. Это и плюс и минус одновременно. Плюс в том, что разместив код в модуле листа можно использовать этот лист в качестве шаблона для распространения со своими кнопками вызова этих кодов(в том числе создания книг кодом) и весь функционал будет доступен. Минус же заключается в некоторых нюансах обращения к ячейкам(подробнее можно ознакомиться в этой статье: Как обратиться к диапазону из VBA) и необходимости размещения ВСЕХ используемых процедур в этом листе, иначе при переносе в другие книги коды могут работать с ошибками.</p>
               <p className="sp-01">Плюс, в модуле листа содержатся встроенные событийные процедуры, каждая из которых отвечает за обработку определенного события на этом листе. Посмотреть их можно так: выбираете объект(на рисунке ниже список в левой части) Worksheet, а в правом списке выбираете событие(в этом списке все процедуры, доступные для выбранного листа). Но к нему можно обратиться и из обычного модуля, просто прописав так сказать полный путь.</p>
               <p className="sp-05">МОДУЛИ ФОРМ</p>
               <p className="sp-01">Содержатся внутри Пользовательской формы (UserForm) и её объектов. В Пользовательских формах в основном все завязано именно на событийных процедурах самой формы и на элементах этой формы (Кнопки, ТекстБоксы, КомбоБоксы (выпадающие списки) и т.д.). Очень удобно использовать Пользовательские формы в своих приложениях для общения с пользователем. т.к. через формы очень удобно отслеживать действия пользователя и можно запретить доступ к листам с данными, путем их скрытия.</p>
               <p className="sp-05">МОДУЛЬ КЛАССА</p>
               <p className="sp-01">В большинстве случаев создается специально для отслеживания событий различных объектов. Модуль класса (Class Module) – это модуль, содержащий программные коды, которые реализуют работу пользовательских классов. В подавляющем большинстве случаев создается специально для отслеживания событий различных объектов. Напрмиер кнопой или ещ чего, то ест код для отдельных объектов, можно так сказать. Наприимер, модуль листа – это тоже модуль класса, так как сам лист это объект, ну или класс объекта (его вид – лист, или вид – книга, или вид – кнопка).</p>

               <img className="image" src="../../content/st-011/008.jpg" alt=""/>
               <p className="sp-02">Окно свойств</p>
               <p className="sp-01">Отображает все свойства объекта (относящиеся к активному проекту, пользовательским формам или элементу управления).</p>
               <p className="sp-01"><b>Поле объектов оконных элементов</b> - содержит список выбранных в данный момент объектов. Видны только объекты из активной формы. Если вы выберете несколько объектов, общие для объектов свойства и их настройки, основанные на первом выбранном объекте, появятся на вкладках списка свойств.</p>
               <p className="sp-01"><b>Алфавитная вкладка</b> — в алфавитном порядке перечислены все свойства выбранного объекта, которые можно изменить во время разработки, а также их текущие настройки. Вы можете изменить настройку свойства, выбрав имя свойства и введя или выбрав новую настройку.</p>
               <p className="sp-01"><b>Вкладка «Категории»</b> — список всех свойств выбранного объекта по категориям. Например, BackColor, Caption и ForeColor находятся в категории «Внешний вид». Вы можете свернуть список, чтобы увидеть категории, или развернуть категорию, чтобы увидеть свойства. Когда вы разворачиваете или сворачиваете список, вы видите значок плюса (+) или минуса (-) слева от названия категории.</p>
               <p className="sp-03">Важно помнить, что некоторые свойства доступны только для чтения и не могут быть изменены, а другие могут быть изменены только во время выполнения из кода. Однако эти свойства обычно не отображаются в окне свойств.</p>

               <img className="image" src="../../content/st-011/009.jpg" alt=""/>
               <p className="sp-02">Окно кода</p>
               <p className="sp-01">Отображает код, связанный с элементом, выбранным в окне Project Explorer. Окна кода могут содержать четыре различных типа кода:</p>
               <ul>
                  <li><b>Подпрограммы</b> — набор инструкций, выполняющих определенную задачу</li>
                  <li><b>Функции</b> — набор инструкций, выполняющих определенную задачу, а затем возвращающих значение или массив.</li>
                  <li><b>Свойства</b> — используются в модулях классов</li>
                  <li><b>Объявления</b> — используются для объявления переменных, которые можно использовать и на которые можно ссылаться из любого места модуля (или проекта).</li>
               </ul>
               <p className="sp-01"><b>Объекты</b> - отображает имя выбранного объекта. Щелкните стрелку справа от списка, чтобы отобразить список всех объектов, связанных с формой.</p>
               <p className="sp-01">Поле <b>«Процедуры/события»</b> - содержит список всех событий, распознаваемых Visual Basic для формы или элемента управления, отображаемого в поле «Объект». Когда вы выбираете событие, процедура события, связанная с этим именем события, отображается в окне кода.</p>
               <p className="sp-01">Если в поле «Объект» отображается (Общее), в поле «Процедура» перечислены все объявления и все общие процедуры, созданные для формы. Если вы редактируете код модуля, в поле «Процедура» перечислены все общие процедуры модуля. В любом случае процедура, выбранная вами в поле «Процедура», отображается в окне «Код».</p>
               <p className="sp-01">Все процедуры в модуле отображаются в одном прокручиваемом списке, отсортированном в алфавитном порядке по именам. При выборе процедуры с помощью раскрывающихся списков в верхней части окна кода курсор перемещается на первую строку кода в выбранной процедуре. Один модуль VBA может хранить любое количество любого из вышеперечисленного, и то, как они организованы, зависит от вас.</p>
               <p className="sp-01"><b>Сплит-бар</b> - eсли перетащить эту полосу вниз, окно кода разделится на две горизонтальные панели, каждая из которых прокручивается отдельно. Затем вы можете одновременно просматривать разные части кода.</p>

               <img className="image" src="../../content/st-011/010.jpg" alt=""/>
               <p className="sp-02">Immediate Window </p>
               <p className="sp-01">Отображение переменных и выполнение строк кода. Окно Immediate используется в VBA Excel при написании и отладке процедур для тестирования отдельных строк кода, проверки текущих значений переменных и в качестве калькулятора.</p>
               <ul>
                  <li>проверка куска кода (после написания надо нажать Enter)</li>
                  <li>проверка переменных</li>
               </ul>
               <p className="sp-03">Можно использовать как калькулятор. Если написать в окне Print или в общем окне знак ?, то можно сразу считать примеры, а также значения констант и т.д. (после написания надо нажать Enter)</p>
               <p className="sp-04">Для отображения в окне Immediate значений переменных используется метод Print класса Debug (Debug.Print). Debug.Print можно псиать сколько угодно, все переменные отобразяться в этом окне. Также При работе с переменными можно изменять их значение непосредственно в окне Immediate. Введите строку с присвоением переменной нового значения и нажмите клавишу Enter. Значение переменной будет перезаписано.</p>

               <img className="image" src="../../content/st-011/011.jpg" alt=""/>
               <p className="sp-02">Окно Locals</p>
               <p className="sp-01">Отображает все переменные, используемые в текущей подпрограмме. Автоматически отображает все объявленные переменные в текущей процедуре и их значения. Ничего заносить не надо, он сам отображает в режиме отладки (по F8)</p>
               <p className="sp-01"><b>Expression</b>	- предоставляет список имен переменных.</p>
               <p className="sp-01"><b>Value (Значение)</b> - перечислены значения переменной.</p>
               <p className="sp-01"><b>Тип</b> - список типов переменной. Данные в этом столбце изменять нельзя.</p>
               <p className="sp-01">Кстати, первая переменная в списке — это переменная специального модуля, которая может быть расширена для отображения всех переменных уровня модуля в текущем модуле. Для модуля класса определяется переменная "Me" системы. Для стандартных модулей первой переменной является "name of the current module".</p>
               <p className="sp-03">Глобальные переменные и переменные в других проектах недоступны из окна Locals. Данные в этом столбце изменять нельзя.</p>

               <img className="image" src="../../content/st-011/012.jpg" alt=""/>
               <p className="sp-02">Watch Window </p>
               <p className="sp-01">Отображает текущее значение переменных по мере выполнения кода. Отличие от Locals используется для отладки больших сложных программ и позволяет контролировать отдельные выражения, значения переменных и т.д. Для добавления выражения нужно щелкнуть по нему мышью и выбрать команду меню Debug — Add Watch (Отладка — Контролировать). Тогда активизируется окно Add Watch</p>
               <p className="sp-04">В поле параметров Context (Контекст) следует выбрать область контроля: процедура или лист, ну или только функция. </p>
               <p className="sp-01">Тип <b>Watch Expression</b> (Наблюдать за выражением) определяет вывод в окно Watch значения наблюдаемого выражения. В режиме отладки можно просмотреть это выражение.</p>
               <p className="sp-01">Тип <b>Break When Value Is True</b> (Прервать работу программы, если выражение выполняется) прерывает работу программы и переводит ее в режим отладки при выполнении определенного в окне Add Watch условия. Напрмиер, выражение а больше 4, установим эту опцию и запустим программу на выполнение. Когда значение переменной превысит 4, работа программы будет остановлена и переведена в режим отладки.</p>
               <p className="sp-01">Тип <b>Break When Value Changes</b> (Прервать работу программы при изменении значения) прерывает выполнение программы при каждом изменении контролируемой переменной или выражения и переводит се в режим отладки.</p>


         

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x011