import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/ZakazPage/zakanimation.scss';
import jsheadLg from '../../assets/language/zakanimation-lang.json';

function ZakAnimation ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="zakanimation">
         <div className="zakanimation-center">

            {/* блок с названием раздела */}
            <div className="zakanimation-span">
               <div className="zakanimation-span-num">
                  <p>3</p>
               </div>
               <div className="zakanimation-span-txt">
                  <p>{lang.menu_01}</p>
               </div>
            </div>

            {/* блок с роботом и описанием */}
            <div className="zakanimation-blocktop">
               <div className="blocktop-left">
                  <div className="blocktop-left-li">
                     <ul>
                        <li>{lang.menu_02}</li>
                        <li>{lang.menu_03}</li>
                        <li>{lang.menu_04}</li>
                        <li>{lang.menu_05}</li>
                        <li>{lang.menu_06}</li>
                     </ul>
                  </div>
                  <div className="blocktop-left-txt">
                     <p>{lang.menu_07}</p>
                  </div>
               </div>
               <div className="blocktop-right">
                  <div className="blocktop-right-img">
                     <img className="image" src="img\g_010.gif" alt=""/>
                  </div>
               </div>
            </div>
            {/* блок с котом и описанием */}
            <div className="zakanimation-blockdown">
               <div className="blockdown-left">
                  <div className="blockdown-left-img">
                     <img className="image" src="img\g_011.gif" alt=""/>
                  </div>
               </div>
               <div className="blockdown-right">
                  <div className="blockdown-right-txt">
                     <p>{lang.menu_08}</p>
                  </div>
                  <div className="blockdown-right-li">
                     <ul>
                        <li>{lang.menu_09}</li>
                        <li>{lang.menu_10}</li>
                        <li>{lang.menu_11}</li>
                        <li>{lang.menu_12}</li>
                     </ul>
                  </div>
               </div>
            </div>

         </div>
      </section>
   )
}

export default ZakAnimation;