import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x001 = ({langID}) => {
   window.scroll(0, 0) 
   const i1 = 1   // первая статья
   const i2 = 2   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
               <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>VBA#01 - История VisualBasic</span>
               <img className="image" src="../../content/st-001/001.jpg" alt=""/>
               
               <p className="sp-02">Для чего нужен VBA</p>
               <p className="sp-01"><b>VBA</b> позволяет уменьшить риск технических ошибок и, что главное, риск человеческой ошибки, что довольно высок при работе с большими объёмами данных, цифр и т.д.  Он позволяет автоматизировать многие процессы, написав команды и программы для повторяющихся действия. А также:</p>
               <ul>
                  <li>собирать формы и отчеты из таблиц и данных</li>
                  <li>создавать свои формы для ручного ввода информации</li>
                  <li>писать собственный функции для работы</li>
                  <li>собирать данные из разных файлов и собрать их в один</li>
                  <li>переводить данные в формат PDF (например), World и т.д., и даже отправлять их по почете</li>
               </ul>
               <p className="sp-01">В общем это мощный инструмент для работы и автоматизации своей работы при должных знаниях (причем тут не нужно быть программистом), простых действиях, быстро и легко, и это позволит вам работать более эффективно.  </p>
               <p className="sp-01">Макросы пригодятся всем, неважно, где вы работаете, если вы пользуетесь екселем, и даже если не пользуетесь, но вам нужна какая-то простая программка для сбора и сортировки данных, и т.д. То есть для работы. это и в банковской сфере, отчетности, это бухгалтерия, формы учета, контроля, для бизнесменов это способ автоматизировать их отчетность и бухгалтерию, создание заказ-нарядов, да все что угодно, налоговой отчётности. </p>
               <p className="sp-01">И даже в простых жизненных ситуациях он поможет, для ведения домашней бухгалтерии, составления графика своей работы, учебы детей и прочее. Хочется свою программку для учета калорий – пожалуйста, график посадки огурцов с напоминалкой на почту – тоже легко. </p>
               
               <img className="image" src="../../content/st-001/002.jpg" alt=""/>
               <p className="sp-02">Первая версия Basic – самое начало</p>
               <p className="sp-01">Чтобы разобраться, что такое Макросы и VBA в частности, нужно немного окунуться в историю создания языка Бейсик (<b>BASIC</b>, сокращение от англ. <b>Beginner's All-purpose Symbolic Instruction Code</b> — универсальный код символических инструкций для начинающих) — семейство высокоуровневых языков программирования. Да и это название просто было созвучно слову «базовый», ведь сам язык разрабатывался как язык для начинающих, студентов и для обучения школьников азам програмированию. Да и вообще популяризации компьютерных технологий в те времена, когда ПС все больше выходили на мировой уровень, входили в каждый дом и т.д.</p>
               <p className="sp-03">Бейсик был придуман в 1964 году преподавателями Дартмутского Колледжа Джоном Кемени и Томасом Курцем, и под их руководством был реализован командой студентов колледжа. Со временем, когда стали появляться другие диалекты, изначальную реализацию стали называть Dartmouth BASIC.</p>
               <p className="sp-01">Основным достоинством и преимуществом данного языка было то, что он предназначался для более «простых» пользователей, не столько заинтересованных в скорости исполнения программ, сколько просто в возможности использовать компьютер для решения своих задач, не имея специальной подготовки. В будущем язык становился все более адаптивным для пользователей, показывал сообщения об ошибках, не требовал понимания, как работают компиляторы, простого машинного языка, как работают оболочки и аппаратное обеспечение. </p>
               <p className="sp-01">Из-за более узкого спектра применения, что на более профессиональный манер можно перевести, что Бейсик являлся языком DSL – предметно-ориентированным, то есть специализированным для конкретной области применения, он гораздо быстрее работал на слабых машинах и в простых небольших программах. Но, кстати, он также давал возможность расширения функциональности, доступную опытным программистам.</p>
               <p className="sp-01">Язык был основан частично на Фортране II и частично на Алголе 60, с добавлениями, что Вам наверняка не о чем не говорит. Скажу просто, что это одни из первых профессиональных языков программирования общего применения. Но они были сложные, и для работы, и для обучения, и именно для этого и задумывался Бейсик – как просто более упрощенная их версия – язык сочен небольшим количество команд. </p>
               <p className="sp-04">Вопреки распространённому убеждению, в момент своего появления это был компилируемый язык. Если вы не знаете, то <b>Компилятор</b> — программа, переводящая написанный на языке программирования текст в набор машинных кодов. Машинный код можно рассматривать как примитивный язык программирования, нули, единицы и чуть более сложные их версии, так сказать, не будем вдаваться в подробности. Хотя вполне возможно создавать программы прямо в машинном коде, сейчас это делается редко в силу громоздкости кода и трудоёмкости последующего управления поддержки. Поэтому подавляющее большинство программ пишется на языках более высокого уровня и транслируется в машинный код компиляторами.</p>
               <p className="sp-01">Несмотря на то, что язык уже использовался на нескольких мини-компьютерах, его настоящее распространение началось с появления на микрокомпьютере Альтаир 8800. Многие языки программирования были слишком большими, чтобы поместиться в небольшую память, которую пользователи таких машин могли себе позволить. Для машин с таким медленным носителем, как бумажная лента (позднее — аудиокассета), и без подходящего текстового редактора такой небольшой язык, как Бейсик, был отличной находкой.</p>
               <p className="sp-01">Кстати, не все знают, что знаменитые Пол Аллен и Уильям Гейтс в далеком 1975 году как раз начали разрабатывать интерпретатор Бейсика для персонального компьютера Altair, основав в дальнейшем компанию <b>Microsoft</b>, ставшую одной из крупнейших компьютерных империй. Basic стал первым продуктом, выпущенным этой компанией - <b>Altair BASIC</b>. Он был успешно перенесен на другие компьютеры, такие как Apple, Commodore и Atari. Затем интерпретатор был написан для только что появившегося компьютера IBM PC. Он не только поставлялся как программа, но и зашивался в ПЗУ компьютера. Многие ветераны, возможно, еще помнят времена, когда после включения машины даже при отсутствии винчестера или дискет можно было писать программы на встроенном Бейсике.</p>
               <p className="sp-01">Итого, по итогу, Basic как первоначальный язык был создан для создания дополнительных возможностей в программах и ОС. Его основной задачей было предоставить студентам-непрограммистам возможность после минимального обучения самостоятельно писать простейшие программы для собственных нужд, чтобы не отвлекать на эту работу специалистов. Этой задаче язык вполне соответствует.</p>
               
               <img className="image" src="../../content/st-001/003.jpg" alt=""/>
               <p className="sp-02">Развитие языка</p>
               <p className="sp-01">В 1970—1980 годах Бейсик получил свое развитие в виде множества версий и диалектов языка высокого уровня, в значительной степени несовместимых между собой. Практически все более мощные компьютеры также имели интерактивные Бейсик-системы. Было создано несколько новых версий Бейсика для платформы IBM PC. Microsoft продавала Бейсик для MS-DOS/PC DOS, включая <b>IBM Advanced BASIC</b> (BASICA), <b>GW-BASIC</b> (модификация BASICA, не требующая «прошивки» от IBM) и <b>QuickBASIC</b>. Это полностью компилируемый язык, с нормальными структурными конструкциями, пользовательскими типами данных, причем еще и совместимый со старыми версиями (Basic и GW Basic). По тем временам это был большой шаг вперед и Basic стало возможным использовать наравне с Pascal или С.</p>
               <p className="sp-04">Кстати, в середине 1980-х Бейсик стал основным языком в сложных моделях программируемых калькуляторов, которые как раз к этому времени достигли мощности, допускающей применение полноценного языка высокого уровня. Применение Бейсика в качестве основного языка высокого уровня программируемых калькуляторов продолжается по сей день; в настоящее время именно «калькуляторный» Бейсик более всего похож на изначальный вариант 1960-80х годов, например, реализация для калькуляторов CITIZEN содержит всего 14 ключевых слов (не считая математических функций) и допускает только односимвольные имена переменных.</p>
               <p className="sp-01">К концу 1980-х годов персональные компьютеры стали намного мощнее и предоставляли возможности (такие, как графический интерфейс пользователя), использование которых в Бейсике было уже не столь удобным. Конечно, язык тоже развивался: расширился словарь и набор конструкций языка, появились сложные ветвления, именованные подпрограммы и функции, длинные имена переменных. </p>
               <p className="sp-01">Вторую жизнь Бейсик получил с появлением <b>Visual Basic</b> от Microsoft. Это был инструмент для быстрого создания практических и полезных программ для Windows при невысокой квалификации программиста и главную роль здесь сыграла удачная реализация графического интерфейса пользователя, скрывавшая от разработчика детали программирования и позволявшая сосредоточиться на задаче.</p>
               <p className="sp-01">Тогда же Microsoft создала вариант под названием <b>WordBasic</b> и использовала его в MS Word до появления Word 97, и в то же время появился первоначальный вариант <b>Visual Basic for Applications</b> (VBA), который был встроен в Microsoft Excel 5.0 в 1993 году, затем в Access 95 в 1995 году, а после и во все остальные инструменты, входящие в пакет Microsoft Office, Internet Explorer 3.0 и выше, а также Microsoft Outlook, включая интерпретатор VBScript – о нем чуть позже.</p>
               <p className="sp-01">Синтаксис языка напоминает Фортран и многие элементы — явные заимствования из него. Язык задумывался для обучения, поэтому его конструкции максимально просты. Как и в других языках программирования, ключевые слова взяты из английского языка. Основных типов данных два: строки и числа. С появлением версии Visual Basic, а также различных его модификаций (таких как VBA), в языке появились многие другие типы данных и дополнения, типичные для современных языков программирования (например, такие, как объектная модель). </p>
               <p className="sp-03">Ранние версии Бейсика существенно отличаются от современных диалектов и сегодня практически не используются, кроме как для поддержки. </p>
               <p className="sp-01">Практически все недостатки, присущие ранним версиям языка, были исправлены, и программирование на современных диалектах Бейсика мало отличается от использования других языков и сред. Оборотной стороной процесса модификации Basic является то, что язык стал гораздо объёмнее и сложнее, его освоение требует больше времени и усилий.</p>
               <p className="sp-01">Несмотря на популярность, в 2001 году Microsoft отказалась от дальнейшей поддержки Visual Basic в пользу принципиально нового языка <b>Visual Basic.NET</b> — в этой реализации язык был снова кардинально пересмотрен, и в текущем состоянии он практически совпадает по набору возможностей c языком C#, отличаются только детали синтаксиса и ключевые слова. Таким образом, Basic перерос из средства для создания программ любителям-непрофессионалам до полноценного средства создания программ.</p>
               <p className="sp-01">Но между ними еще был простой Microsoft Visual Basic, а также, помимо написания полноценных программ, как мы уже сказали, Бейсик широко применяется как основа для различных скриптовых языков (например, макросы в пакете Microsoft Office пишутся на диалекте VBA), который также получил дальнейшее развитие и на нем работают до сих пор.</p>

               <img className="image" src="../../content/st-001/004.jpg" alt=""/>
               <p className="sp-02">Начало Microsoft Visual Basic</p>
               <p className="sp-01"><b>Microsoft Visual Basic</b> — язык программирования, разработанный корпорацией Microsoft. Как мы видим, от простого институтского проекта он переродился и плотно стал завязанным с Майкрософт.  Язык Visual Basic унаследовал дух, стиль и отчасти синтаксис своего предка — языка BASIC, у которого есть немало диалектов. При этом важной особенностью, унаследованной от предка, является простота и дружелюбность с пользователями, например инструменты для визуального проектирования пользовательского интерфейса, редактор кода с возможностью IntelliSense — технология автодополнения Microsoft, наиболее известная в Microsoft Visual Studio, которая дописывает название функции при вводе начальных букв. Также реализована подсветка синтаксиса, и инструменты для отладки приложений.</p>
               <p className="sp-01">Visual Basic также является хорошим средством быстрой разработки приложений баз данных для операционных систем семейства Microsoft Windows. Множество готовых компонентов, поставляемых вместе со средой, призваны помочь программисту сразу же начать разрабатывать бизнес-логику бизнес-приложения, не отвлекая его внимание на написание кода запуска программы, подписки на события и других механизмов, которые VB реализует автоматически.</p>
               <p className="sp-01">В начале был <b>Visual Basic 1.0</b> для Microsoft Windows. За основу языка был взят синтаксис QBasic, а новшеством, принесшим затем языку огромную популярность, явился принцип связи языка и графического интерфейса. Этот принцип был разработан Аланом Купером (Alan Cooper) и реализован в прототипе Tripod (также известном как Ruby)</p>
               <p className="sp-01"><b>Visual Basic 6.0</b> стала последней версией. Microsoft изменила политику в отношении языков семейства Basic и вместо развития Visual Basic, был создан абсолютно новый язык Visual Basic.NET, благодаря которой удалось оптимизировать больше процессов, дать большую совместимость с другими языками и программами на них, использующую общие компоненты и библиотеки классов, и в том числе, естественно, от самой Майкрософт, </p>
               <p className="sp-01">Но при этом, Visual Basic.NET не позволяет программировать по-старому, потому что по сути является совершенно другим языком, таким же, как и любой другой язык программирования для платформы .NET. Индивидуальность языка, так же как и его преимущества (простота, скорость создания программ, лёгкость использования готовых компонентов) при использовании в среде .NET не имеют такого значения, как раньше — всё сосредоточено на возможностях самой платформы .NET, на её библиотеках классов. </p>
               <p className="sp-01">На данный момент, если мы говорим о языке Basic , мы говорим о классических версиях языка 4.0 — 6.0 Visual, которые больше не поддерживаются, также, как и его прародитель сам язык Basic, и в большей спенени мы подразумеваем: <b>Visual Basic for Applications</b> (VBA) и <b>Visual Basic Scripting Edition</b> (VBScript) и о языке для платформы .NET — <b>Visual Basic .NET</b>.</p>
               <p className="sp-01">При этом, в марте 2020 года Microsoft заявила, что будет поддерживать Visual Basic в унифицированной платформе .NET 5.0, но больше не будет добавлять новые функции или развивать этот язык.</p>
               <p className="sp-03">Припоминая интересные факты о Windows и базовые программы, многие вспомнят «Косынку», «Сапера», «Reversi» – простые игры, «убийцы времени». Однако их истинное предназначение – комфортное освоение компьютерной мыши. Это сейчас подобные девайсы – дело привычное, а ранее периферия имела статус новинки.</p>
               <p className="sp-01">Итогом можно сказать, что VB - язык сильно привязанный к своей среде разработки и к операционной системе Microsoft Windows, являясь исключительно инструментом написания Windows-приложений. Привязка к среде заключается в том, что существует большое количество средств, предназначенных для помощи и удобства программирования: встроенный отладчик, просмотр переменных и структур данных на лету, окно отладки, всплывающие подсказки при наборе текста программы (IntelliSense). Все эти преимущества делают бесполезным и даже невозможным использование Visual Basic вне среды разработки, например в обычном текстовом редакторе.</p>
               
               <p className="sp-02">Развитие Visual Basic</p>
               <p className="sp-01"><b>Visual Basic .NET (VB.NET)</b> — объектно-ориентированный язык программирования, который можно рассматривать как очередной виток эволюции Visual Basic (VB), реализованный на платформе .NET Framework. VB.NET не имеет обратной совместимости с более ранней версией (Visual Basic 6.0).</p>
               <p className="sp-01">Главной особенностью является поддержка объектно-ориентированного программирования с конструкторами и деструкторами, другими словами, создания своих объектов, назначения им своих свойств, удаление свойст и т.д. Ну и конечно, использование всего набора объектных библиотек, входящих в .NET Framework, включающих мощные средства по работе с формами (Windows Forms), базами данных (ADO.NET), графикой (GDI+), средствами обеспечения безопасности, веб-страницами (ASP.NET) и т. п.</p>
               <p className="sp-01"><b>Visual Basic Scripting Edition (VBScript)</b> - Скриптовый язык, являющийся несколько усечённой версией обычного Visual Basic. Используется в основном для автоматизации администрирования систем Microsoft Windows, для написания сценариев для Internet Explorer. Другими словами, для написания определенных алгоритмом для самой Виндойс и некоторых ее программ. </p>
               <p className="sp-03">Кстати, сценарный язык (язык сценариев, скриптовой язык; англ. scripting language) — это определенное написание действия в краткой форме, так сказать. Именно поэтому в ней нет операторов, переменных, и по своей сути это просто набор определённых действий, зато быстрый и понятный. </p>
               <p className="sp-01"><b>eMbedded Visual Basic (eVB)</b> - Данный инструмент используется для разработки программного обеспечения под операционные системы Windows CE и Windows Mobile. Включает в себя интегрированную среду разработки, отладчик и эмулятор для тестирования приложений. Для работы обязательно требуется наличие библиотек eMbedded Visual Basic Runtime для запуска приложений</p>

               <img className="image" src="../../content/st-001/005.jpg" alt=""/>
               <p className="sp-02">Visual Basic for Applications (VBA)</p>
               <p className="sp-01">Это среда разработки практически не отличается от классического Visual Basic, которая предназначена для написания макросов и других прикладных программ для конкретных бизнес-приложений. Наибольшую популярность получил благодаря своему использованию в пакете Microsoft Office. </p>
               <p className="sp-01">Но важное отличие - отсутствуют некоторые объекты, такие как: Clipboard, App, Form, Timer, Data. Вместо них предоставляются другие классы, реализованные в объектной модели конкретного приложения. По простому – VBA работает в той системе, к которой она привязана, например к Екселю, и в ней нет стандартных форм, которые были в VB, ну то есть самого тела программы, где кнопочки располагаются, текстовой окно и т.д., меню, а работает в среде самого екселя и взаимодействует с ее ячейками и т.д. Правда это не означает, что ее нельзя создать, но другими методами. </p>
               <p className="sp-03">Кстати, само по себе значение Макрос или Макрокоманда — это программный алгоритм действий, записанный пользователем. Часто макросы применяют для автоматизации рутинных действий.</p>
               <p className="sp-01">Используя макросы, можно значительно (в десятки, а то и в сотни раз) увеличить эффективность работы с приложением. Помимо выполнения набора встроенных в приложение команд, макрос позволяет производить обработку внешних файлов, загрузку и передачу файлов и данных через интернет, чтение и изменение настроек операционной системы.</p>
               <p className="sp-01">К достоинствам языка можно отнести сравнительную лёгкость освоения, благодаря которой приложения могут создавать даже пользователи, не программирующие профессионально. К особенностям VBA можно отнести выполнение скрипта именно в среде офисных приложений.</p>
               <p className="sp-01">Недостатком являются проблемы с обратной совместимостью разных версий, это бывает, да, разных причем версий не только самой программы оболочки VBA, но и сопутствующих, если вы используете их библиотеки. По-простому, написав макрос у себя дома на своем компьютере и залив его в сеть, совершенно ен факт, что он пойдет на других компьютерах и системах. надо обязательно прикладывать спецификации и версию своих программ. </p>
               <p className="sp-04">Кстати, довольно часто вы можете встретить такое слово, как <b>ActiveX</b> — фреймворк для определения программных компонентов, пригодных к использованию из программ, написанных на разных языках программирования. Программное обеспечение может собираться из одного или более таких компонентов, чтобы использовать их функциональность.</p>
               <p className="sp-01">Множество приложений для Microsoft Windows, включая приложения самой компании Microsoft, такие, как Internet Explorer, Microsoft Office, Microsoft Visual Studio, Windows Media Player, используют управляющие элементы ActiveX, чтобы реализовать набор функциональных возможностей и в дополнение инкапсулировать их собственную функциональность в управляющие элементы ActiveX, чтобы предоставить возможность встраивать данные элементы в другие приложения.</p>
               <p className="sp-01">Технология ActiveX — средство, при помощи которого Internet Explorer (IE) использует другие приложения внутри себя. С помощью ActiveX IE загружает Windows Media Player, Quicktime и другие приложения, которые могут воспроизводить файлы, внедрённые в веб-страницы. Элементы управления ActiveX активизируются при щелчке по такому объекту на веб-странице, например, .WMV-файлу, чтобы загрузить его для отображения в окне браузера Internet Explorer.</p>
               <p className="sp-03">Кстати, в будущем Microsoft планирует заменить VBA на Visual Studio Tools for Applications (VSTA) — инструментарий расширения функциональности приложений, основанный на Microsoft .NET.</p>
               <p className="sp-01"><b>Visual Studio Tools for Applications</b> (VSTA) — позволяет независимым поставщикам программного обеспечения настраивать автоматизацию и расширяемость в своих приложений, другими ловами, эта система более обширна по программным продуктам, так сказать. В свою очередь эти возможно позволят польщовать настравить разные программы под свои нужды. </p>
               <p className="sp-01">Сам по себе VSTA уже входит в состав Office 2007, однако пока он интегрирован только в программу InfoPath, так как в других приложениях Office вместо него используется Visual Basic for Applications. </p>



   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x001