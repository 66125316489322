import React from 'react' ;
import '../../scss/BlogPage/blogspisok.scss';
import BlogItem from './BlogItem';
import BlogPagin from './BlogPagin';
import jsstaties from "../../assets/content/mini-staties.json"
function BlogSpisok ( {langID, searchId, searchHesh, searchPage, setSearchPage, setSearchNumstat, classNomInd, setClassNomInd} ) {

   
  
      //поиск
   const items = jsstaties
      .filter((obj) => {
         if (obj.title.toLowerCase().includes(searchId)) {
            if (obj.hesh === searchHesh || searchHesh === '#Все статьи') {
               return true;
            }
         }
         return false
      }).map((obj) => <BlogItem key={obj.id} {...obj} setSearchNumstat={setSearchNumstat} />).reverse();
      
      // количество страниц в зависиомсти от разрешения экрана
      const mq1280 = window.matchMedia('(max-device-width: 1280px)')
      const mq900 = window.matchMedia('(max-device-width: 900px)')
      let iPage = 9
      if (mq1280.matches && !mq900.matches) {
         iPage = 8
      } else if (mq900.matches) {
         iPage = 6
      } else {
         iPage = 9
      } 
      // - - - - - - - - - - 

      const count = Math.ceil(items.length / iPage)

      const itemsSt = items.slice(searchPage-iPage,searchPage)

   return (
      <section className="blogspisok">
         <div className="blogspisok-center">
            {itemsSt}

         </div>
         <div className="blogspisok-pagin">
            { [...Array(count)].map((item, index) => <BlogPagin  key={index} index={index+1} setSearchPage={setSearchPage} classNomInd={classNomInd} setClassNomInd={setClassNomInd} iPage={iPage}  /> ) }
            </div>
      </section>
   )
}

export default BlogSpisok;