import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x004 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 0   // первая статья
   const i2 = 2   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Отправляем заказы в Telegram</span>
               <img className="image" src="../../content/st-004/001.jpg" alt=""/>
               
               <p className="sp-01"><b>Telegram</b> - это удобный мессенджер не только для общения и ведения каналов, но и для полуечения заказов от клиентов, обратной связи, отзывов, сообщение "перезвонить" и т.д. А главное, все это дело довольно просто настроить на любом сайте. Главное не забыть подключить PHP на своем сервере версии выше 17 (если у вас простой лендинг и вы не использовали его).</p>
               <p className="sp-02">Настройки в telegram</p>
               <p className="sp-01">По сути все сообщения в телегу приходят от оф.чат-бота telegram <b>@BotFather</b>. И все, что вам нужно, просто найти его по поиску.  </p>
               <ul>
                  <li>Откройте его в телеграм и отправьте команду «/newbot».</li>
                  <li> Далее нужно будет придумать название и логин своему боту.</li>
               </ul>
               <p className="sp-01">Это все выполняется прямо в чате телеграм, ниже можете увидеть скрин переписки с ботом. Это не сложно, просто действуйте по инстуркции, он вам сам все подскажет. После успешного создания и настроек, BotFather пришлет токен созданного бота. Именно токен то нам и нужен, потребуется для отправки запросов к api.</p>
               <p className="sp-01">Сама суть всей этой процедуры в том, что вам сообщения с сайта должен как-бы "кто-то" присылать. Ведь это мессенджер. И чат-бот Father - он то и будет вашим собеседеником, который будет вам их присылать в диалоге</p>
               <p className="sp-01">Далее вы можете просто создать отдельный чат или группу, в которой будете вы и ваш бот. Назовите ее как-нибудь: "Заказы".</p>
               <p className="sp-01">Вторым шагом будет получения вашего id, который нам надо прописать будет в настройках на сайте, для того, чтобы отправлять сообщения о заказах через чат-бота именно вам, а не в деревню к дедушке.  Чтобы узнать свой id можно также воспользоваться ботами, их много. Например, вот таким: <b>@getmyid_bot</b>. Просто запустите бота и в ответ он напишет ваш ID.</p>

               <img className="image" src="../../content/st-004/002.jpg" alt=""/>
               <p className="sp-02">Переходим к настройкам сайта</p>
               <p className="sp-01">По итогу мы получили токен нашего бота: <b>2225556669:AAH5rOmouiYdPkААgGXPk0wOM_aR7NK_0_c</b>, например, и ID нашей телеги (нашего телефона или фирмы): <b>5492137891</b> </p>
               <p className="sp-01">И все что нам нужно сделать по итогу, это отправить сообщение через браузер вида:</p>
               <p className="sp-05">https://api.telegram.org/bot2225556669:AAH5rOmouiYdPkААgGXPk0wOM_aR7NK_0_c/sendMessage?chat_id=5923592998&text=TextSoobsheniya"</p>
               <p className="sp-01">Вы даже просто можете вставить его в адресную строку, написать сообщение в конце и вы должны будете получить его в своем телеграмме.</p>
               <p className="sp-01">Ну и все что нам надо, это прописать его в файле (коде) PHP. Можно просто прописать строчку, "захардкодив" ее, можно отправить через <b>curl_init</b> (инициализирует сеанс c URL).Или же в том же React отправить через axios (см.картинку снизу).</p>
               <img className="image" src="../../content/st-004/003.jpg" alt=""/>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x004