import React from 'react' ;
// import { Link } from "react-router-dom";
import '../../scss/MainPage/bodymain.scss';
import jsheadLg from '../../assets/language/bodymain-lang.json';
import { HashLink as Link } from 'react-router-hash-link';

function BodyMain ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 

   return (
      <section className="bodymain">
         <div className="bodymain-center">

         {/* первый блок лендинг-промо сайт */}
         <div className="bodymain-lend" id="landing">
            {/* блок с картинкой */}
            <div className="bodymain-land-left">
               <div className="bodymain-land-left-collor">

               </div>
               <div className="bodymain-land-left-img">
                  <img className="image" src="fon\site_01.png" alt=""/>
               </div>
               <div className="bodymain-land-left-line">
                  <div className="line-round circle-01"></div>
                  <div className="line-round circle-02"></div>
               </div>
            </div>
            {/* блок с описанием */}
            <div className="bodymain-land-right">
               <Link to="/sdelatzakaz#zaklending" title={lang.menu_01}><div className="bodymain-land-right-span">
                  <p>{lang.menu_01}</p>
               </div></Link>
               <div className="bodymain-land-right-txt">
                  <ul>
                     <li>{lang.menu_02}</li>
                     <li>{lang.menu_03}</li>
                     <li>{lang.menu_04}</li>
                  </ul>
               </div>
            </div>
         </div>

         {/* второй блок сайт визитка */}
         <div className="bodymain-visit" id="promo">
            <div className="bodymain-visit-top">
               <div className="bodymain-visit-top-collor">

               </div>
               <div className="bodymain-visit-top-img">
                  <img className="image" src="fon\site_02.jpg" alt=""/>
               </div>
            </div>
            <div className="bodymain-visit-down">
               <Link to="/sdelatzakaz#zakvizit" title={lang.menu_05}><div className="bodymain-visit-down-span">
                  <p>{lang.menu_05}</p>
               </div></Link>
               <div className="bodymain-visit-down-txt">
                  <ul>
                     <li>{lang.menu_06}</li>
                     <li>{lang.menu_07}</li>
                     <li>{lang.menu_08}</li>
                  </ul>
               </div>
            </div>
         </div>

         {/* третий блок корпоративный сайт */}
         <div className="bodymain-korp" id="web">
            {/* верхний блок с картикной */}
            <div className="bodymain-korp-top">
               <div className="bodymain-korp-top-line">
                  <div className="line-round circle-04"></div>
                  <div className="line-round circle-05"></div>
               </div>
               <div className="bodymain-korp-top-imgmax">
                  <img className="image" src="fon\site_03.jpg" alt=""/>
               </div>
               <div className="bodymain-korp-top-imgmin">
                  <img className="image" src="fon\site_04.png" alt=""/>
               </div>
               <div className="bodymain-korp-top-collor"></div>
            </div>
            {/* нижний блок с описанием */}
            <div className="bodymain-korp-down">
               <Link to="/sdelatzakaz#zakkorp" title={lang.menu_09}><div className="bodymain-korp-down-span">
                  <p>{lang.menu_09}</p>
               </div></Link>
               <div className="bodymain-korp-down-txt">
                  <ul>
                     <li>{lang.menu_10}</li>
                     <li>{lang.menu_11}</li>
                     <li>{lang.menu_12}</li>
                  </ul>
               </div>
               <div className="bodymain-korp-down-collor"></div>
            </div>
         </div>

         {/* Четвертый блок интернет магазин */}
         <div className="bodymain-shop" id="shop">
            <div className="bodymain-shop-line">
               <div className="line-round circle-06"></div>
               <div className="line-round circle-07"></div>
            </div>
            {/* правая часть с каритнокй  */}
            <div className="bodymain-shop-left">
               <div className="bodymain-shop-left-img">
                  <img className="image" src="fon\site_05.png" alt=""/>
               </div>
            </div>
            {/* левая часть с описанием и картинкой кота */}
            <div className="bodymain-shop-right">
               <Link to="/sdelatzakaz#zakshop" title={lang.menu_09}><div className="bodymain-shop-right-span">
                  <p>{lang.menu_13}</p>
               </div></Link>
               <div className="bodymain-shop-right-txt">
                  <ul>
                     <li>{lang.menu_14}</li>
                     <li>{lang.menu_15}</li>
                     <li>{lang.menu_16}</li>
                  </ul>        
               </div>
               
               <div className="bodymain-shop-right-img">
                  <img className="image" src="img\g_003.gif" alt=""/>
               </div>
               <div className="bodymain-shop-right-line">
                  <div className="line-round circle-03"></div>
               </div>
            </div>
         </div>


         </div>
      </section>
   )
}

export default BodyMain;