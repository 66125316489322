import React from 'react' ;
import '../../scss/MainPage/banersoc.scss';
import jsheadLg from '../../assets/language/banersoc-lang.json';

function BanerSoc ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];
   const [isPlanmenu, setIsPlanmenu] = React.useState(false);
   const [isPlanmail, setIsPlanmail] = React.useState(false);
   const [isPlansoc01, setIsPlansoc01] = React.useState(false);
   const [isPlansoc02, setIsPlansoc02] = React.useState(false);
   const [isPlansoc03, setIsPlansoc03] = React.useState(false);
   const [isPlansoc04, setIsPlansoc04] = React.useState(false);


   return (
      <section className="banersoc" id="socseti">
         <div className="banersoc-center">

            {/* блок с телефоном и соцсетями */}
            <div className="banersoc-mob">
               {/* блок с телефоном и почтой */}
               <div className="banersoc-mob-top">

                  {/* блок с телефоном */} 
                  <div className={`banersoc-mob-top-phone ${isPlanmenu && ('plan-menu')}`} >
                     <a href="tel:+79167020406" title={lang.menu_03} target="_blank" rel="noreferrer"><p>+7(916)702-04-06</p></a>
                  </div>

                  {/* блок с меесенджерами */}
                  <div className="banersoc-mob-top-soc">
                     <div className="top-soc-mobile" onClick={() => setIsPlanmenu(!isPlanmenu)}>
                        <img className="image" src="icon\i_001.gif" alt=""/>
                     </div>
                     <div className={`top-soc-social ${isPlanmenu && ('plan-menu')}`} >
                        <div className="top-soc-social-img">
                           <a href="https://www.messenger.com/t/leontevMikle25" title="Facebook Massage" target="_blank" rel="noreferrer"><img className="image" src="icon\i_006.gif" alt=""/>
                           </a>
                        </div> 
                        <div className="top-soc-social-img">
                           <a href="https://t.me/MLeontiev25" title="Telegram" target="_blank" rel="noreferrer"><img className="image" src="icon\i_007.gif" alt=""/></a>
                        </div>
                        <div className="top-soc-social-img">
                           <a href="https://wa.me/+79167020406" title="WatsApp" target="_blank" rel="noreferrer"><img className="image" src="icon\i_008.gif" alt=""/></a>
                        </div>
                        <div className="top-soc-social-img">
                           <a href="viber://add?number=+79167020406" title="Viber" target="_blank" rel="noreferrer"><img className="image" src="icon\i_009.gif" alt=""/></a>
                        </div>
                        <div className="top-soc-social-img">
                           <a href="skype:live:.cid.944fcc7d3534042a?chat" title="Skype" target="_blank" rel="noreferrer"><img className="image" src="icon\i_010.gif" alt=""/></a>
                        </div>
                     </div>
                  </div>

                  {/* блок с почтой */}
                  <div className="banersoc-mob-top-mail">
                     <div className="top-mail-img" onClick={() => setIsPlanmail(!isPlanmail)}>
                        <img className="image" src="icon\i_002.gif" alt=""/>
                     </div>    
                     <div className={`top-mail-txt ${isPlanmail && ('plan-menu')}`} >
                        <a href="mailto:m.a.leontev@gmail.com" title="E-mail" target="_blank" rel="noreferrer"><p>m.a.leontev@gmail.com</p></a>
                     </div>                   
                  </div>

                  {/* блок с социальными сетями */}
                  <div className="banersoc-mob-down">
                     <div className="banersoc-mob-down-soc">
                        <div className="banersoc-mob-down-soc-img" onClick={() => setIsPlansoc01(!isPlansoc01)}>
                           <img className="image" src="icon\i_014.gif" alt=""/>  
                        </div>
                        <div className={`banersoc-mob-down-soc-txt ${isPlansoc01 && ('plan-menu')}`} >
                           <a href="https://www.instagram.com/kamorka_Programmista/" title="Instagram" target="_blank" rel="noreferrer"><p>@Kamorka_Programmista</p></a>
                        </div>
                     </div>
                     <div className="banersoc-mob-down-soc">
                        <div className="banersoc-mob-down-soc-img" onClick={() => setIsPlansoc02(!isPlansoc02)}>
                           <img className="image" src="icon\i_013.gif" alt=""/>  
                        </div>
                        <div className={`banersoc-mob-down-soc-txt ${isPlansoc02 && ('plan-menu')}`} >
                           <a href="https://vk.com/kamorkaprogrammista" title="Vkontakte" target="_blank" rel="noreferrer"><p>VK_kamorkaProgGroup</p></a>
                        </div>
                     </div>
                     <div className="banersoc-mob-down-soc">
                        <div className="banersoc-mob-down-soc-img" onClick={() => setIsPlansoc03(!isPlansoc03)}>
                           <img className="image" src="icon\i_012.gif" alt=""/>  
                        </div>
                        <div className={`banersoc-mob-down-soc-txt ${isPlansoc03 && ('plan-menu')}`}>
                           <a href="https://www.facebook.com/leontevMikle25" title="Facebook" target="_blank" rel="noreferrer"><p>FaceBook_MikleLeo</p></a>
                        </div>
                     </div>
                     <div className="banersoc-mob-down-soc">
                        <div className="banersoc-mob-down-soc-img" onClick={() => setIsPlansoc04(!isPlansoc04)}>
                           <img className="image" src="icon\i_011.gif" alt=""/>  
                        </div>
                        <div className={`banersoc-mob-down-soc-txt ${isPlansoc04 && ('plan-menu')}`}>
                           <a href="https://t.me/+4ch39yeGdP8xYzMy" title="Group Telegram" target="_blank" rel="noreferrer"><p>Telegram_GroupKamorka</p></a>
                        </div>
                     </div>
                  </div>

               </div>
            </div>

         </div>
      </section>
   )
}

export default BanerSoc;