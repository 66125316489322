import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/MainPage/raytme.scss';
import jsheadLg from '../../assets/language/raytme-lang.json';

function RaytMe ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="raytme" id="zayvka">
         <div className="raytme-center">

            {/* блок с кратинкой и описанием */}
            <div className="raytme-block part-01">
               <div className="raytme-block-img">
                  <img className="image" src="../../logo/l_001.gif" alt=""/>
               </div>
               <div className="raytme-block-txt">
                  <p>{lang.menu_01}</p>
               </div>
            </div>

            <div className="raytme-block part-02">
               <div className="raytme-block-img">
                  <img className="image" src="../../logo/l_004.gif" alt=""/>
               </div>
               <div className="raytme-block-txt">
                  <p>{lang.menu_02}</p>
               </div>
            </div>

            <div className="raytme-block part-03">
               <div className="raytme-block-img">
                  <img className="image" src="../../logo/l_003.gif" alt=""/>
               </div>
               <div className="raytme-block-txt">
                  <p>{lang.menu_03}</p>
               </div>
            </div>

            <div className="raytme-block part-04">
               <div className="raytme-block-img">
                  <img className="image" src="../../logo/l_002.gif" alt=""/>
               </div>
               <div className="raytme-block-txt">
                  <p>{lang.menu_04}</p>
               </div>
            </div>


         </div>
      </section>
   )
}

export default RaytMe;