import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x007 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 5   // первая статья
   const i2 = 4   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>UI-Kit - готовое решение для сайта</span>
               <img className="image" src="../../content/st-007/001.jpg" alt=""/>
               
               <p className="sp-01">Не все проекты требуют того, что бы с нуля разрабатывать составляющие интерфейса. Иногда задача поставлена так, что нужно реализовать некоторый сервис в короткие сроки и в рамках ограниченного бюджета. При таком раскладе можно воспользоваться готовыми решениями, которые сэкономят много времени.</p>
               <ul>
                  <li>если продукт только выходит на рынок и цель заказчика - это MVP проект, который нужен ему вчера</li>
                  <li>если у заказчика ограниченный бюджет и желательно пропустить этапы разработки дизайна и вёрстки</li>
                  <li>если сервис не требует неординарных решений для интерфейса, то также можно задействовать UI библиотеку</li>
                  <li>ну или бывают ситуации, когда приложение разрабатывается для внутреннего использования и наполнение становится важнее внешних атрибутов</li>
               </ul>
               <p className="sp-01"><b>UI-kit</b> — это готовый набор элементов пользовательского интерфейса, который ускоряет создание приложения на всех уровнях: проектирование, дизайн, разработка. Он представляет собой коллекцию элементов интерфейса в виде исходных файлов. В UI Kit есть все, что нужно для создания интерфейса: кнопки, виджеты, чекбоксы, прогресс бары и панели навигации. Каждый элемент UI-кита проработан во всех нюансах по концепции «бери и внедряй». </p>
               <p className="sp-01">Мы поговорим про UI-Kit'ы для React. Согласно опроса на Stack Overflow популярность React выросла почти вдвое. На самом деле ничего удивительного. Среди прочего, это стало возможным благодаря его растущей экосистеме, основанной на компонентах. Кто-то предпочитает библиотеки, другие – разработку собственных компонентов. </p>

               <img className="image" src="../../content/st-007/002.jpg" alt=""/>
               <p className="sp-02"><b>Material-UI</b></p>
               <p className="sp-01">Компоненты React, реализующие материальный дизайн Google. Это одна из самых популярных и широко используемых React UI библиотек на GitHub. Стоит отметить, что в MUI подробней расписана документация, но визуальное представление компонентов внутри библиотеки не очень приятное. сли оценить объективно, то это отличный продукт, с помощью которого можно спроектировать серьезный интерфейс и который не смотря на моё мнение, является одной из самых крупных и удобных библиотек. А главное - он полностью бесплатный.</p>

               <img className="image" src="../../content/st-007/003.jpg" alt=""/>
               <p className="sp-02"><b>Ant Design</b> (antd)</p>
               <p className="sp-01">Одна из самых популярных библиотек. Ее упоминание часто можно встретить в вакансиях для frontend разработчиков. Сервис принадлежит китайской компании Alibaba. Внутри большое количество готовых компонентов, которые подойдут для создания практически любых веб-приложений. Ограничения могут возникнуть, если вы будете разрабатывать сервис, который работает с большим количеством данных и статистикой. Antd может снабдить ваш проект аккуратными табличками с базовым функционалом, не более того.Из преимуществ можно выделить привлекательный визуал. Используя компоненты этой библиотеки вы всегда будете получать чистый и привлекательный интерфейс.</p>

               <img className="image" src="../../content/st-007/004.jpg" alt=""/>
               <p className="sp-02"><b>React Bootstrap</b></p>
               <p className="sp-01">Bootstrap — это бесплатный CSS-фреймворк с открытым исходным кодом, предназначенный для быстрой вёрстки адаптивных интерфейсов сайтов и веб-приложений с ориентацией на мобильные устройства. Bootstrap используется многими веб-разработчиками по всему миру. Он является очень популярным. По данным W3Techs, Bootstrap используется на 19% всех веб-сайтов.  Bootstrap может использоваться для верстки любых сайтов. Причём он позволяет это сделать очень быстро, благодаря большему количеству готовых классов и компонентов. По сути вы просто складываете дизайн из готовых кусочков, поэтому большинство Bootstrap-сайтов очень похожи друг на друга. Кроме быстроты, он также упрощает процесс верстки оригинальности.</p>
               <p className="sp-03">Основная область его применения – это фронтенд разработка сайтов и интерфейсов админок. Среди аналогичных систем (Foundation, UIkit, Semantic UI, InK и др.) фреймворк Bootstrap является самым популярным.</p>

               <img className="image" src="../../content/st-007/005.jpg" alt=""/>
               <p className="sp-02"><b>Grommet</b></p>
               <p className="sp-01">Это фреймворк на базе React. Это доступность, модульность, адаптивность и красивые темы в одном аккуратном пакете. В Grommet вы найдете больше 60 компонентов. Он также предоставляет Sketch, Figma, AdobeXd файлы и больше 600 иконок. Этот фреймворк используется Netflix, Samsung, Uber, Boeing, IBM и другими компаниями.</p>

               <img className="image" src="../../content/st-007/006.jpg" alt=""/>
               <p className="sp-02"><b>Chakra UI</b></p>
               <p className="sp-01">Chakra UI предоставляет набор React-компонентов, которые отличаются доступностью и пригодностью для компоновки и многократного использования. Создавать сайты и приложения с их помощью можно очень быстро. Компоненты Chakra UI соответствуют спецификациям WAI-ARIA и имеют aria-* атрибуты.</p>
               <p className="sp-04">WAI-ARIA — технологический стандарт, разрабатываемый Консорциумом Всемирной паутины для предоставления возможности полноценного использования Интернета людьми с физическими ограничениями. Больше на тему обеспечения доступности в вебе можно почитать в статье «Что такое веб-доступность и как научиться делать доступные сайты и приложения».</p>
               <p className="sp-01">Эта библиотека отличается хорошей производительностью, а кроме того с ней приятно работать. Благодаря этому сообщество Chakra UI быстро растет. Библиотека имеет хорошую документацию с примерами кода.</p>

               <img className="image" src="../../content/st-007/007.jpg" alt=""/>
               <p className="sp-02">Немного о компонентах</p>
               <p className="sp-01">Компоненты UI являются основными блоками и элементами для дизайна пользовательского интерфейса. Они предоставляют пользователям способ взаимодействия с вашим веб-сайтом или приложением. Этими компонентами могут являться интерактивный текст и графика, которые сообщат пользователю, что делать дальше. Есть основные и наиболее часто используемые элементы UI, которые вы должны учитывать при разработке своего продукта или веб-сайта. К ним относятся следующие:</p>
               <ul>
                  <li>Кнопки</li>
                  <li>Ссылки</li>
                  <li>Иконки</li>
                  <li>Меню</li>
                  <li>Панели инструментов</li>
                  <li>Текстовые поля</li>
                  <li>Флажки</li>
                  <li>Радио-кнопки</li>
               </ul>
               <p className="sp-01">Каждый из этих элементов выполняет определенную функцию. Их понимание необходимо для создания эффективного пользовательского интерфейса. Например, кнопки позволяют выполнять конкретные действия, ссылки — переходить на другие страницы или веб-сайты, а иконки помогают быстро находить важные функции или информацию.</p>

               <img className="image" src="../../content/st-007/008.jpg" alt=""/>
               <p className="sp-02">Полезные советы по UI-дизайну</p>
               <p className="sp-01">Разработка UI-дизайна базируется на определенных стандартах и требует, чтобы работа дизайнеров осуществлялась в соответствии с некоторыми принципами и практиками. Вот что следует запомнить:</p>
               <p className="sp-01">1. Убедитесь, что в вашем интерфейсе легко ориентироваться. Разместите наиболее важные компоненты в легкодоступных местах и ​​используйте четкие и лаконичные элементы.</p>
               <p className="sp-01">2. Обратите внимание на детали. Элементы пользовательского интерфейса должны выглядеть понятно, а текст быть разборчивым.</p>
               <p className="sp-01">3. Протестируйте свои проекты. Убедитесь, что вы тестируете свои дизайны на разных устройствах с разными экранами, чтобы убедиться в оптимизации дизайна для любого девайса.</p>
               <p className="sp-01">4. Используйте шаблоны проектирования. Шаблоны проектирования — это элементы пользовательского интерфейса, эффективность которых уже доказана, поэтому рекомендуется придерживаться их там, где это возможно.</p>
               <p className="sp-01">5. Используйте стандартные принципы дизайна. Применяйте принципы симметрии, контраста, выравнивания для создания визуально привлекательных интерфейсов, которые также будут просты в использовании.</p>
              

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x007