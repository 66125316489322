import React from 'react' ;
import '../scss/headdown.scss';
import jsheadLg from '../assets/language/headDown-lang.json';
import { HashLink as Link } from 'react-router-hash-link';

function HeadDown( {langID, tipPageHeader} ) {

   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 

   let imgTitleHeader, imgPostHeader, txtLongHeader, classTxtHeader, classDivHeader
   switch (tipPageHeader) {
      case 'mainHeader':
         imgTitleHeader = "img/g_001.gif"
         imgPostHeader = "img/g_002.gif"
         txtLongHeader = lang.menu_04
         classTxtHeader = ""
         break
      case 'zakazHeader':
         imgTitleHeader = "img/g_007.gif"
         imgPostHeader = "img/g_008.gif"
         txtLongHeader = lang.menu_05
         classTxtHeader = "spanTxt"
         classDivHeader = "spanDiv"
         break
      case 'primernHeader':
         imgTitleHeader = "img/g_007.gif"
         imgPostHeader = "img/g_008.gif"
         txtLongHeader = lang.menu_06
         classTxtHeader = "spanTxt"
         classDivHeader = "spanDiv"
         break
      case 'kamorkaHeader':
         imgTitleHeader = "img/g_013.gif"
         imgPostHeader = "img/g_002.gif"
         txtLongHeader = lang.menu_07
         classTxtHeader = "spanTxt"
         classDivHeader = "spanDiv"
         break
   }

   return (
      <section className="headerdown">
         <div className="low-header-menu">
            <div className="low-header-cent">
               <div className="low-header-line">
                  {/* просто для линии сбоку */}
               </div>
               {/* надпись под логотипом */}
               <div className="low-header-underlogo">
                  <p>{lang.menu_01}</p>
                  <p>{lang.menu_02}</p>
                  <p>{lang.menu_03}</p>
               </div>
               {/* блок с кошкой и цветными формами */}
               <div className="low-header-cat">

                  {/* цветная форма красная Лендинг */}
                  <div className="low-header-cat-onecolor onered">
                     <div className="cat-onecolor-lines">
                        <div className="cat-onecolor-line red-01"></div>
                        <div className="cat-onecolor-line red-02"></div>
                        <div className="cat-onecolor-line red-03"></div>
                     </div>
                     <div className="cat-onecolor-blocks-block-red">
                     <Link to="/#landing" title="Landing"><p>Landing</p></Link>
                     </div>
                  </div>

                  {/* цветная форма синяя и желтая Шоп и Веб */}
                  <div className="low-header-cat-twocolor">
                     {/* желтая */}
                     <div className="low-header-cat-onecolor oneyellow">
                        <div className="cat-onecolor-lines">
                           <div className="cat-onecolor-line yell-01"></div>
                           <div className="cat-onecolor-line yell-02"></div>
                           <div className="cat-onecolor-line yell-03"></div>
                        </div>
                        <div className="cat-onecolor-blocks-block-yellow">
                        <Link to="/#shop" title="Shop"><p>Shop</p></Link>
                        </div>
                     </div>
                     {/*синяя */}
                     <div className="low-header-cat-onecolor oneblue">
                        <div className="cat-onecolor-lines">
                           <div className="cat-onecolor-line blue-01"></div>
                           <div className="cat-onecolor-line blue-02"></div>
                           <div className="cat-onecolor-line blue-03"></div>
                        </div>
                        <div className="cat-onecolor-blocks-block-blue">
                           <Link to="/#web" title="Web"><p>Web</p></Link>
                        </div>
                     </div>
                  </div>

                  {/* цветная форма зеленая Промо */}
                  <div className="low-header-cat-threecolor">
                     <div className="low-header-cat-onecolor onegreen">
                        <div className="cat-onecolor-lines">
                           <div className="cat-onecolor-line green-01"></div>
                           <div className="cat-onecolor-line green-02"></div>
                           <div className="cat-onecolor-line green-03"></div>
                        </div>
                        <div className="cat-onecolor-blocks-block-green">
                           <Link to="/#promo" title="Promo"><p>Promo</p></Link>
                        </div>
                     </div>

                     {/* блок с картинкой кошки */}
                     <div className="cat-onecolor-img">
                        <img className="image" src={imgTitleHeader} alt=""/>
                     </div>
                  </div>
               </div>

            {/* блок с картинкой программистау */}
               <div className={`low-header-img ${classDivHeader}`} >
                  <img className="image" src={imgPostHeader} alt=""/>
               </div>

               {/* блок с названием раздела или описанием */}
               <div className={`low-header-textcomon ${classTxtHeader}`}>
                  <p>{txtLongHeader}</p>
               </div>
            </div>
         </div>
      </section>
   )
}

export default HeadDown;