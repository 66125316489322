import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/MainPage/aboutme.scss';
import jsheadLg from '../../assets/language/aboutme-lang.json';

function AboutMe ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="aboutme" id="aboutme">
         <div className="aboutme-center">

            {/* блок с названием раздела */}
            <div className="aboutme-top">
               <div className="aboutme-top-span">
                  <p>{lang.menu_01}</p>
               </div>
            </div>

            {/* нижний блок с картинкой и описанием */}
            <div className="aboutme-down">
               {/* блок с картинкой */}
               <div className="aboutme-down-img">
                  <img className="image" src="img\f_04.gif" alt=""/>
               </div>
               {/* блок с описанием */}
               <div className="aboutme-down-opis"> 
                  <div className="aboutme-down-opis-top">
                     <p>{lang.menu_02}</p>
                     <p>{lang.menu_03}</p>
                  </div>
                  <div className="aboutme-down-opis-down">
                     <div className="aboutme-down-opis-down-txt">
                        <p>{lang.menu_04} </p>
                        <p>{lang.menu_05}</p>
                     </div>
                     <div className="aboutme-down-opis-down-but">
                        <Link to="sdelatzakaz" title={lang.menu_06}><p>{lang.menu_06}</p></Link>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </section>
   )
}

export default AboutMe;