import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/PrimerPage/primermid.scss';
import jsheadLg from '../../assets/language/primerpage-lang.json';
import { useRef, useState } from 'react';

// модуль для свайпера (вертушки)
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { Autoplay, Navigation } from 'swiper/modules';



function PrimerMid ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];
   // всплывающий сладйер большой кратинки сайт 1
   const [isVissait, setIsVissait] = React.useState(false);
   // всплывающий сладйер маленький кратинки сайт 2
   const [isVissait_m1, setIsVissait_m1] = React.useState(false);
  // всплывающий сладйер маленький кратинки сайт 3
  const [isVissait_m2, setIsVissait_m2] = React.useState(false);
  // всплывающий сладйер маленький кратинки сайт 4
  const [isVissait_m3, setIsVissait_m3] = React.useState(false);

   function fnCallSayt (count) {
      setIsVissait(!isVissait);
   };
   function fnCallSayt_m1 (count) {
      setIsVissait_m1(!isVissait_m1);
   };
   function fnCallSayt_m2 (count) {
      setIsVissait_m2(!isVissait_m2);
   };
   function fnCallSayt_m3 (count) {
      setIsVissait_m3(!isVissait_m3);
   };

   return (
      <section className="primermid">
         <div className="primermid-center">

            <div className="primermid-big">
               <img className="image im-1180" onClick={() => {fnCallSayt(0)}} src="prim\pr_05-1180.jpg" alt=""/>
               <img className="image im-780" onClick={() => {fnCallSayt(0)}} src="prim\pr_05-780.jpg" alt=""/>
               <img className="image im-480" onClick={() => {fnCallSayt(0)}} src="prim\pr_05-480.jpg" alt=""/>
            </div>

            <div className="primermid-min">
               <Swiper 
                  slidesPerView={'auto'}
                  spaceBetween={30}
                  autoplay={{
                     delay: 5000,
                     disableOnInteraction: false,
                  }}
                  rewind={true}
                  navigation={true} 
                  modules={[Autoplay, Navigation]}     
                  className="mySwiper"
               >
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_07-1180.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_07-780.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_07-480.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_08-1180.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_08-780.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_08-480.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_09-1180.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_09-780.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_09-480.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_07-1180.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_07-780.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_07-480.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_08-1180.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_08-780.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_08-480.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_09-1180.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_09-780.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_09-480.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                  </SwiperSlide>                  
               </Swiper>
            </div>

         {/* всплывающий слайдер большой кратинки сайт 1*/}
            {isVissait &&  (
               <div className="primermid-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-005-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-006-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-007-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-008-r.jpg" alt=""/></SwiperSlide>    
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-009-r.jpg" alt=""/></SwiperSlide>       
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide langID={langID}><img className="image" src="prim\s-005\s_05-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-005-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-006-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-007-e.jpg" alt=""/></SwiperSlide>      
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-008-e.jpg" alt=""/></SwiperSlide>         
                  <SwiperSlide><img className="image" src="prim\s-005\s_05-009-e.jpg" alt=""/></SwiperSlide>   
               </Swiper>)}

               <div className="primermid-saitfor-img" onClick={() => {fnCallSayt(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}


         {/* всплывающий слайдер большой кратинки сайт 2*/}
         {isVissait_m1 &&  (
               <div className="primermid-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-004-r.jpg" alt=""/></SwiperSlide>          
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide langID={langID}><img className="image" src="prim\s-007\s_07-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-007\s_07-004-e.jpg" alt=""/></SwiperSlide>           
               </Swiper>)}

               <div className="primermid-saitfor-img" onClick={() => {fnCallSayt_m1(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}

         {/* всплывающий слайдер большой кратинки сайт 3*/}
         {isVissait_m2 &&  (
               <div className="primermid-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-005-r.jpg" alt=""/></SwiperSlide>             
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-009\s_09-005-e.jpg" alt=""/></SwiperSlide>             
               </Swiper>)}

               <div className="primermid-saitfor-img" onClick={() => {fnCallSayt_m2(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}

         {/* всплывающий слайдер большой кратинки сайт 3*/}
         {isVissait_m3 &&  (
               <div className="primermid-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-005-r.jpg" alt=""/></SwiperSlide>    
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-006-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-007-r.jpg" alt=""/></SwiperSlide>            
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-005-e.jpg" alt=""/></SwiperSlide>  
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-006-e.jpg" alt=""/></SwiperSlide>  
                  <SwiperSlide><img className="image" src="prim\s-008\s_08-007-e.jpg" alt=""/></SwiperSlide>             
               </Swiper>)}

               <div className="primermid-saitfor-img" onClick={() => {fnCallSayt_m3(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}

         </div>

         {/* блок с цветная полоса */}
         <div className="primermid-col"></div>
         <div className="primermid-fon">
            <div className="primermid-fon-img">
               <img className="image" src="img\g_012.gif" alt=""/>
            </div>
         </div>
      </section>
   )
}

export default PrimerMid;