import React from 'react' ;
// import { Link } from "react-router-dom";
import '../scss/footer.scss';
import jsheadLg from '../assets/language/footer-lang.json';
import { HashLink as Link } from 'react-router-hash-link';

function Footer ( {langID, onClickIdLang} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 

   return (
      <footer>
         <a name="end"></a>
         {/* блок зеленого футера */}
         <div className="footer-main">
            <div className="footer-main-center">

               {/* блок левой части футера */}
               <div className="footer-main-blockleft">
                  <div className="footer-main-blocklogo">
                     <div className="blocklogo-round">
                        {/* синий кружок */}
                     </div>
                     <div className="blocklogo-text">
                        <span>web-leodesign</span>
                        <p>{lang.menu_01}</p>
                        <p>{lang.menu_02}</p>
                        <p>{lang.menu_03}</p>
                     </div>
                  </div>
                  {/* блок кота и номера телефона */}
                  <div className="footer-main-blockcontact">
                     <div className="blockcontact-img">
                        <img className="image" src="../../img/g_005.gif" alt=""/>
                     </div>
                     <div className="blockcontact-text">
                        <div className="blockcontact-text-top">
                           <div className="blockcontact-text-top-img">
                              <img className="image" src="../../icon/i_001.gif" alt=""/>
                           </div>
                           <a href="tel:+79167020406" title={lang.menu_12} target="_blank" rel="noreferrer"><div className="blockcontact-text-top-txt">
                              <p>+7(916)702-04-06</p>
                           </div></a>
                        </div>
                        <div className="blockcontact-text-top">
                           <div className="blockcontact-text-top-img">
                              <img className="image" src="../../icon/i_002.gif" alt=""/>
                           </div>
                           <a href="mailto:m.a.leontev@gmail.com" title={lang.menu_13} target="_blank"><div className="blockcontact-text-top-txt">
                              <p>m.a.leontev@gmail.com</p>
                           </div></a>
                        </div>
                     </div>
                  </div>
               </div>

               {/* блок правой части футера */} 
               <div className="footer-main-blockright">
                  {/* блок с телефонами */}
                  <div className="blockright-tel">
                     <a href="https://www.messenger.com/t/leontevMikle25" title="Facebook Massage" target="_blank" rel="noreferrer"><div className="blockright-tel-img"><img className="image" src="../../icon/i_006.gif" alt=""/></div></a>
                     <a href="https://t.me/MLeontiev25" title="Telegram" target="_blank" rel="noreferrer"><div className="blockright-tel-img"><img className="image" src="../../icon/i_007.gif" alt=""/></div></a>
                     <a href="https://wa.me/+79167020406" title="WhatsApp" target="_blank" rel="noreferrer"><div className="blockright-tel-img"><img className="image" src="../../icon/i_008.gif" alt=""/></div></a>
                     <a href="viber://add?number=+79167020406" title="Viber" target="_blank" rel="noreferrer"><div className="blockright-tel-img"><img className="image" src="../../icon/i_009.gif" alt=""/></div></a>
                     <a href="skype:live:.cid.944fcc7d3534042a?chat" title="Skype" target="_blank" rel="noreferrer"><div className="blockright-tel-img"><img className="image" src="../../icon/i_010.gif" alt=""/></div></a>
                  </div>
                  {/* блок с меню */}
                  <div className="blockright-menu">
                     <div className="blockright-menu-sp">
                     <Link to="/#main" title={lang.menu_04}><p>{lang.menu_04}</p></Link>
                     </div> 
                     <div className="blockright-menu-txt">
                        <Link to="/#aboutme" title={lang.menu_05}><p>{lang.menu_05}</p></Link>
                        <Link to="primerrabot" title={lang.menu_06}><p>{lang.menu_06}</p></Link>
                        <Link to="sdelatzakaz" title={lang.menu_07}><p>{lang.menu_07}</p></Link>
                        <Link to="/#kontakts" title={lang.menu_08}><p>{lang.menu_08}</p></Link>
                        <Link to="kamorkaprogrammista" title={lang.menu_10}><p>{lang.menu_10}</p></Link>
                        <Link to="https://docs.google.com/document/d/1H3-qxiCrYBwUZJXC-bNJyLdbtWiZlogRFIU2OTrn750/edit?usp=sharing" target="_blank" title={lang.menu_09}><p>{lang.menu_09}</p></Link>
                     </div>
                     <div className="blockright-menu-but">
                     <Link to="/#zayvka" title=""><p>{lang.menu_11}</p></Link>
                     </div>
                  </div>
                  {/* блок с осциальными сетями */}
                  <div className="blockright-soc">
                     <a href="https://www.instagram.com/kamorka_Programmista/" title="Instagram" target="_blank" rel="noreferrer"><div className="blockright-soc-img"><img className="image" src="../../icon/i_014.gif" alt=""/></div></a>
                     <a href="https://vk.com/kamorkaprogrammista" title="Vkontakte" target="_blank" rel="noreferrer"><div className="blockright-soc-img"><img className="image" src="../../icon/i_013.gif" alt=""/></div></a>
                     <a href="https://www.youtube.com/@user-jj1zo1dk2k" title="YouTube" target="_blank" rel="noreferrer"><div className="blockright-soc-img"><img className="image" src="../../icon/i_016.gif" alt=""/></div></a>
                     <a href="https://t.me/+4ch39yeGdP8xYzMy" title="Telegram" target="_blank" rel="noreferrer"><div className="blockright-soc-img"><img className="image" src="../../icon/i_011.gif" alt=""/></div></a>
                  </div>
               </div>
            </div>
         </div>

         {/* подпись под футером */}
         <div className="footer-span">
            <p>{lang.menu_14}</p>
            <p>{lang.menu_15}</p>
         </div>
      </footer>
   )
}

export default Footer;