import React from 'react' ;
import './scss/main.scss';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Main from './MapSite/Main';
import Zakaz from './MapSite/Zakaz';
import PrimerRa from './MapSite/PrimerRa';
import KamorBlog from './MapSite/KamorBlog';
import Statya1x001 from './Components/BlogPage/Staties/Statya1x001';
import Statya1x002 from './Components/BlogPage/Staties/Statya1x002';
import Statya1x003 from './Components/BlogPage/Staties/Statya1x003';
import Statya1x004 from './Components/BlogPage/Staties/Statya1x004';
import Statya1x005 from './Components/BlogPage/Staties/Statya1x005';
import Statya1x006 from './Components/BlogPage/Staties/Statya1x006';
import Statya1x007 from './Components/BlogPage/Staties/Statya1x007';
import Statya1x008 from './Components/BlogPage/Staties/Statya1x008';
import Statya1x009 from './Components/BlogPage/Staties/Statya1x009';
import Statya1x010 from './Components/BlogPage/Staties/Statya1x010';
import Statya1x011 from './Components/BlogPage/Staties/Statya1x011';
import Statya1x012 from './Components/BlogPage/Staties/Statya1x012';

function App() {
  const [langID, setLangID] = React.useState(false);
  //фильтрация списка
  const [searchId, setSearchId] = React.useState('')
  //фильтрация хештегов
  const [searchHesh, setSearchHesh] = React.useState('#Все статьи')
//фильтрация страниц
  const [searchPage, setSearchPage] = React.useState(9)
  //номер статьи
  const [searchNumstat, setSearchNumstat] = React.useState("")
  //подсветка раздела
  const [classStInd, setClassStInd] = React.useState(1)
  //подстека номера старницы пагинации
  const [classNomInd, setClassNomInd] = React.useState(1)

  return (
    <div className="main">

      <Header langID={langID} onClickIdLang={(i) => setLangID(i)} />
      
      <Routes>
          {/* Главная страница */}
          <Route path="/" element={<Main langID={langID} tipPageHeader='mainHeader'setSearchHesh={setSearchHesh} setClassStInd={setClassStInd} setSearchPage={setSearchPage} />} />

          {/* Как сделать заказ */}
          <Route path="sdelatzakaz" element={<Zakaz langID={langID} tipPageHeader='zakazHeader'/>} />

          {/* Примеры работ */} 
          <Route path="primerrabot" element={<PrimerRa langID={langID} tipPageHeader='primernHeader'/>} />

          {/* Каморка программиста */} 
          <Route path="kamorkaprogrammista" 
            element={<KamorBlog langID={langID}
            tipPageHeader='kamorkaHeader'
            searchId={searchId} 
            setSearchId={setSearchId} 
            setSearchHesh={setSearchHesh} 
            setSearchPage={setSearchPage} 
            searchHesh={searchHesh}
            searchPage={searchPage}
            searchNumstat={searchNumstat}
            setSearchNumstat={setSearchNumstat}
            setClassStInd={setClassStInd}
            classStInd={classStInd}
            classNomInd={classNomInd}
            setClassNomInd={setClassNomInd}
            />} />

            {/* Список статей */} 
            <Route path="kamorkaprogrammista/statya1x001" element={<Statya1x001 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x002" element={<Statya1x002 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x003" element={<Statya1x003 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x004" element={<Statya1x004 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x005" element={<Statya1x005 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x006" element={<Statya1x006 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x007" element={<Statya1x007 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x008" element={<Statya1x008 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x009" element={<Statya1x009 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x010" element={<Statya1x010 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x011" element={<Statya1x011 langID={langID} />} />
            <Route path="kamorkaprogrammista/statya1x012" element={<Statya1x012 langID={langID} />} />
        </Routes>












      <Footer langID={langID} />
    </div>
  );
}

export default App;
