import React from 'react' ;
import HeadDown from '../Components/HeadDown';
import FormZaya from '../Components/MainPage/FormZaya';
import DopUslug from '../Components/MainPage/DopUslug';
import BlogTopMenu from '../Components/BlogPage/BlogTopMenu'; 
import BlogSpisok from '../Components/BlogPage/BlogSpisok';
import BlogSocial from '../Components/BlogPage/BlogSocial';
import BlogOpis from '../Components/BlogPage/BlogOpis';



function KamorBlog( {langID, searchId, setSearchId, setSearchHesh, setSearchPage, searchHesh, searchPage, setSearchNumstat, classStInd, setClassStInd, classNomInd, setClassNomInd } ) {
   
   window.scroll(0, 600)
   // const [classNomInd, setClassNomInd] = React.useState(1)


   return (
      <section> 
         <HeadDown langID={langID} tipPageHeader='kamorkaHeader'/>
         <BlogTopMenu langID={langID} searchId={searchId} setSearchId={setSearchId} setSearchHesh={setSearchHesh} setSearchPage={setSearchPage} searchHesh={searchHesh} setClassStInd={setClassStInd} classStInd={classStInd} classNomInd={classNomInd} setClassNomInd={setClassNomInd} />
         <BlogSpisok langID={langID} searchId={searchId} searchHesh={searchHesh} searchPage={searchPage} setSearchPage={setSearchPage} setSearchNumstat={setSearchNumstat} classNomInd={classNomInd} setClassNomInd={setClassNomInd} />
         <BlogSocial langID={langID}/>
         <BlogOpis langID={langID}/>
         <DopUslug langID={langID}/>
         <FormZaya langID={langID}/>
      </section>
   )
}

export default KamorBlog;