import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x010 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 7   // первая статья
   const i2 = 8   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Grid - двумерная сетка возможности</span>
               <img className="image" src="../../content/st-010/001.jpg" alt=""/>
               
               <p className="sp-01"><b>Grid</b> – это еще один способ распределить боксы внутри контейнера, как и flex. Главным их различием является то, что flex – одномерный массив, а grid – двумерный. А по-простому, flex – это просто набор ячеек, как слов в Word'e, которые стоят в одну строку. И если им не хватает места по ширине в контейнере, они перепрыгивают на вторую строчку (или не перепрыгивают) и так далее. Мы можем направить эти ячейки или слова слева направо, сверху-вниз и наоборот, а также отцентровать, прижать в левому или правому краю и т.д.</p>
               <p className="sp-01">Гриды же похожи на excel, у них есть отдельные ячейки, строки и столбы, в которых мы можем задавать явное количество ячеек, объединять в одну две и более ячеек, устанавливать для каждого свой размер, переносить ячейки местами и расставлять, как мы хотим. </p>
               <p className="sp-01">Отсюда, гриды удобно использовать при сложных макетах, составлении списков товаров, а также составлять общий массив для сайта (хедер, боди, таск- и нав-бар и т.д.). Флексы удобно применять при работе с меню, длинным списком, когда вам нужно отсортировать иконки и сделать одинаковое расстояние между ними. Простыми словами, если есть значения в ряд и их надо равномерно распределить на странице. Для всего остального лучше использовать гриды. </p>

               <img className="image" src="../../content/st-010/002.jpg" alt=""/>
               <p className="sp-02">Основные свойства Grid'ов</p>
               <p className="sp-05">display: grid;</p>
               <p className="sp-01">Cамый первый элемент, превращает контенйеер в грид со всеми вытекающими отсюда последствиями</p>
               <p className="sp-01"><b>grid-template-columns</b> и <b>grid-template-rows</b> - свойства, задающие размеры и количество колонок или рядов грид-раскладки, соответственно</p>
               <p className="sp-05">grid-template-columns: repeat(3, 50px); <br></br>
               grid-template-rows: 50px 50px 50px;</p>
               <p className="sp-01">Значения можно задавать друг за другом (50px, auto, 20%), а repeat – просто повторяет последовательность и сколько раз (3, 250px)</p>
               <p className="sp-03">fr (от fraction — доля, часть) отвечает за свободное пространство внутри грид-контейнера. По сути если задать repeat(3, 1fr)  - то будет создано три колонки равные и они поделят контейнер на 3 части</p>
               <p className="sp-01"><b>grid-auto-columns, grid-auto-rows</b> - Если элементов внутри грид-контейнера больше, чем может поместиться в заданные явно ряды и колонки, то для них создаются автоматические, неявные ряды и колонки. При помощи свойств grid-auto-columns и grid-auto-rows можно управлять размерами этих автоматических рядов и колонок.</p>
               <p className="sp-01"><b>grid-auto-flow</b> - Если грид-элементов больше, чем явно объявленных колонок или рядов, то они автоматически размещаются внутри родителя.</p>
               <p className="sp-04">row (значение по умолчанию) — автоматически размещаемые элементы выстраиваются в ряды.<br></br>
               column — автоматически размещаемые элементы выстраиваются в колонки.<br></br>
               dense — браузер старается заполнить дырки (пустые ячейки) в разметке, если размеры элементов позволяют. Можно сочетать с остальными значениями.</p>

               <img className="image" src="../../content/st-010/003.jpg" alt=""/>
               <p className="sp-01"><b>grid-template-areas</b> - Позволяет задать шаблон сетки расположения элементов внутри грид-контейнера. Имена областей задаются при помощи свойства grid-area. Текущее свойство grid-template-areas просто указывает, где должны располагаться эти грид-области.</p>
               <p className="sp-03">Заданное значение — название области, может быть абсолютно любым словом или даже эмодзи. Если вам нужна пустая ячейка просто ставите . (точку).</p>
               <p className="sp-01">Имена областей должны разделяться пробелами. Это важно, особенно в том случае, если вы хотите расположить две пустых ячейки рядом. Разделите точки пробелами, иначе браузер подумает, что это одна пустая ячейка.</p>
               <img className="image" src="../../content/st-010/004.jpg" alt=""/>
               
               <p className="sp-04">Можно указывать через слеш сразу два свойства: grid-template: grid-template-rows / grid-template-columns. </p>
               <p className="sp-01"><b>row-gap, column-gap</b> - Задают отступы между рядами или колонками. Можно объединять в одно свойсто <b>gap: row-gap column-gap</b>. minmax() – можно одновременно задавать минимальный и максимальный размер</p>
               <p className="sp-05">row-gap: 10px;<br></br>
               column-gap: 20px;<br></br>
               grid-auto-rows: minmax(100px, auto);</p>

               <p className="sp-02">Выравнивание элементов:</p>
               <ul>
                  <li><b>justify-content</b> - свойство, с помощью которого можно выровнять элементы вдоль оси строки.</li>
                  <li><b>start</b> — выравнивает сетку по левой стороне грид-контейнера.</li>
                  <li><b>end</b> — выравнивает сетку по правой стороне грид-контейнера.</li>
                  <li><b>center</b> — выравнивает сетку по центру грид-контейнера.</li>
                  <li><b>stretch</b> — масштабирует элементы, чтобы сетка могла заполнить всю ширину грид-контейнера.</li>
                  <li><b>space-around</b> — одинаковое пространство между элементами и полуразмерные отступы по краям.</li>
                  <li><b>space-evenly</b> — одинаковое пространство между элементами и полноразмерные отступы по краям.</li>
                  <li><b>space-between</b> — одинаковое пространство между элементами без отступов по краям.</li>
               </ul>
               <img className="image" src="../../content/st-010/005.jpg" alt=""/>
               
               <p className="sp-01"><b>align-items</b> - Свойство, с помощью которого можно выровнять элементы по вертикальной оси внутри грид-контейнера.</p>
               <p className="sp-04">start — выравнивает элемент по начальной (верхней) линии.<br></br>
               end — выравнивает элемент по конечной (нижней) линии.<br></br>
               center — выравнивает элемент по центру грид-ячейки.<br></br>
               stretch — растягивает элемент на всю высоту грид-ячейки.</p>
               <p className="sp-01">Общие свойства:</p>
               <p className="sp-05">place-items: align-items justify-items</p>
               <p className="sp-05">grid: grid-template-rows grid-template-columns grid-template-areas grid-auto-rows grid-auto-columns grid-auto-flow </p>

               <img className="image" src="../../content/st-010/006.jpg" alt=""/>
               <p className="sp-02">Значение для элемента</p>
               <p className="sp-01"><b>grid-column-start, grid-column-end, grid-row-start, grid-row-end</b> - Определяют положение элемента внутри грид-сетки при помощи указания на конкретные направляющие линии. Название или номер линии — может быть порядковым номером или названием конкретной линии. </p>
               <p className="sp-04">span число — элемент растянется на указанное количество ячеек.<br></br>
               span имя — элемент будет растягиваться до следующей указанной линии.</p>
               <p className="sp-01">Общие свойства:</p>
               <p className="sp-05">grid-column: grid-column-start / grid-column-end<br></br>
               grid-row: grid-row-start / grid-row-end<br></br>
               grid-area: row-start / column-start / row-end / column-end</p>

               <img className="image" src="../../content/st-010/007.jpg" alt=""/>
               <p className="sp-01"><b>justify-self</b> - С помощью этого свойства можно установить горизонтальное выравнивание для отдельного элемента, отличное от выравнивания, заданного грид-родителю.</p>
               <p className="sp-01"><b>align-self</b> - выравнивает отдельный элемент по вертикальной оси.</p>
               <p className="sp-01">Общее свойство:</p>
               <p className="sp-05">place-self: align-self justify-self</p>
         

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x010