import React from 'react' ;
import '../../scss/BlogPage/blogspisok.scss';
import jsheadLg from '../../assets/language/aboutme-lang.json';
import { Link } from 'react-router-dom';

function BlogItem ( {langID, id, image, title, description, hesh, ssil, setSearchNumstat} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <div className="blogspisok-statya">
         <div className="blogspisok-statya-img">
            <img className="image" src={image} alt=""/>
         </div>
         <div className="blogspisok-statya-text">
            <div className="statya-text-span">
               <p>{title}</p>
            </div>
            <div className="statya-text-opis">
               <p>{description}</p>
            </div>
            <div className="statya-text-snoska">
               <div className="statya-text-snoska-hesh">
                  <p>{hesh}</p>
               </div>
               <div className="statya-text-snoska-ssil" onClick={() => setSearchNumstat(ssil)}>
                  <Link to={ssil} ><p>продолжение...</p></Link>
               </div>
            </div>
         </div>
      </div>
   )
}

export default BlogItem;