import React from 'react' ;
import '../../scss/BlogPage/blogspisok.scss';

function BlogPagin ( {index, setSearchPage, classNomInd, setClassNomInd, iPage} ) {

   // const [classNomInd, setClassNomInd] = React.useState(index)

   function PagePagin () {
      setSearchPage(iPage*index)
      setClassNomInd(index)
      window.scroll(0, 700) 
   }


   return (
      <div className={index === classNomInd ? "blogspisok-pagin-round active" : "blogspisok-pagin-round"} onClick={() => PagePagin()}>
         <p>{index}</p>
      </div>
   )
}

export default BlogPagin;