import React from 'react' ;
import jsheadLg from '../assets/language/headDown-lang.json';
import HeadDown from '../Components/HeadDown';
import ZakTipsayt from '../Components/ZakazPage/ZakTipsayt';
import ZakKorpstyle from '../Components/ZakazPage/ZakKorpstyle';
import ZakAnimation from '../Components/ZakazPage/ZakAnimation';
import ZakBanner from '../Components/ZakazPage/ZakBanner';
import ZakUslugy from '../Components/ZakazPage/ZakUslugy';
import Kamorka from '../Components/Kamorka';

function Zakaz( {langID, tipPageHeader} ) {
   window.scroll(0, 600)
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 
   
   return (
      <section>
         <HeadDown langID={langID} tipPageHeader='zakazHeader'/>
         <ZakTipsayt langID={langID}/>
         <ZakKorpstyle langID={langID}/>
         <ZakAnimation langID={langID}/>
         <ZakBanner langID={langID}/>
         <ZakUslugy langID={langID}/>
         <Kamorka langID={langID} />

      </section>
   )
}

export default Zakaz;