import React from 'react' ;
import { Link } from "react-router-dom";
import '../scss/ourwork.scss';
import jsheadLg from '../assets/language/ourwork-lang.json';

function OurWork ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 

   return (
      <section className="ourwork">
         <div className="ourwork-center">
            {/* блок с названием раздела */}
            <div className="ourwork-top">
            <Link to="/sdelatzakaz" title={lang.menu_01} rel="noreferrer"><p>{lang.menu_01}</p></Link>
            </div>
            {/* блок с менюшками (картинки + описание) */}
            <div className="ourwork-down">

               {/* блок с описанием Оформелание заявки... */}
               <div className="ourwork-down-studio prime-01">
                  <div className="ourwork-down-studio-img">
                     <img className="image" src="logo/l_005.gif" alt=""/>
                  </div>
                  <div className="ourwork-down-studio-txt">
                     <p>{lang.menu_02}</p>
                  </div>
               </div>

               {/* блок с описанием Написание сайтов... */}
               <div className="ourwork-down-studio prime-03">
                  <div className="ourwork-down-studio-img">
                     <img className="image" src="logo/l_007.gif" alt=""/>
                  </div>
                  <div className="ourwork-down-studio-txt">
                     <p>{lang.menu_04}</p>
                  </div>
               </div>

               {/* блок с линиями */}
               <div className="ourwork-down-lines">
                  <div className="ourwork-down-line-01"></div>
                  <div className="ourwork-down-line-02"></div>
                  <div className="ourwork-down-line-03"></div>
               </div>
               
               {/* блок с описанием Прорисовка шаблона... */}
               <div className="ourwork-down-studio prime-02">
                  <div className="ourwork-down-studio-txt">
                     <p>{lang.menu_03}</p>
                  </div>
                  <div className="ourwork-down-studio-img">
                     <img className="image" src="logo/l_006.gif" alt=""/>
                  </div>
               </div>

               {/* блок с описанием Передача клиенту... */}
               <div className="ourwork-down-studio prime-04">
                  <div className="ourwork-down-studio-txt">
                     <p>{lang.menu_05}</p>
                  </div>
                  <div className="ourwork-down-studio-img">
                     <img className="image" src="logo/l_008.gif" alt=""/>
                  </div>
               </div>

            </div>

         </div>
      </section>
   )
}

export default OurWork;