import React from 'react' ;
import '../../scss/MainPage/formzaya.scss';
import axios from 'axios';
import jsheadLg from '../../assets/language/formasvyaz-lang.json';

function FormZaya ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   // отправка формы с номером телефона
   const [nophone, setNophone] = React.useState(false);
   const [value, setValue] = React.useState('');
   const onChangeValue = event => {
      setValue(event.target.value);
      setNophone(false);
   } 
   // + имя пользователя
   const [namepost, setNamepost] = React.useState('');
   const onChangeName = event => {
      setNamepost(event.target.value);
   }
   // + емейл пользователя
   const [emailpost, setEmailpost] = React.useState('');
   const onChangeEmail = event => {
      setEmailpost(event.target.value);
   }
   // отправка возможных соц.сетей
   const [socTeleg, setSocTeleg] = React.useState(false);
   const onSocTeleg = event => {
      setSocTeleg(!socTeleg);
   }
   const [socWats, setSocWats] = React.useState(false);
   const onSocWats = event => {
      setSocWats(!socWats);
   }
   const [socViber, setSocViber] = React.useState(false);
   const onSocViber = event => {
      setSocViber(!socViber);
   }
   const [socFace, setSocFace] = React.useState(false);
   const onSocFace = event => {
      setSocFace(!socFace);
   }
   const [socSkipe, setSocSkipe] = React.useState(false);
   const onSocSkipe = event => {
      setSocSkipe(!socSkipe);
   }
   // отображение блока при успешной отправке
   const [formSaccess, setFormSaccess] = React.useState(false);
   function funCloseSaccess (i) {
      setFormSaccess(i)
   };

   const test = () => {
      if (value) {
         axios.post(`https://api.telegram.org/bot5852449573:AAEb3d_E6JnIru4KqCwuTbYuitZOZjUxpFs/sendMessage?chat_id=-820878379&text=Web-LeoDesign:%0A
- - - - - - - - - %0A
Имя: ${namepost}%0A
Teлефон: %2B${(value.split('+').join(''))}%0A
e-mail: ${emailpost}%0A
Соцсети: ${(socTeleg)?"Телеграм... ":""} ${(socWats)?"WatsApp... ":""} ${(socViber)?"Viber... ":""} ${(socFace)?"Facebook... ":""} ${(socSkipe)?"Skipe... ":""} %0A
         `);
         setFormSaccess(!formSaccess)
         setNophone(false);
      } else {
         setValue(lang.menu_07);
         setNophone(true);
      }
   } // -------------------------------

   return (
      <section className="formzaya" id="kontakts">
         <div className="formzaya-center">


            <div className="formzaya-block">
               {/* форма для ввода данных */}
               <div className="formzaya-block-left">
                  <input type="text" 
                  value={namepost} 
                  onChange={onChangeName} 
                  id="phone1" 
                  placeholder={lang.menu_01}/>
                  <input type="text" 
                  value={emailpost} 
                  onChange={onChangeEmail} 
                  id="phone2" 
                  placeholder={lang.menu_02}/>
                  <input type="text" 
                  value={value} 
                  onChange={onChangeValue} 
                  className={(nophone)?"phone-error":""}
                  id="phone3" 
                  placeholder={lang.menu_03}/>
               </div>

               {/* форма инфо и выбора соцсетей */}
               <div className="formzaya-block-right">
                  <div className="formzaya-block-right-txt">
                     <p>{lang.menu_04}</p>
                  </div>
                  <div className="formzaya-block-right-span">
                     <p>{lang.menu_05}</p>
                  </div>
                  {/* выбор соцсетей */}
                  <div className="formzaya-block-right-soc">
                     <label>
                        <input type="checkbox" id="myCheck1" name="fb1" value="" onChange={onSocTeleg}/>
                        <div className={(socTeleg)?"right-soc-img change-soc":"right-soc-img"}>
                           <img src="icon\i_007.gif" alt="Telegram" title="Telegram" />
                        </div>
                     </label>
                     <label>
                        <input type="checkbox" id="myCheck2" name="fb2" value="" onChange={onSocWats}/>
                        <div className={(socWats)?"right-soc-img change-soc":"right-soc-img"}>
                           <img  src="icon\i_008.gif" alt="WhatsApp" title="WhatsApp" />
                        </div>
                     </label>
                     <label>
                        <input type="checkbox" id="myCheck3" name="fb3" value="" onChange={onSocViber}/>
                        <div className={(socViber)?"right-soc-img change-soc":"right-soc-img"}>
                           <img src="icon\i_009.gif" alt="Viber" title="Viber" />
                        </div>
                     </label>
                     <label>
                        <input type="checkbox" id="myCheck4" name="fb4" value="" onChange={onSocFace}/>
                        <div className={(socFace)?"right-soc-img change-soc":"right-soc-img"}>
                           <img src="icon\i_006.gif" alt="Facebook Massage" title="Facebook Massage" />
                        </div>
                     </label>
                     <label>
                        <input type="checkbox" id="myCheck5" name="fb5" value="" onChange={onSocSkipe}/>
                        <div className={(socSkipe)?"right-soc-img change-soc":"right-soc-img"}>
                           <img src="icon\i_010.gif" alt="Skype" title="Skype" />
                        </div>
                     </label>
                  {/* кнопка для заявки */}
                  </div>
                  <div className="formzaya-block-right-but">
                     <input type="button" onClick={test} value={lang.menu_06} id="knopka_zayavka" />	
                  </div>
               </div>
            </div>

            {/* форма удачной отправки сообщения */}
            {formSaccess &&  (
            <div className="formzaya-saccess" onClick={() => funCloseSaccess(false)} >
               <p>{lang.menu_08}</p>
               <p>{lang.menu_09}</p>
            </div>
            )}
         </div>
      </section>
   )
}

export default FormZaya;