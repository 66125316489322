import React from 'react' ;
import jsheadLg from '../assets/language/headDown-lang.json';
import HeadDown from '../Components/HeadDown';
import RaytMe from '../Components/MainPage/RaytMe';
import FormZaya from '../Components/MainPage/FormZaya';
import BodyMain from '../Components/MainPage/BodyMain';
import DopUslug from '../Components/MainPage/DopUslug';
import AboutMe from '../Components/MainPage/AboutMe';
import BanerSoc from '../Components/MainPage/BanerSoc';
import OurWork from '../Components/OurWork';
import Primer from '../Components/Primer';
import Kamorka from '../Components/Kamorka';

function Main( {langID, tipPageHeader, setSearchHesh, setClassStInd, setSearchPage} ) {

   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 

   return (
      <section>
         <HeadDown langID={langID} tipPageHeader='mainHeader'/>
         <RaytMe langID={langID}/>
         <FormZaya langID={langID}/>
         <BodyMain langID={langID}/>
         <DopUslug langID={langID} />
         <AboutMe langID={langID}/>
         <BanerSoc langID={langID}/>
         <OurWork langID={langID}/>
         <Primer langID={langID} />
         <Kamorka langID={langID} setSearchHesh={setSearchHesh} setClassStInd={setClassStInd} setSearchPage={setSearchPage} />

      </section>
   )
}

export default Main;