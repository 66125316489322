import React from 'react' ;
import '../../scss/BlogPage/blogtopmenu.scss';
import BlogSearch from '../BlogPage/BlogSearch'

function BlogTopMenu ( {searchId, setSearchId, setSearchHesh, setSearchPage, searchHesh, classStInd, setClassStInd, setClassNomInd} ) {

   const [miniMenu, setMiniMenu] = React.useState(false)

   const mq1280 = window.matchMedia('(max-device-width: 1280px)')
   const mq900 = window.matchMedia('(max-device-width: 900px)')
 
   function butTopMenu_0 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      
      setSearchHesh("#Все статьи")
      setClassStInd(1)
      setMiniMenu(false)
      setClassNomInd(1)
   }
   function butTopMenu_1 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      setSearchHesh("#Программирование")
      setClassStInd(2)
      setMiniMenu(false)
      setClassNomInd(1)
   }
   function butTopMenu_2 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      setSearchHesh("#Дизайн")
      setClassStInd(3)
      setMiniMenu(false)
      setClassNomInd(1)
   }
   function butTopMenu_3 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      setSearchHesh("#Продвижение")
      setClassStInd(4)
      setMiniMenu(false)
      setClassNomInd(1)
   }
   function butTopMenu_4 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      setSearchHesh("#JavaScript")
      setClassStInd(5)
      setMiniMenu(false)
      setClassNomInd(1)
   }
   function butTopMenu_5 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      setSearchHesh("#HTML/CSS")
      setClassStInd(6)
      setMiniMenu(false)
      setClassNomInd(1)
   }
   function butTopMenu_6 () {
      if (mq1280.matches && !mq900.matches) {
         setSearchPage(8)
      } else if (mq900.matches) {
         setSearchPage(6)
      } else {
         setSearchPage(9)
      }
      setSearchHesh("#Технологии")
      setClassStInd(7)
      setMiniMenu(false)
      setClassNomInd(1)
   }


   return (
      <section className="blogtop">
         <div className="blogtop-menu">

         {/* меню с хештегами */}
            <div className="blogtop-menu-left">
               <div className={classStInd === 1 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_0()}>
                  <p>#Все</p>
               </div>
               <div className={classStInd === 2 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_1()}>
                  <p>#Программирование</p>
               </div>
               <div className={classStInd === 3 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_2()}>
                  <p>#Дизайн</p>
               </div>
               <div className={classStInd === 4 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_3()}>
                  <p>#Продвижение</p>
               </div>
               <div className={classStInd === 5 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_4()}>
                  <p>#JavaScript</p>
               </div>
               <div className={classStInd === 6 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_5()}>
                  <p>#HTML/CSS</p>
               </div>
               <div className={classStInd === 7 ? "blogtop-menu-hesh active" : "blogtop-menu-hesh" } onClick={() => butTopMenu_6()}>
                  <p>#Технологии</p>
               </div>
            </div>

            {/* мини меню мобильное */}
            <div className="blogtop-menu-mobile" onClick={() => setMiniMenu(!miniMenu)}>
               <div className="menu-mobile-cont">
                  <div className="menu-mobile-cont-span">
                     <p>{searchHesh}</p>
                  </div>
                  <div className="menu-mobil-cont-img">
                     <img className="image" src="icon\icon-05.svg" alt=""/>
                  </div>
               </div>
            </div>
            {miniMenu && (
            <div className="blogtop-menu-spisok">
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_1()}>
                  <p>#Программирование</p>
               </div>
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_2()}>
                  <p>#Дизайн</p>
               </div>
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_3()}>
                  <p>#Продвижение</p>
               </div>
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_4()}>
                  <p>#Java/PHP</p>
               </div>
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_5()}>
                  <p>#HTML/CSS</p>
               </div>
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_6()}>
                  <p>#Технологии</p>
               </div>
               <div className="blogtop-menu-spisok-span" onClick={() => butTopMenu_0()}>
                  <p>#Все статьи</p>
               </div>
            </div>

            )}




         {/* меню с поиском */}
            <div className="blogtop-menu-right">
               <BlogSearch searchId={searchId} setSearchId={setSearchId} setSearchPage={setSearchPage} />
            </div>

         </div>
      </section>
   )
}

export default BlogTopMenu;