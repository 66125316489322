import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/PrimerPage/primerdow.scss';
import jsheadLg from '../../assets/language/primerpage-lang.json';
import { useRef, useState } from 'react';

// модуль для свайпера (вертушки)
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { Autoplay, Navigation } from 'swiper/modules';



function PrimerDow ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];
   // всплывающий сладйер большой кратинки сайт 1
   const [isVissait, setIsVissait] = React.useState(false);
   function fnCallSayt (count) {
      setIsVissait(!isVissait);
   };

   return (
      <section className="primerdow">
         <div className="primerdow-center">

            <div className="primerdow-center-span">
               <div className="primerdow-center-span-txt">
                  <p>{lang.menu_01}</p>
               </div>
            </div>


            <div className="primerdow-big">
               <img className="image im-1180" onClick={() => {fnCallSayt(0)}} src="prim\pr_06-1180.jpg" alt=""/>
               <img className="image im-780" onClick={() => {fnCallSayt(0)}} src="prim\pr_06-780.jpg" alt=""/>
               <img className="image im-480" onClick={() => {fnCallSayt(0)}} src="prim\pr_06-480.jpg" alt=""/>
            </div>

         {/* всплывающий слайдер большой кратинки сайт 1*/}
         {isVissait &&  (
               <div className="primerdow-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-005-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-006-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-007-r.jpg" alt=""/></SwiperSlide>    
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-008-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-009-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-010-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-011-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-012-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-013-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-014-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-015-r.jpg" alt=""/></SwiperSlide>           
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-005-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-006-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-007-e.jpg" alt=""/></SwiperSlide>    
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-008-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-009-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-010-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-011-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-012-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-013-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-014-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-006\s_06-015-e.jpg" alt=""/></SwiperSlide>           
               </Swiper>)}

               <div className="primerdow-saitfor-img" onClick={() => {fnCallSayt(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}




         </div>
      </section>
   )
}

export default PrimerDow;