import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x006 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 2   // первая статья
   const i2 = 3   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Установка счетчика Яндекс.Метрики</span>
               <img className="image" src="../../content/st-006/001.jpg" alt=""/>
               
               <p className="sp-01"><b>Яндекс.Метрика</b> — это инструмент веб-аналитики, который помогает получать наглядные отчеты, видеозаписи действий посетителей, отслеживать источники трафика и оценивать эффективность онлайн- и офлайн-рекламы.</p>
               <p className="sp-01">Чтобы установить счетчик Яндекс.Метрики на сайт:</p>
               <p className="sp-01">Авторизуйтесь на странице Яндекс.Метрика или зарегистрируйтесь. Посчле чего нажмите <b>Добавить счетчик</b></p>
               <p className="sp-01">На открывшейся странице введите все необходимые данные, после чего примите условия Пользовательского соглашения и нажмите Создать счетчик.</p>
               <ul>
                  <li>Имя счётчика -  указанное имя будет отображаться на странице Мои счетчики и в верхнем меню переключения между счетчиками. Если не задано, используется значение поля Адрес сайта.</li>
                  <li>Адрес сайта - основной домен сайта. Поле обязательно для заполнения. Префикс схемы/протокола (http://, https://) указывать не следует.</li>
                  <li>Часовой пояс</li>
               </ul>
               <p className="sp-04">В поле Адрес Сайта вы можете указать путь сайта (path в структуре URL). Например, example.com/category/. При этом не указывайте адрес до определенного файла или фрагмента страницы (символа «#») — эти указания вызовут ошибку в поле для ввода. Кроме того, не будут учитываться переданные в URL параметры (часть адреса после символа «?»). Кириллические домены добавьте кириллическими символами, например сайт.рф. Не используйте Punycode.</p>
               <img className="image" src="../../content/st-006/002.jpg" alt=""/>


               <p className="sp-02">Дополнительные настройки</p>
               <p className="sp-01"><b>Включить автоматические цели</b> - Метрика собирает данные о различных действиях на сайте. Она может создавать цели автоматически на основе некоторых действий. Для этого включите опцию Автоматически отслеживать клики на номер телефона, email и отправку форм в настройках счетчика. Если вы создали счетчик недавно, опция уже включена.</p>
               <p className="sp-01">Также вы можете воспользоваться списком рекомендуемых целей для различных индустрий и поддержать на сайте отправку событий с заданными идентификаторами. Тогда цели для отслеживания отправленных событий будут созданы автоматически. Кроме этого, если вы используете на сайте Google Analytics и Facebook Pixel*, Метрика может распознавать разметку рекомендуемых целей этих систем и автоматически создавать цели для событий. Метрика предлагает рекомендуемые цели в зависимости от сферы вашего бизнеса:</p>
               <ul>
                  <li>Услуги</li>
                  <li>Недвижимость</li>
                  <li>Интернет-магазины</li>
                  <li>Образование</li>
                  <li>Образование</li>

               </ul>
               <p className="sp-03">Цели начинают отображаться, когда Метрика фиксирует на сайте хотя бы одно из перечисленных выше событий — на странице Цели с названием, например «Автоцель: Переходы в мессенджер».</p>
               <p className="sp-01"><b>Включить запись визитов, карты и аналитику форм</b> - Для этого установите переключатель Вебвизор, карта скроллинга и аналитика форм в положение Вкл. Эта опция также подключает карту кликов и карту ссылок.</p>
               <p className="sp-01">Технология Вебвизор позволяет проанализировать поведение посетителей на своем сайте на качественно новом уровне. Вы можете воспроизвести действия посетителей в формате видео и узнать, что они делают на каждой странице, как передвигают курсор мыши, кликают по ссылкам. Детальный анализ поведения посетителей помогает выявить проблемы в навигации, логике и юзабилити, а в результате — повысить конверсию сайта.</p>
               <p className="sp-01">Аналитика форм — инструмент, предназначенный для сайтов, активно использующих формы для заполнения. Например, строку поиска по сайту, форму заказа или форму обратной связи. Аналитика форм позволяет понять, как именно посетители сайта взаимодействуют с формами.</p>
               <p className="sp-01"><b>Добавить дополнительные адреса сайта</b> - это может понадобиться, если:</p>
               <ul>
                  <li>по разным адресам отвечает один и тот же сайт с одним и тем же установленным счетчиком</li>
                  <li>вы хотите получать статистику по нескольким сайтам с помощью одного счетчика</li>
                  <li>вы хотите получать уведомления о проверках доступности сайта</li>
               </ul>
               <p className="sp-01"><b>Подписаться на уведомления</b> - метрика может присылать вам новости и рекомендации по работе со счетчиком. Чтобы получать сообщения, в поле Почта для уведомлений укажите адрес электронной почты, на которую вы хотите получать уведомления.</p>
               <p className="sp-01"><b>Не сохранять полные IP-адреса посетителей сайта</b> - обычно в Яндекс Метрике сохраняется полный обезличенный IP-адрес посетителя. При включенной опции в системе запишется неполный адрес. Например, значение 192.0.2.2 изменится на 192.0.2.0. Это снижает точность определения региона посетителя сайта. Обычно в Яндекс Метрике сохраняется полный обезличенный IP-адрес посетителя. При включенной опции в системе запишется неполный адрес. Например, значение 192.0.2.2 изменится на 192.0.2.0. Это снижает точность определения региона посетителя сайта.</p>
               <p className="sp-04">Также можно подтвердить согласие с Договором об обработке данных в Яндекс Метрике. Это необходимо, если ваша компания зарегистрирована в ЕС или Швейцарии — или вы обязаны соответствовать Генеральному регламенту о защите данных (General Data Protection Regulation, сокращенно GDPR) по другим причинам.</p>
               <img className="image" src="../../content/st-006/003.jpg" alt=""/>

               <p className="sp-02">Установка счетчика на сайт</p>
               <p className="sp-01">Чтобы установить метрику на свой сайт, все, что нужно сделать, это добавить специальный код HTML на все страницы сайта. Но, что важно, способ, которым можно добавить этот HTML-код на страницы, зависит от структуры сайта. </p>

               <p className="sp-05">Установка счетчика на сайт без CMS</p>
               <p className="sp-01">Установка счетчика на сайт без CMS. Если все страницы вашего сайта — отдельные файлы, написанные вручную, добавьте код счетчика в HTML-код всех страниц сайта. Рекомендуем разместить его внутри элементов head или body как можно ближе к началу страницы — от этого зависит полнота и корректность подсчета данных.</p>
               <p className="sp-05">Установка счетчика на сайт с CMS</p>
               <p className="sp-01">В Метрике на странице Настройка, на вкладке Счетчик, нажмите кнопку Скопировать. Скопированный код добавьте в файл, который отвечает за генерацию заголовка (header) всех страниц сайта. Для наиболее популярных CMS разработаны официальные плагины Метрики, которые позволяют установить код счетчика автоматически. Как правило, для этого достаточно установить плагин и пройти авторизацию. Если на одном сайте используются несколько CMS (например, одна отвечает за содержимое сайта, а другая — за форум), код счетчика необходимо разместить в каждой из них.</p>
               <p className="sp-03">CMS-система — программа для управления содержимым сайта (Content Management System). С ее помощью можно загружать на сайт и удалять с него тексты, изображения или видео, не имея навыков программирования.</p>
               <p className="sp-01">После установки счетчик будет доступен на странице Мои счетчики. Данные начинают собираться сразу же. Счетчик также можно установить с помощью системы управления тегами; можно установить сразу несколько счетчиков. Вы можете отредактировать код счетчика (например, включить или отключить сбор определенных данных). При этом необходимо заменить код на всех страницах сайта. После редактирования кода все накопленные ранее данные не пересчитываются.</p>
               <img className="image" src="../../content/st-006/004.jpg" alt=""/>

              

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x006