import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x008 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 5   // первая статья
   const i2 = 4   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Основные стили в фотографии</span>
               <img className="image" src="../../content/st-008/001.jpg" alt=""/>
               
               <p className="sp-01">Еще недавно трудно было представить все современные способы применения фотографии. Разве всего 15 лет назад кто-то мог предположить, что цифровые камеры произведут революцию во всех сферах фоторемесла — от художественной до коммерческой съемки, от фотожурналистики до моментального фото? И мы участвуем в формировании новой реальности и нового порядка, в котором можно делать снимки, просматривать их и делиться ими сразу со всем миром. Как правило, у каждого фотографа есть креативная специализация — один или несколько жанров, в которых он профессионально работает и зарабатывает. Это может быть изображение только людей, или масштабных зданий, или драматичных событий.</p>
               <p className="sp-01">Условно можно разделить фотографию на три больших блока:</p>
               <ul>
                  <li><b>Жанры коммерческой фотографии</b> - связанные с рекламой и созданием снимков на заказ.</li>
                  <li><b>Условно-коммерческие</b> - необязательно «продающие».</li>
                  <li><b>Некоммерческие</b> - более близкие к чистому искусству.</li>
               </ul>
               <p className="sp-02">Жанры коммерческой съёмки</p>
               <p className="sp-01">Фотография может быть искусством, но большинство покупают камеру не только, чтобы сделать красивую картинку и получить лайки, а чтобы на этом зарабатывать. Такую область фотографии называют коммерческой. Коммерческой фотографией называют любую съёмку, где автор получил деньги за работу. Даже фото на документы  можно отнести к коммерческой фотографии. </p>

               <img className="image" src="../../content/st-008/002.jpg" alt=""/>
               <p className="sp-05">Fashion</p>
               <p className="sp-01">Это фотосъёмка, основная тема которой — демонстрация одежды, аксессуаров, макияжа и других элементов моды. Эти фотографии используют в каталогах, журналах, на сайтах, в социальных сетях и наружной рекламе. Цель жанра Fashion — привлечь внимание к предметам, показать их особенности, преимущества и вызвать у зрителя желание ими обладать.</p>
               <p className="sp-04">На коммерческих съёмках для каталогов фотографу нужно быть выносливым, уметь работать быстро с огромными объёмами вещей и чётко соблюдать все требования заказчика. Одни бренды продумывают всё заранее, а уже после этого, в самом конце, выбирают фотографа, который не оказывает никакого влияния на процесс. Другие же могут задать направление, но дать автору больше свободы, чем просто нажимать на кнопку.</p>
               <p className="sp-01">Работа фотографа в этом жанре потребует от вас определенных навыков и отдачи:</p>
               <ul>
                  <li>правильно выставлять свет, схему освещения, как в помещении, так и на улице, и сцену так, чтобы в центре внимания был сам предмет съемки, а не модель</li>
                  <li>следить за модойЮ быть в тренде, понимать о том, что он снимает, для какой культуры и аудитории этот продукт</li>
                  <li>взаимодествовать и быт на одной волне со стилистами, визажистами и прочими сопутствующими людьми,  актже, само собой, владеть графическими редакторами</li>
               </ul>

               <img className="image" src="../../content/st-008/003.jpg" alt=""/>
               <p className="sp-05">Интерьерная фотография</p>
               <p className="sp-01">Это жанр фотоискусства, изображающий внутренний вид помещений. Интерьерная фотография носит в первую очередь прикладной характер. Фотосъемка интерьеров актуальна для бизнеса дизайнеров, строителей, риелторов, владельцев и оформителей помещений, event-компаний. Главная задача — показать зрителю дизайн и наполнение квартир, общественных пространств, кафе, галерей. У этого направления есть особенность: в кадре не бывает людей и «лишних» предметов.</p>
               <p className="sp-01">Интерьерный фотограф должен уметь выстраивать сцену в ограниченном, иногда совсем маленьком пространстве. Правильно растявлять предметы, убирать из кадра лишние — слишком яркие или слишком «бытовые». Работать с компактным световым оборудованием внутри помещений.</p>

               <img className="image" src="../../content/st-008/004.jpg" alt=""/>
               <p className="sp-05">Предметная фотография или натюрморт</p>
               <p className="sp-01">В жанре предметной фотографии делают снимки отдельных объектов; как маленьких, например ювелирных изделий, так и крупных — мебели или экспонатов в музеях. Обычно предметные фото делают для рекламы, и их задача — презентовать преимущества и качества вещей, чтобы привлечь внимание покупателя и мотивировать к покупке.</p>
               <p className="sp-01">В предметной фотографии, как правило, не допускается нечёткости, размытия, отклонения по экспозиции кадра, засветок, виньетирования, шумов и других артефактов, которые могут легко послужить оригинальным художественным приёмом при творческой съёмке.</p>
               <p className="sp-01">Для рекламной съёмки характерен творческий подход при построении кадра, создание концепции, использование дополнительного реквизита, декораций, интересных локаций. Расстановка света выполняется индивидуально, чтобы наилучшим образом осветить все элементы композиции. Рекламный снимок призван передать определенное ощущение (эмоцию), которое будет ассоциировано с продуктом и мотивировать покупателя к дальнейшей покупке.</p>
               <p className="sp-04">Такие съёмки намного более сложные, кропотливые и дорогостоящие, нежели потоковые, и требуют качественного постпродакшена. Количество фото на выходе обычно составляет от 1 до 5 штук за съемочный день. У зарубежных мастеров данного жанра даже существует на эту тему высказывание — «one day — one shot», что в переводе означает «один день — один снимок»</p>

               <img className="image" src="../../content/st-008/005.jpg" alt=""/>
               <p className="sp-05">Свадебная фотография</p>
               <p className="sp-01">Это жанр съёмки, в котором заказчиками выступают пары, а не компании или бренды. Эти фотографии как таковые не «продают» и не рекламируют, но свадебные фотографы работают только на коммерческих условиях.</p>
               <p className="sp-01">Свадебному фотографу нужны особенные навыки:</p>
               <ul>
                  <li>Управлять позами участников.</li>
                  <li>Выстраивать романтический нарратив с помощью окружающего пейзажа или интерьеров.</li>
                  <li>Работать с первого дубля и фиксировать уникальные моменты.</li>
                  <li>Из тысяч сделанных снимков выбирать десятки действительно качественных и ярких.</li>
               </ul>
               <p className="sp-01">В жанре есть два направления: классический и репортажный. В классическом фотограф делает постановочные снимки свадебного дня, фиксирует романтические моменты с позированием. В репортажном — наблюдает через объектив за праздником, но не вмешивается, фотографирует проявления живых эмоций и случайные моменты.</p>

               <p className="sp-02">Условно-коммерческие жанры</p>
               <p className="sp-01">К этой категории мы отнесли жанры съёмок, которые могут делать и для коммерческих, и для некоммерческих, творческих целей. Например, для социальных и образовательных проектов или для собственного креативного портфолио.</p>

               <img className="image" src="../../content/st-008/006.jpg" alt=""/>
               <p className="sp-05">Портрет</p>
               <p className="sp-01">Портретные фотографии показывают внешность, передают характер и настроение героев, их занятия или статусы, а объектом съёмки могут быть не только люди, но и животные. Портреты делают и для бизнес-целей, и для личных: для портфолио, резюме и презентаций, для сайта или блога, для плаката или афиши. В рекламе тоже используют этот жанр: например, когда для рекламного баннера нужна фотография селебрити-амбассадора.</p>
               <p className="sp-01">Портрет – один из самых востребованных в фотоиндустрии жанров. И он же – один из самых сложных. Лицо человека… это ведь не только черты лица, это глаза, выражение в целом, отражающее индивидуальность и характер. Красивый, хорошо снятый человек может получиться безликим, если фотограф попросту не умеет делать портреты. При этом можно сделать изумительный портрет любой модели даже с явными недостатками внешности. </p>
               <p className="sp-03">Фотографический портрет — это фотография человека, который знает, что его фотографируют, и то, что он делает с этим знанием, является такой же частью фотографии, как то, что на нем надето или как он выглядит. — Ричард Аведон</p>
               <p className="sp-01">Перед тем, как приступить к портретной съемке, нужно разобраться с некоторыми важными деталями, без которых портрет не сможет получиться удачным.</p>
               <ul>
                  <li>Правильно подобранное фоновое изображение – это половина успеха. Важно соблюдать ряд фаткоров, таких как кадрирование всего ненужного на заднем плане, не забывать о функции размытия, регулировать расстояние от модели до фона и правильно использовать солнечные блики.</li>
                  <li>Глаза – отражение истинной сущности человека - d процессе портретной съемки фокусироваться необходимо именно на глазах. Выгодно подчеркнутые глаза способны привлечь внимание и ко всей композиции, в противном случае вы не получите желаемого эффекта и ваше фото останется просто незамеченным.</li>
                  <li>Одним из важнейших элементов портретной фотосъемки является правильный подбор техники. В частности здесь речь идет об используемых объективах. Здесь необходимо учитывать то, какой именно кадр вам нужен.</li>
               </ul>
               
               <img className="image" src="../../content/st-008/007.jpg" alt=""/>
               <p className="sp-05">Репортаж</p>
               <p className="sp-01">Фотографии в этом жанре демонстрируют события любого масштаба — и мировой значимости, и локальные. Их задача — передать динамику сцен и состояние участников так, чтобы у зрителей появилось ощущение присутствия.</p>
               <p className="sp-01">Работа в жанре репортажа требует фокусироваться на фактах, не вмешиваться в ход событий и не манипулировать участниками. Снимать сериями, которые визуально фиксируют начало, развитие, кульминацию и завершение истории. А также важны интсрументы, в частности , использование оборудования, которое позволяет фотографировать дальние объекты, — например, зум-объективы, широкоугольные и телеобъективы.</p>

               <img className="image" src="../../content/st-008/008.jpg" alt=""/>
               <p className="sp-05">Архитектурная фотография</p>
               <p className="sp-01">Архитектурная фотография — популярный самостоятельный жанр. Снимки зданий, городских сооружений и памятников пользуются повышенным вниманием: реклама и привлечение внимания к масштабным застройкам, популяризация туристических мест и продвижение достопримечательностей. Качественные снимки всегда требуются для печатной продукции и городских билбордов, ведения сайтов и аккаунтов крупных компаний.</p>
               <p className="sp-01">Фотосъёмку домов, дворцов, храмов, вокзалов, стадионов и руин можно проводить и на коммерческой основе, и ради искусства. Этот жанр нужен, чтобы фиксировать масштаб эстетики и детали зданий и сооружений, и он тесно связан с предметным и пейзажным.</p>
               <p className="sp-01">В работе архитектурного фотографа есть особенности:</p>
               <ul>
                  <li>Необходимо иметь тренированный глазомер, чтобы чётко видеть геометрию и линии.</li>
                  <li>Стоит быть готовым к экспериментам с точками съёмки — например, забираться на большую высоту.</li>
                  <li>Источник освещения в большинстве случаев только естественный, максимум — уличные фонари, поэтому для фотографирования часто приходится дожидаться определённого времени суток, погоды и сезона.</li>
               </ul>

               <img className="image" src="../../content/st-008/009.jpg" alt=""/>
               <p className="sp-05">Ню</p>
               <p className="sp-01">Ню, или нюд, — это жанр художественного изображения обнажённого человека. Технически он мало отличается от портретного жанра, но в нём есть важный концептуальный момент: в ню-фотографиях крайне важно не создавать вульгарный, порнографический образ. Концентрация внимания зрителя должна быть на формах тела и красоте пластики.</p>
               <p className="sp-01">Съемка ню – это очень интимный, творческий процесс, в котором нет места для стеснительности и зажатости. Тем не менее, деликатность события вынуждает придерживаться особых правил и включить профессионализм на максимум.</p>
               <p className="sp-01">Фотографию жанра ню можно разделить на три поджанра: это эротическое фото (картинка направлена на восприятие посредством инстинктов), арт-фотография (главное показать красоту обнаженного тела, с направлением на интеллектуальное восприятие зрителем) и порнография (наличие грубой сексуальной направленности). Мы поговорим о самом популярном поджанре – арт-ню фотография.</p>

               <p className="sp-02">Некоммерческие фото</p>
               <p className="sp-01">В этом направлении фотограф не зависит от технического задания или мнения заказчика: он сам выступает автором идеи и поэтому может творчески реализоваться. Некоммерческие жанры существуют не для того, чтобы заработать, а чтобы вызвать дискуссию у аудитории, рассказать историю, сделать благотворительный проект.</p>

               <img className="image" src="../../content/st-008/010.jpg" alt=""/>
               <p className="sp-05">Street-фотография</p>
               <p className="sp-01">Это в первую очередь изображения пейзажей, архитектуры, культуры и людей, характеризующих определённый регион. Такие снимки образовывают, показывают красоту и разнообразие мира, привлекают к местности других путешественников. Основные элементы стрит-фото: спонтанность, тщательное наблюдение и «широко открытые глаза», готовность запечатлеть все, что появляется в видоискателе и кажется значимым, волнующим, интересным.</p>
               <p className="sp-01">Еще один ключевой аспект стрит-фотографии — наличие ощущения, что кадр снят не запланированно, без предварительной договоренности. Поэтому фотографии, сделанные на публичных мероприятиях отличаются по ощущениям от случайных — в первом случае редко получается сделать спонтанный кадр. Однако, их тоже можно причислить к стит-фотографии — всегда есть место гибкости и эксперименту, в рамках жанра.</p>
               <p className="sp-03">Туристическая фотография (от английского выражения «travel photo») - отдельный жанр фотоискусства, который отражает впечатления путешественника и его ощущения, наблюдения от путешествий: природные и культурные особенности, этнические нюансы, люди с их эмоциями и характерами, памятники исторические и природные, интересные детали местности и так далее.</p>
               <p className="sp-01">Основная отличительная особенность такой фотосъемки – выбор фототехники (в поездку с собой очень сложно взять весь набор объективов и светофильтров со вспышками). Техника должна быть универсальной, удобной и практичной.</p>
               <p className="sp-01">Фотограф погружается в окружающую его среду, наслаждается пейзажами, городом. Это помогает сделать естественные и натуральные снимки, не скованные и без ощутимого позерства, получить документальные и красивые фотографии. Это же можно сказать и о животном мире. Когда у фотографа есть время привыкнуть к животным, а у животных есть возможность привыкнуть к человеку – получаются уникальные кадры, окупающие потраченное время и усилия. Животные не ведут себя стеснительно или скованно.</p>
               
               <img className="image" src="../../content/st-008/011.jpg" alt=""/>
               <p className="sp-05">Пейзаж</p>
               <p className="sp-01">Пейзажное фото изображает естественную природу и созданный человеком ландшафт. Задача жанра — не просто показать картинку, а вызвать эмоции у зрителя. Пейзаж отличается от натюрморта или снимков с людьми тем, что в нём нельзя скорректировать композицию. Фотохудожник не может физически убрать гору или дерево из кадра — только найти ракурс, в котором их не видно.</p>
               <p className="sp-01">Особенности фотосъёмки в пейзажном жанре такие:</p>
               <ul>
                  <li>Надо понимать, как работать с ближним, дальним и средним планами, чтобы объекты выглядели эффектно.</li>
                  <li>Работать придётся в любую погоду и только при доступном естественном освещении.</li>
                  <li>А ещё современный пейзажный фотограф должен уметь снимать на дрон.</li>
               </ul>

               <img className="image" src="../../content/st-008/012.jpg" alt=""/>
               <p className="sp-05">Документальная фотография</p>
               <p className="sp-01">До недавнего времени в профессиональном языке фотографов отсутствовало само понятие «документальная фотография». На сегодняшний день оно обозначает жанр фотографии, изображающий события и явления социального характера через глубокое погружение в тему. Нередко документальные фотопроекты создают для того, чтобы использовать в качестве иллюстраций к тексту лонгрида или представить их публике в виде выставки или книги.</p>
               <p className="sp-01">Зачастую этот вид фотографии решает задачи фотожурналистики, именно поэтому его довольно часто относят к ее жанрам. Многие придерживаются того мнения, что фотодокументалистика главным образом должна выполнять информационные функции, но для некоторых она стала гораздо большим, чем простая фиксация каких-либо злободневных моментов и пересказывание сюжета. Она демонстрирует авторский взгляд на освещаемую проблему и призывает к определенным действиям. Если проанализировать различные источники, то можно выделить основные черты, которые отличают данный жанр от других:</p>
               <ul>
                  <li>запечатление социальной проблемы, которая вызывает резонанс</li>
                  <li>эстетичность кадров в сочетании с их естественностью и реалистичностью</li>
                  <li>обычно это несколько фотографий или даже целая развернутая серия снимков, связанных между собой общей темой или идеей</li>
                  <li>как правило, этот жанр тесно связан с политическими веяниями и сам претендует на политическое влияние</li>
                  <li>документальная фотография носит публичный характер</li>
               </ul>
               <p className="sp-01">Документальные фотографии – уникальные, правдивые и прямые визуальные историко-художественные свидетельства тех или иных событий, в основе которых лежит серьезная сюжетная завязка. В документальном фото выделяют свои поджанры: уличная фотография, хроника, типология, социальный репортаж. Деление, конечно, весьма условно.</p>

               <img className="image" src="../../content/st-008/013.jpg" alt=""/>
               <p className="sp-05">Астрофотография</p>
               <p className="sp-01">Съёмка космических объектов — отдельный жанр фотоискусства, который условно делится на исследовательский и художественный. В исследовательском работают преимущественно астрономы, а художественный — это кадры небесных тел, которые делают люди не ради науки.</p>
               <p className="sp-01">Это технически сложное направление в фотографии, и для хороших, впечатляющих снимков нужна достаточно продвинутая техника. Также, для фотографа важно ориентироваться в карте звёздного неба и уметь читать астрономические прогнозы, чтобы знать, когда будет удачная обстановка для фото. Ну и конечно уметь выставлять экспозицию, выдержку и подбирать фокусное расстояние. </p>

               <img className="image" src="../../content/st-008/014.jpg" alt=""/>
               <p className="sp-05">Fine Art</p>
               <p className="sp-01">Это креативный жанр фотографии, где видение автора и идея, которую он хочет сообщить, — ключевой фактор съёмки. Это могут быть и постановочные кадры, и фотографии «живой» реальности, но главное, что они передают видение фотохудожника. В таких снимках всегда есть поэтичность, и они подталкивают зрителя к рефлексии.</p>
               <p className="sp-01">В отличие от других видов, суть художественной фотографии заключается в том, чтобы запечатлеть то, что видит художник, а не то, что находится перед камерой.</p>
               <p className="sp-01">Сделать снимок может каждый, но не каждый является настоящим художником. Художественное изображение является чем-то большим, чем просто прямое или буквальное изображение сцены или темы. Настоящий мастер обладает собственным узнаваемым стилем, который преобразуется с развитием техники и изменением сюжетов. В своих работах Fine Art фотографы применяют художественные концепции и компоненты, учитывают такие элементы, как пространство, баланс, линия, цвет, глубина, текстура, форма, и, конечно, свет.</p>
               <p className="sp-01">Fine Art изображение выражает эмоции фотографа и его эстетическое видение. Это художественное произведение, а не просто снимок, сделанный камерой. Fine Art фотография выходит за рамки того, что на самом деле находится перед камерой. Это личная интерпретация художником ситуации, которая может сильно отличаться от реальности.</p>

              

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x008