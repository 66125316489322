import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x003 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 0   // первая статья
   const i2 = 1   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Flexible Box - что такое флексы</span>
               <img className="image" src="../../content/st-003/001.jpg" alt=""/>
               
               <p className="sp-01"><b>FelxBox</b> (он же просто flex) - Flexible Box, модель одномерного-направленного макета и как один из методов распределения пространства между элементами в интерфейсе. А по-простому – способ выравнивание элементов в одном контейнере, например, для отображения товаров в интернет-магазине. Считается одномерным, так как работает с оной плоскостью (вертикальной или горизонтальной) за одно действие, тогда как тот же grid – является двумерным. </p>
               <p className="sp-01">Чтобы создать гибкий контейнер, мы устанавливаем значение свойства контейнера области display в flex или inline-flex. </p>
               <p className="sp-05">display: flex; <br></br>
               /* начальный ситаксис */</p>
               <p className="sp-01"><b>Inline-flex</b> – установит все дочерние flex - элементы в одну тсроку.</p>
               <img className="image" src="../../content/st-003/002.jpg" alt=""/>

               <p className="sp-01">Код для последнего примера:</p>
               <img className="image" src="../../content/st-003/003.jpg" alt=""/>

               <p className="sp-01">Ключевой особенностью flexbox является возможность выравнивать и выравнивать элементы по главной и поперечной осям, а также распределять пространство между гибкими элементами. Обратите внимание, что эти свойства должны быть установлены для гибкого контейнера, а не для самих элементов.</p>

               <p className="sp-02">Основные свойства:</p>
               <p className="sp-01">У flex-контейнера есть две оси: главная и перпендикулярная ей.По умолчанию все предметы располагаются вдоль главной оси — слева направо.</p>
               <p className="sp-05">flex-direction: row;<br></br>
               /* как flex-элементы располагаются во flex-контейнере по оси (row/column) и направлению слева/справа (normal или row-reverse/column-reverse) */
                </p>
               <p className="sp-01">Если вы выберете row или row-reverse, ваша главная ось будет проходить вдоль строки в линейном направлении.</p>
               <p className="sp-01">Выберите column или column-reverse и ваша основная ось будет проходить сверху вниз — в направлении блока.</p>
               <img className="image" src="../../content/st-003/004.jpg" alt=""/>

               <p className="sp-05">flex-wrap: wrap;<br></br>
            /* задаёт правила вывода flex-элементов — в одну строку или в несколько, с переносом блоков. */</p>
               <p className="sp-01"><b>flex-wrap: nowrap</b> - Расположение в одну линию, может привести к переполнению контейнера. </p>
               <p className="sp-01"><b>flex-wrap: wrap</b> - Расположение в несколько линий. </p>
               <p className="sp-01"><b>flex-wrap: wrap-reverse</b> - Ведёт себя так же, как и wrap, но cross-start и cross-end инвертированы.</p>
               <p className="sp-05">/* flex-flow: flex-direction flex-wrap */<br></br>
               flex-flow: row wrap;</p>
               <p className="sp-01">flex-flow – объединяет два этих элемента, обычно с него и начинается код.</p>
               <p className="sp-01">Свойство <b>justify-content</b> используется для выравнивания элементов по главной оси, направление, в котором flex-direction задан поток. </p>
               <p className="sp-05">justify-content: space-evenly;</p>
               <p className="sp-01"><b>justify-content: flex-start</b> - элементы будут выровнены по начальному краю контейнера</p>
               <p className="sp-01"><b>justify-content: flex-end</b> - выровнять их с конца </p>
               <p className="sp-01"><b>justify-content: center</b>- выровнять по центру.</p>
               <p className="sp-01"><b>justify-content: space-between</b> – крайние элементы ровно по краям, остальные по центру с одинаковым расстоянием между ними </p>
               <p className="sp-01"><b>justify-content: space-around</b> - чтобы создать одинаковое пространство справа и слева от каждого элемента, но крайние элементы будут иметь половинное пространство (1/2) от края.</p>
               <p className="sp-01"><b>justify-content: space-evenly</b> - чтобы вокруг элементов было одинаковое пространство, как по середине, так и с краев</p>
               <p className="sp-01">Свойство <b>align-items</b> выравнивает элементы по поперечной (второстепенной) оси. Свойство <b>align-content</b> – выравнивает ряды колонок.</p>
               <p className="sp-05">align-items: flex-start; <br></br>
               /* выравнивние элементов контейнера относительно  второстепенной оси (
               flex-start, flex-end, center, stretch - во всю длинну, baseline - как в свойствах каждого элеменета) */ <br></br>
               align-content: space-between;<br></br>
               /* выравнивает ряды колонок (space-between = отодвинуть друг от друга по краям, space-around / space-evenly - равномерно распределить) */</p>
               <p className="sp-01"><b>stretch</b> - по умолчанию растягиваются до высоты гибкого контейнера. Это может быть продиктовано высотой самого высокого элемента в контейнере или размером, установленным в самом гибком контейнере.</p>
               <p className="sp-01"><b>flex-start, flex-end, center</b> – все как у justify-content</p>
               <img className="image" src="../../content/st-003/005.jpg" alt=""/>
               
               <p className="sp-02">Свойства гибких элементов внутри flex-box:</p>
               <p className="sp-01">Чтобы иметь больше контроля над гибкими элементами, мы можем нацеливаться на них напрямую. Мы делаем это с помощью трех свойств: <b>flex-grow</b>, <b>flex-shrink</b>, <b>flex-basis</b>, и вместе это одно большой свойство <b>flex</b></p>
               <p className="sp-05">flex: flex-grow flex-shrink flex-basis</p>
               <p className="sp-01"><b>flex-basis</b> – это то, что определяет размер этого элемента с точки зрения пространства. Начальное значение этого свойства auto— в этом случае браузер проверяет, есть ли у элементов размер. Если у элементов нет размера, то в качестве гибкой основы используется размер содержимого.</p>
               <p className="sp-01">Если для <b>flex-grow</b> свойства установлено положительное целое число, гибкие элементы могут расти вдоль главной оси от их flex-basis. Это приведет к тому, что элемент растянется и займет все доступное пространство на этой оси или часть доступного пространства, если другим элементам также разрешено расти.</p>
               <p className="sp-03">flex-grow: 1 - доступное пространство в гибком контейнере будет поровну разделено между нашими элементами, и они растянутся, чтобы заполнить контейнер полностью по главной оси.</p>
               <p className="sp-01">Свойство flex-grow можно использовать для пропорционального распределения пространства. Если мы присвоим нашему первому элементу flex-grow значение 2, а остальным элементам значение 1, то первый элемент займет в два раза больше места, чем другие (растянется больше)</p>
               <p className="sp-01">Если flex-grow - свойство связано с добавлением пространства на главной оси, то <b>flex-shrink</b> - управляет тем, как оно удаляется. Если в контейнере недостаточно места для размещения наших элементов и flex-shrink установлено в положительное целое число, то элемент может стать меньше, чем flex-basis. Как и в случае с flex-grow, можно назначать разные значения, чтобы один элемент уменьшался быстрее, чем другие — элемент с более высоким значением, установленным для flex-shrink, будет уменьшаться быстрее, чем его братья и сестры, которые имеют более низкие значения.</p>
               <p className="sp-04">Если говорить более просто, то flex-basis – это значение бокса внутри контейнера по умолчанию (его исходный размер, что-то типа минимального (но это не совсем так)), скажем 200px. Flex-grow – это пропорция для растягивания. Когда содержимое контейнера больше, чем внутренние боксы, то они растянутся, чтобы заполнить свободное пространство.Если задать одному элементу число больше, чем у других, скажем, 2 – 1 – 1 – 1, то он заберет ровно в два раза больше места. flex-shrink по сути тоже самое, только в другую сторону и определяет скорость сжатия. Это значит, что при растягиваниии всех элементов во всю длину контейнера, выделеный нами элемент с большим числом будет иметь большую скорость сжатия, и тем самым может даже стать меньше, чем его базовая ширина (просто он будет сжиматься быстрее до тех пор, пока остальные контейнры не достигнут своей базовой ширины.) </p>

               <img className="image" src="../../content/st-003/006.jpg" alt=""/>
               <p className="sp-02">Свойство для отдельно – взятого элемента.</p>
               <p className="sp-05">align-self: normal;<br></br>
               /* выравнивает конкретно-взятый элемент</p>
               <p className="sp-01"><b>auto</b> - Вычисляет значение родительского объекта align-items.</p>
               <p className="sp-01"><b>normal</b> - эффект этого ключевого слова зависит от режима макета, в котором мы находимся:</p>
               <p className="sp-01"><b>self-start / self-end</b>  - Выравнивает элементы, которые должны быть заподлицо с краем контейнера</p>
               <p className="sp-01"><b>flex-start / flex-end</b> - Край перекрестного поля гибкого элемента совмещен с начальным краем линии (выравниванием других элементов контейнера)</p>
               <p className="sp-01"><b>center</b> - Поле поля гибкого элемента центрируется внутри линии на поперечной оси. </p>
               <p className="sp-01"><b>baseline, first baseline, last baseline</b> - выравнивает элемента по боазовой линии </p>
               <p className="sp-01"><b>stretch</b> – растягивается вдоль поперечной оси элементов</p>
               <p className="sp-01"><b>safe</b> - Если размер элемента выходит за пределы контейнера выравнивания, элемент выравнивается так, как если бы режим выравнивания был start. <b>unsafe</b> - Независимо от относительных размеров элемента и контейнера выравнивания, заданное значение выравнивания учитывается.</p>

               <p className="sp-05">order: 1;<br></br>
               /* указывает порядок элементов в контейнере (по умол. = 1), чем выше число - тем "первее" будет элемент */</p>
               <p className="sp-01">Свойство <b>order</b> определяет порядок, используемый для размещения flex элементов в их flex контейнере. Элементы располагаются в восходящем порядке по значению order. Элементы с одинаковым значением order располагаются в том порядке, в каком они находятся в исходном коде.</p>
               <p className="sp-01">Благодаря нему, мы можем располагать необходимый нам бокс-элемент из всего массива (а он идет по порядку по мере своего размещения в коде) в нужное нам место в сетке. </p>
               <img className="image" src="../../content/st-003/007.jpg" alt=""/>



   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x003