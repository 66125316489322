import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/ZakazPage/zakuslugy.scss';
import jsheadLg from '../../assets/language/zakuslugy-lang.json';

function ZakUslugy ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="zakuslugy">
         <div className="zakuslugy-center">
            <div className="zakuslugy-span">
               <p>{lang.menu_01}</p>
            </div>

            {/* блок с контентом (картинка, текст, цена) */}
            <div className="zakuslugy-blockcont">
               <div className="blockcont-round r-img">
                  <img className="image" src="logo\l_009.gif" alt=""/>
               </div>
               <div className="blockcont-txt">
                  <p>{lang.menu_02}</p>
               </div>
               <div className="blockcont-round r-col">
                  <span>30$</span>
                  <p>{lang.menu_06}</p>
               </div>
            </div>

            <div className="zakuslugy-blockcont block-desktop">
               <div className="blockcont-round r-col">
                  <span>150$ </span>
                  <p>{lang.menu_06}</p>
               </div>
               <div className="blockcont-txt">
                  <p>{lang.menu_03}</p>
               </div>
               <div className="blockcont-round r-img">
                  <img className="image" src="logo\l_010.gif" alt=""/>
               </div>
            </div>
            {/* для мобильнйо версии */}
            <div className="zakuslugy-blockcont block-mobile">

               <div className="blockcont-round r-img">
                  <img className="image" src="logo\l_010.gif" alt=""/>
               </div>
               <div className="blockcont-txt">
                  <p>{lang.menu_03}</p>
               </div>
               <div className="blockcont-round r-col">
                  <span>150$ </span>
                  <p>{lang.menu_06}</p>
               </div>
            </div>

            <div className="zakuslugy-blockcont">
               <div className="blockcont-round r-img">
                  <img className="image" src="logo\l_011.gif" alt=""/>
               </div>
               <div className="blockcont-txt">
                  <p>{lang.menu_04}</p>
               </div>
               <div className="blockcont-round r-col">
                  <span>30$</span>
                  <p>{lang.menu_07}</p>
               </div>
            </div>

            <div className="zakuslugy-blockcont block-desktop">
               <div className="blockcont-round r-col">
                  <span>30$</span>
                  <p>{lang.menu_07}</p>
               </div>
               <div className="blockcont-txt">
                  <p>{lang.menu_05}</p>
               </div>
               <div className="blockcont-round r-img">
                  <img className="image" src="logo\l_012.gif" alt=""/>
               </div>
            </div>
            {/* для мобильнйо версии */}
            <div className="zakuslugy-blockcont block-mobile">
               <div className="blockcont-round r-img">
                  <img className="image" src="logo\l_012.gif" alt=""/>
               </div>
               <div className="blockcont-txt">
                  <p>{lang.menu_05}</p>
               </div>
               <div className="blockcont-round r-col">
                  <span>30$</span>
                  <p>{lang.menu_07}</p>
               </div>
            </div>

         </div>
      </section>
   )
}

export default ZakUslugy;