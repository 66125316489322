import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/ZakazPage/zakbanner.scss';
import jsheadLg from '../../assets/language/zakbanner-lang.json';

function ZakBanner ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="zakbanner">
         <div className="zakbanner-center">

            <div className="zakbanner-block">
               {/* блок в вие ромба */}
               <div className="block-revers">

               </div>
               <div className="block-right">
                  {/* блок с текстом */}
                  <div className="block-right-txt">
                     <p className="sec-01">{lang.menu_01}</p>
                  </div>
                  {/* блок с линией */}
                  <div className="block-right-line">
                  </div>
               </div>      
            </div>

            <div className="zakbanner-block">
               {/* блок в вие ромба */}
               <div className="block-revers">

               </div>
               <div className="block-right">
                  {/* блок с текстом */}
                  <div className="block-right-txt">
                     <p className="sec-02">{lang.menu_02}</p>
                  </div>
                  {/* блок с линией */}
                  <div className="block-right-line">
                  </div>
               </div>      
            </div>


            <div className="zakbanner-block">
               {/* блок в вие ромба */}
               <div className="block-revers">

               </div>
               <div className="block-right">
                  {/* блок с текстом */}
                  <div className="block-right-txt">
                     <p className="sec-03">{lang.menu_03}</p>
                  </div>
                  {/* блок с линией */}
                  <div className="block-right-line">
                  </div>
               </div>      
            </div>


         </div>
      </section>
   )
}

export default ZakBanner;