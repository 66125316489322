import React from 'react' ;
import { Link } from "react-router-dom";
import '../scss/kamorka.scss';
import jsheadLg from '../assets/language/kamorka-lang.json';
import jscontent from '../assets/content/mini-articles.json';

function Kamorka ( {langID, setSearchHesh, setClassStInd, setSearchPage} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];
   
   // открытое и закрыто меню
   const [isVisstatya, setIsVisstatya] = React.useState(false);
   // номер рахздела для открытого меню
   const [isVisPor, setIsVisPor] = React.useState(0);

   //главная фукнция при нажатии меню (открыть закрыть + номер статьи)
   function fnCallStatya (count) {
      setIsVisstatya(!isVisstatya);
      setIsVisPor(count);
   }; 
   const contentCount = jscontent[isVisPor]
   const mq1280 = window.matchMedia('(max-device-width: 1280px)')
   const mq900 = window.matchMedia('(max-device-width: 900px)')
   //функии для перехода в блог
   function butTopMenu(count) {
      switch (count) {
         case 0:
            setSearchHesh("#Программирование")
            setClassStInd(2)
            if (mq1280.matches && !mq900.matches) {
               setSearchPage(8)
            } else if (mq900.matches) {
               setSearchPage(6)
            } else {
               setSearchPage(9)
            }
         break;
         case 1:
            setSearchHesh("#Дизайн")
            setClassStInd(3)
            if (mq1280.matches && !mq900.matches) {
               setSearchPage(8)
            } else if (mq900.matches) {
               setSearchPage(6)
            } else {
               setSearchPage(9)
            }
         break;
         case 2:
            setSearchHesh("#Продвижение")
            setClassStInd(4)
            if (mq1280.matches && !mq900.matches) {
               setSearchPage(8)
            } else if (mq900.matches) {
               setSearchPage(6)
            } else {
               setSearchPage(9)
            }
         break;
         case 3:
            setSearchHesh("#JavaScript")
            setClassStInd(5)
            if (mq1280.matches && !mq900.matches) {
               setSearchPage(8)
            } else if (mq900.matches) {
               setSearchPage(6)
            } else {
               setSearchPage(9)
            }
         break;
         case 4:
            setSearchHesh("#HTML/CSS")
            setClassStInd(6)
            if (mq1280.matches && !mq900.matches) {
               setSearchPage(8)
            } else if (mq900.matches) {
               setSearchPage(6)
            } else {
               setSearchPage(9)
            }
         break;
         case 5:
            setSearchHesh("#Технологии")
            setClassStInd(7)
            if (mq1280.matches && !mq900.matches) {
               setSearchPage(8)
            } else if (mq900.matches) {
               setSearchPage(6)
            } else {
               setSearchPage(9)
            }
         break;
      }
   }


   return (
      <section className="kamorka">
         <div className="kamorka-center">

            {/* верхний блог с названием */}
            <div className="kamorka-top">
               {/* линия пунктирная */}
               <div className="kamorka-top-line"></div>
               {/* навание раздела */}
               <div className="kamorka-top-span"> 
                  <Link to="/kamorkaprogrammista" title={lang.menu_03}><p>{lang.menu_01}</p></Link>
                  <span>{lang.menu_02}</span>
               </div>
            </div>

            {/* нижний блок с разделами и кратинкой */}
            <div className="kamorka-down">
               {/* блок с плюсиками */}
               <div className="kamorka-down-plus">
                  <div className="kamorka-down-plus-line li01"></div>
                  <div className="kamorka-down-plus-block" title={lang.menu_09} onClick={() => {fnCallStatya(0)}}>
                  {/* <div className="kamorka-down-plus-block" onClick={() => {setIsVisstatya(!isVisstatya)}}> */}
                     <p>+</p>
                  </div>
                  <div className="kamorka-down-plus-line li02"></div>
                  <div className="kamorka-down-plus-block" title={lang.menu_09} onClick={() => {fnCallStatya(1)}}>
                     <p>+</p>
                  </div>
                  <div className="kamorka-down-plus-line li02"></div>
                  <div className="kamorka-down-plus-block" title={lang.menu_09} onClick={() => {fnCallStatya(2)}}>
                     <p>+</p>
                  </div>
                  <div className="kamorka-down-plus-line li02"></div>
                  <div className="kamorka-down-plus-block" title={lang.menu_09} onClick={() => {fnCallStatya(3)}}>
                     <p>+</p>
                  </div>
                  <div className="kamorka-down-plus-line li02"></div>
                  <div className="kamorka-down-plus-block" title={lang.menu_09} onClick={() => {fnCallStatya(4)}}>
                     <p>+</p>
                  </div>
                  <div className="kamorka-down-plus-line li02"></div>
                  <div className="kamorka-down-plus-block" title={lang.menu_09} onClick={() => {fnCallStatya(5)}}>
                        <p>+</p>
                  </div>
               </div>
               {/* блок с навзаниями статей */}
               <div className="kamorka-down-staty">
                  <p title={lang.menu_09} onClick={() => {fnCallStatya(0)}} id="st-pon">{jscontent[0].menu_02}</p>
                  <p title={lang.menu_09} onClick={() => {fnCallStatya(1)}} id="st-pon">{jscontent[1].menu_02}</p>
                  <p title={lang.menu_09} onClick={() => {fnCallStatya(2)}} id="st-pon">{jscontent[2].menu_02}</p>
                  <p title={lang.menu_09} onClick={() => {fnCallStatya(3)}} id="st-pon">{jscontent[3].menu_02}</p>
                  <p title={lang.menu_09} onClick={() => {fnCallStatya(4)}} id="st-pon">{jscontent[4].menu_02}</p>
                  <p title={lang.menu_09} onClick={() => {fnCallStatya(5)}} id="st-pon">{jscontent[5].menu_02}</p>
               </div>
               {/* блок с картинкой */}
               <div className="kamorka-down-logo">
                  <div className="kamorka-down-logo-img">
                        <img className="image" src="img\g_006.gif" title={lang.menu_01} alt={lang.menu_01}  />
                     </div>
               </div>
            </div>

            {/* всплывающее окно с ифнормацией о рзаделе */}
            {isVisstatya &&  (
               <div className="kamorka-block-st">
                  <div className="block-st-left">
                     <div className="block-st-left-img">
                        <img className="image" src={contentCount.menu_01} alt=""/>
                     </div>
                  </div>
                  <div className="block-st-right">
                     <div className="block-st-right-txt">
                        <span>{contentCount.menu_02}</span>
                        <p>{contentCount.menu_03}</p> 
                     </div>
                     <div className="block-st-right-ssil">
                        <div className="block-st-right-ssil-01">
                           <Link to="/kamorkaprogrammista" title={lang.menu_07} onClick={() => butTopMenu(isVisPor)}>
                              <p>{lang.menu_05}</p>
                           </Link>
                        </div>
                        <div className="block-st-right-ssil-02" title={lang.menu_08} onClick={() => {fnCallStatya(1)}}>
                           <p>{lang.menu_06}</p>
                        </div>
                     </div>
                  </div>
               </div>
            )}

         </div>
      </section>
   )
}

export default Kamorka;