import React from 'react' ;
import '../../scss/BlogPage/blogopis.scss';
import jsheadLg from '../../assets/language/blog-lang.json';

function BlogOpis ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <div className="blogopis">
         <div className="blogopis-center">
            <div className="blogopis-center-left">
               <img className="image" src="../../img/g_014.gif" alt=""/>
            </div>
            <div className="blogopis-center-right">
               <div className="blogopis-center-right-txt">
                  <p>{lang.menu_01}</p>
               </div>
               <div className="blogopis-center-right-but">
                  <p>{lang.menu_02}</p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default BlogOpis;