import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x009 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 5   // первая статья
   const i2 = 4   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>ChatGPT и крутые нейросети 2023</span>
               <img className="image" src="../../content/st-009/001.jpg" alt=""/>
               
               <p className="sp-01">Казалось бы, совсем недавно компания OpenAI представила чат-бот <b>ChatGPT</b>, который называют революционным. ChatGPT может вести осознанный диалог с собеседником: отвечать на вопросы, давать советы и объяснять сложные понятия. Кроме того, чат-бот умеет искать баги в коде, сочинять стихи, писать сонеты, придумывать сценарии и генерировать школьные эссе на разные тематики</p>
               <p className="sp-01">ИИ обучили на огромном массиве данных из интернета. После этого тестировщики компании оценивали ответы ранних версий модели, затем ИИ заново обучался уже на новой информации. При этом, у ChatGPT есть «память». В отличие от многих нейросетевых чат-ботов, ChatGPT запоминает детали разговора и может строить ответы, основываясь на информации, которую ему уже сообщил пользователь. Например, у чата можно спросить, что подарить маме на день рождения. Нейросеть предложит примеры, спросит о ее интересах и ответит на вопросы о каждом предложенном подарке. У бота можно переспрашивать, с чего начался диалог: он выдаст краткое саммари.</p>
               <p className="sp-01">Пользователи соцсетей отмечают, что качество ответов у ChatGPT выше, чем у поиска Google, который становится все менее релевантным. Например, бот сразу пишет, как решить дифференциальное уравнение, в то время как Google перенаправляет на некачественные сайты.</p>
               <p className="sp-03">Американская некоммерческая компания OpenAI занимается технологиями машинного обучения. Один из ее основателей — Илон Маск. Разрабатывают ИИ и ищут способы, как применять его для упрощения жизни человека.</p>
               <p className="sp-01"> ИИ рассказывает, как выполнить определенную операцию или придумать процесс. Он может найти баги в части кода или перевести код из одного языка программирования в другой. Мы добавили две ошибки в код и попросили ChatGPT найти их — бот справился с заданием. В соцсетях отмечают, что ИИ может стать отличным помощником для поиска багов.</p>
               <p className="sp-01">ChatGPT знает большинство популярных фильмов, сериалов, книг, видеоигр. В чате можно генерировать новые эпизоды уже закрытых ситкомов и смешивать персонажей из разных вселенных. Например, в соцсетях просят чат-бота рассказать об алгоритме сортировки пузырьком через диалоги персонажей «Сайнфелда» или объяснить код в духе гангстеров из фильмов.</p>
               <p className="sp-01">Чат-бот умеет переводить текст на десятки языков, с парой «русский — английский» справляется отлично. С запросами на английском чат-бот справляется лучше, поэтому можно составлять запросы на русском, просить их перевести и уже потом использовать их для генерации текста. Это также поможет в переписке с носителями языков. Нейросеть уже используют для составления рабочих писем: в соцсетях разошлась история о сотруднике с ломаным английским, который при помощи ChatGPT «повысил» свой уровень до безупречного.</p>
               <p className="sp-01">Но на данный момент не одним GPT едины, и с каждыйм днем появляется вес больше интересных и новых нейросетей, коорые могут помочь вам в построении вашего бизнеса, программировании, обучении и прочих подобных штуках.</p>

               <img className="image" src="../../content/st-009/002.jpg" alt=""/>
               <p className="sp-05">DALL·E 2</p>
               <p className="sp-01">ейросеть буквально взорвавшая весь интернет. В основном она используется для генерации изображения по текстовому запросу или наброску. Но с помощью DALL-E 2 можно внести невероятно реалистичные изменения в уже существующие фото и арт-иллюстрации, так как нейросеть принимает во внимание и тени, и отражения, и текстуры.</p>
               <p className="sp-01">Что касается процесса использования, то он невероятно прост. Для того, чтобы получить 4 вариации изображений, достаточно ввести текстовый запрос или загрузить изображение на сервис. Спустя секунд 30 можно скачивать результат или редактировать его совместно с нейросетью.</p>
               <p className="sp-01">Доступна быстрая регистрация через Google и Microsoft Account. Требуется верификация по номеру мобильного телефона. Создавая аккаунт вы подтверждаете, что вам исполнилось 18 лет. После регистрации становится доступно 50 бесплатных кредитов, далее по подписке.</p>

               <img className="image" src="../../content/st-009/003.jpg" alt=""/>
               <p className="sp-05">Midjourney.com</p>
               <p className="sp-01">Нейросеть достаточно быстро справляется с вашим текстовым запросом и генерирует по нему сразу 4 варианта изображений. После чего вы сможете создать дополнительные вариации или сделать масштабирование понравившегося варианта и сохранить себе итоговое изображение в отличном качестве. Генерировать изображения можно и по текстовому запросу и по уже имеющемуся изображению, например фото. Функционал небольшой, но справляется сеть с поставленными запросами отлично и нравится многим художникам.</p>
               <p className="sp-01">К сожалению, сам процесс использования сети сложно охарактеризовать положительно. Начиная с процесса регистрации через сервис-дискорт-инвайт в канал и заканчивая самой генерацией изображений. К счастью как на самом сервисе, так и в канале сети на дискорде есть подробная инструкция с иллюстрациями. Из них становится понятно, что все арт объекты генерируются через запрос к боту в одном из публичных каналов “#newbies ”. Разобравшись с этим моментом, отправить запрос боту несложно, но придется немного подождать результат и посмотреть на процесс “общения” других пользователей с нейросетью.</p>
               <p className="sp-01">После регистрации становится доступно 25 бесплатных генераций. Далее вы можете выбрать один из тарифных планов. Базовый тарифный план обойдется в 8$ ежемесячно за которые вы получите 200 генераций, доступ к галереям участников сообщества и возможность докупить генерации. Стандартный план стоит дороже — 24$ в месяц, он не ограничивает количество генераций и также открывает доступ к галереям других участников, однако, лишь 15 часов работы с сервисом поставят ваши запросы в приоритет. По истечению 15 часов ваши изображения будет генерировать согласно очереди участников сообщества.</p>

               <img className="image" src="../../content/st-009/004.jpg" alt=""/>
               <p className="sp-05">Stable Diffusion</p>
               <p className="sp-01">Нейросеть Stable Diffusion уступает DALL-E 2 и Midjourney разве что по качеству итоговых изображений. Их разрешение 512 х 512 пикселей. В остальном она отлично понимает ваш запрос, генерирует 4 не менее интересных картинки и к тому является бесплатной! Для использования сервиса потребуется лишь фантазия на ввод текстового запроса и нажатие лишь одной кнопки для генерации результата. И самое важное - не требует регистрации и полностью бесплатная</p>
               <p className="sp-05">Remove.bg</p>
               <p className="sp-01">Это условно-бесплатный сервис от компании Kaleido. Нейросеть автоматически удаляет фон с фотографий — это быстрее, чем вырезать его вручную, например, в Adobe Photoshop. Сервис работает онлайн, но есть версии для Windows, MacOS, Linux и андроид. Есть даже специальный плагин для Adobe Photoshop и Figma, который сильно упрощает работу. Но это не весь функционал сервиса — в нем можно еще и отредактировать работу. Например, вместо оригинального фона наложить какой-нибудь другой, стереть или восстановить какие-то объекты на оригинальной фотографии.</p>
               <p className="sp-05">Palette</p>
               <p className="sp-01">Бесплатный инструмент, который окрашивает черно-белые фотографии. Palette.fm работает на модели глубокого обучения, которая определяет первоначальные цвета объектов на фотографии или иллюстрации. </p>
               <p className="sp-01">Когда загрузите фото, сможете выбрать цветовой фильтр или уточнить цвета с помощью письменного описания. Сервис бесплатный, поможет сделать цветными сколько угодно картинок. Но есть и платная функция — скачивать обработанные HD-изображения. Одна загрузка стоит 20 центов.</p>

               <img className="image" src="../../content/st-009/005.jpg" alt=""/>
               <p className="sp-05">Designs.ai</p>
               <p className="sp-01">Designs — новый сервис, который выступает конкурентом популярным Canva и Snappa. Он помогает создавать баннеры, видео и макеты с помощью ИИ. Но мы протестируем сервис в качестве автоматического логомейкера.Сервис генерирует сотни новых логотипов за несколько секунд. При этом можно выбрать, на основе чего создавать логотип: иконки, текста или инициалов. Мы попробуем на примере иконки.</p>
               <ul>
                  <li>На первом этапе прописываем имя компании</li>
                  <li>Затем выбираем, на основе чего будем генерировать будущий логотип.</li>
                  <li>На следующем этапе придумываем слоган компании. </li>
               </ul>
               <p className="sp-01">Вот и все. После этого нейросеть предложит выбрать несколько примеров логотипов, из которых можно выбрать понравившиеся макеты.А потом — цветовую палитру. Можно выбрать случайную, если еще не определились с цветом. Осталось выбрать иконку из предложенных. Выбирать можно несколько иконок сразу, так нейросеть предложит разные варианты с разными иконками.После этого мы получим несколько вариантов разных логотипов, из который можно выбрать наиболее понравившийся.</p>
               <p className="sp-01">Конечно, потом логотип можно доработать самостоятельно или отдать на правки дизайнеру, но для стартовой иконки это отличный вариант.</p>
               <p className="sp-01">Сервис платный, но можно скачать демо-версию с водяными знаками. Платные тарифы стартуют от $19 при подписке на год и $29 при подписке на месяц. Отличный вариант бесплатного использования сервиса — генерировать здесь несколько разных логотипов, вдохновляться ими и рисовать что-то свое.</p>

               <img className="image" src="../../content/st-009/006.jpg" alt=""/>
               <p className="sp-05">Pixop</p>
               <p className="sp-01">Сервис позволяет повысить разрешение видео до 8K. Он использует искусственный интеллект, который с помощью автоматических фильтров улучшает качество отснятого материала и снижает пиксельность картинки.</p>
               <p className="sp-01">Сервис использует несколько автоматических фильтров: суперразрешение, шумоподавление, деинтерлейсинг, устранение дрожания и глубокое восстановление. Поэтому итоговое видео получается таким, будто его сняли на профессиональную камеру.Единственный минус - Pixop платный. Здесь нет тарифов, платить нужно за каждую функцию по улучшению видео, а также доплачивать за продолжительность видеофрагмента.</p>
               <p className="sp-05">ChatBCG</p>
               <p className="sp-01">Бот ChatBCG разработали в Stanford AI. Нейросеть работает по модели BCG-3, для которой достаточно задать тему или написать подсказку — система найдет текст и фотографии из открытых источников и красиво их оформит. При этом ограничений по темам нет. В конце от пользователя требуется выбрать только дизайн и сохранить результат. Единственный минус сервиса — он работает только на английском языке.</p>
               <p className="sp-05">Slidebean</p>
               <p className="sp-01">Slidebean — сервис на основе нейросети, которая создает презентации в двух режимах: outline и design. В первом нужно прописывать текст для будущих слайдов, во втором — выбирать дизайн. Алгоритм за несколько секунд создаст презентацию и покажет вам. Если какой-то из слайдов не понравится — нажмите на кнопку «next option» под ним и искусственный интеллект автоматически пересоберет содержимое.</p>

               <img className="image" src="../../content/st-009/007.jpg" alt=""/>
               <p className="sp-05">Notion.AI</p>
               <p className="sp-01">Notion — платформа для ведения заметок, создания систем управления знаниями, информацией и проектами. Мы рассказывали подробно, как пользоваться этим сервисом и почему он классный.</p>
               <p className="sp-01">В недавнем обновлении сервиса Notion был добавлен инструмент, который использует нейросеть для генерации текстов. Это расширяет возможности платформы и позволяет использовать его для создания нового контента. Теперь прямо в Notion пользователи могут упрощать и расширять тексты, извлекать ключевые выводы из заметок, улучшать стиль письма и многое другое.</p>
               <p className="sp-01">Что умеет Notion.AI:</p>
               <ul>
                  <li>создавать тексты в заданном стиле и tone of voice</li>
                  <li>помогать с контентом для сайтов и соцсетей — создаёт готовые тексты и подкидывает идеи для публикаций</li>
                  <li>переводить тексты на разные языки</li>
                  <li>анализировать тексты — например, проверять семантику</li>
                  <li>генерировать автоматические ответы на письма</li>
                  <li>создавать программный код, учитывая указанные параметры или требования</li>
                  <li>корректировать объём текста под заданные параметры</li>
               </ul>
               <p className="sp-01">Интерфейс Notion полностью на английском языке, но нейросеть неплохо понимает русский язык. Чтобы начать работать с платформой, зарегистрируйтесь любым удобным способом и создайте новый документ. В Notion много шаблонов — выберите подходящий и укажите тему текста. Например: «Как спастись от жары». Далее командой Ctrl+J вызовите помощника и в выделенной строке напишите команду для нейросети.</p>
               <p className="sp-01">AI пишет статью на основе данных, взятых из интернета. В результате получается статья с советами: с ней придётся немного поработать, отредактировать, внести коррективы — и можно публиковать.</p>

               <img className="image" src="../../content/st-009/008.jpg" alt=""/>
               <p className="sp-05">YandexGPT</p>
               <p className="sp-01">Нейросеть от Яндекса работает прямо на главной странице поисковика. Сервис запущен недавно и сейчас находится на стадии активного развития. Например, в июле 2023 года было запущено закрытое тестирование нейросети в Yandex Cloud. По результатам создатели YandexGPT хотят понять, какие задачи можно решать наиболее эффективно и как приоритизировать сценарии развития сервиса.</p>
               <p className="sp-01">Что умеет YandexGPT: генерировать тексты на заданные темы на разных языках; создавать описания товаров, статьи для блогов, посты для соцсетей и другие виды текстового контента; искать информацию в интернете; переводить тексты и т.д.</p>
               <p className="sp-03">Чтобы запустить YandexGPT, достаточно зайти на главную страницу Яндекса, кликнуть на иконку Алисы в правом нижнем углу страницы и выбрать режим «Давай придумаем». Сервис работает как на десктопе, так и в мобильной версии (поиске или приложении).</p>
               <p className="sp-01">Примечательно, что нейросеть не отвечает на провокационные вопросы и на запросы, которые по мнению системы могут быть опасными для пользователя.</p>

               <img className="image" src="../../content/st-009/009.jpg" alt=""/>
               <p className="sp-05">CopyMonkey</p>
               <p className="sp-01">Нейросеть может генерировать тексты разных типов — посты для соцсетей, статьи для блогов, описания товаров, тексты для рассылок и другие материалы. Чтобы получить максимально точные результаты, можно указать ключевые слова.</p>
               <p className="sp-01">Что умеет CopyMonkey:</p>
               <ul>
                  <li>отвечать на вопросы из любой сферы — создатели заявляют, что нейросеть решает любые задачи</li>
                  <li>создавать полноценные тексты и SEO-статьи — но результат придётся редактировать</li>
                  <li>генерировать креативные тексты для блогов и соцсетей</li>
                  <li>составлять описания к видео и комментарии на YouTube</li>
                  <li>создавать описания товаров и товарных категорий</li>
                  <li>генерировать отзывы</li>
                  <li>писать тексты для лендингов и даже для рассылок</li>
               </ul>
               <p className="sp-01">Конечно, последний пункт — это не полноценное закрытие задачи. Полученный от нейросети результат будет нуждаться в серьёзной доработке.</p>


              

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x009