import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x012 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 9   // первая статья
   const i2 = 10   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Размеры экрана при верстке</span>
               <img className="image" src="../../content/st-012/001.jpg" alt=""/>
               
               <p className="sp-01">Начинающим верстальщикам, а также дизайнерам, кто делает сайт на фрилансе или просто для себя, часто необходимо понимать, какой размер экрана увидит пользователь, какую ширину брать для адаптивного дизайна, делать ли резиновую верстку, и даже какова высота первого «зрительного» экрана на мониторе или экране смартфона. </p>
               <p className="sp-01"><b>Адаптивная вёрстка или дизайн</b> — создание страниц сайта, автоматически подстраивающихся под размер, разрешение и ориентацию экрана устройства. Для разных девайсов готовят разные дизайны, при входе на сайт автоматически загружается оптимизированный под устройство макет.</p>
               <p className="sp-01">И конечно, любой программист вам скажет, что в эру новых технологий, развития смартфонов, планшетов и т.д., каждому уже нужно делать резиновую верстку. Вообще, если усреднить, на сайте может быть три варианта вёрстки:</p>
               <p className="sp-02">1. Без адаптивного дизайна. </p>
               <p className="sp-01">Обычно делается простой сайт или лэндинг, одностраничный, в котором задаются жёсткие рамки сайта, а в поле header прописывается строчка:</p>
               <p className="sp-05">meta name="viewport" сontent="width=device-width, initial-scale=1, shrink-to-fit=no”</p>
               <p className="sp-01">Ширину главного блока при этом в CSS мы прописывает min-width: и нужный размер. Чаoе всего такие сайты делают не шире 1280-1080px. На экране компьютера он будет просто страницей по середине с отступами по краям, а на экранах планшетов/смартфонов весь контент будет сжиматься и становиться «мельче». Проблема таких сайтов именно в том, что вам нужно пальцами приближать, чтобы что-то нормальное рассмотреть. Но в качестве быстрого решения, дешевого, я бы сказал, многие заказчики выбирают такой вариант. </p>

               <img className="image" src="../../content/st-012/002.jpg" alt=""/>
               <p className="sp-02">2. Адаптация по чек-поинтам.</p>
               <p className="sp-01">Это вариант, когда вы верстаете свой сайт под определенные границы. Скажем для экранов компьютера это 1280px, для планшета 780px, для смартфона 480px. Можно больше вариаций, не суть. И весь свой дизайн подстраиваете под эти значения. Все что в промежутках будет «сжиматься», как и при случае выше. Но это уже более удобно, можно регулировать размер шрифтов, при этом верстается гораздо быстрее. </p>
               <p className="sp-02">3. Полностью резиновая верстка. </p>
               <p className="sp-01">Уже применяется в том случае, когда вы не зависимо от размера экрана или даже размера браузера (когда вы можете его сужать и расширять) верстка будет меняться и подстраивается. Делается это как за счет размера блоков, заданных в процентах, так и за счет фиксированных блоков, но вы сами их «туда-сюда» расставляете. Это не суть, мы сегодня не об этом. </p>
               <p className="sp-01">На практике могу вам сказать, что обычно выбирают либо первый вариант (для быстрых решений и не адаптивного сайта) либо, если делать с адаптацией, то третий вариант. Всегда надо делать так, чтобы при изменении ширины экрана, ваша верстка подстраивалась под это. Делается это довольно просто, и в CSS достаточно прописать:</p>
               <p className="sp-05">@media only screen and (min-device-width: 500px) and (max-device-width: 1000px)</p>
               <p className="sp-01">Первый показатель отвечает за размер страницы браузера, второй за экран устройства. Можно вообще вес опустить и написать только:</p>
               <p className="sp-05">@media (max-width: 1000px)</p>

               <img className="image" src="../../content/st-012/003.jpg" alt=""/>
               <p className="sp-01">Использовать минимальные значения или максимальные, исходя из нижней границы или верхней, не играет никакой роли. Важно именно то, чтобы при верстке и создании сайта, вы попробовали порастягивать бразуер (и в том числе под экраны разных мониторов) и поигрались с расположением блоком, размеров шрифта и прочего. В медиа выражениях можно хоть отдельный сайт написать. А JS поможет скрыть отдельные блоки или свернуть их в меню. Сегодня опять же не об этом. </p>
               <p className="sp-01">Использовать минимальные значения или максимальные, исходя из нижней границы или верхней, не играет никакой роли. Важно именно то, чтобы при верстке и создании сайта, вы попробовали порастягивать бразуер (и в том числе под экраны разных мониторов) и поигрались с расположением блоком, размеров шрифта и прочего. В медиа выражениях можно хоть отдельный сайт написать. А JS поможет скрыть отдельные блоки или свернуть их в меню. Сегодня опять же не об этом. </p>
               <p className="sp-01">И именно поэтому довольно полезно знать размеры экранов и будущих блоков, чтобы какие-то выплывающие меню, подсказки, ширину основных боков сразу подстраивать под минимальный размер. Например, экран смартфона у на 320-480px, значит и ширину блоков, менюшек, еще чего, можно сразу рисовать фиксированными в 300px, и тогда их можно не переделывать внутри, а просто убрать лишнее вокруг, перевести на новую строчку, расположить поверх других блоков и т.д. Это очень удобно создавать такие вариации, меньше мороки. </p>
               <p className="sp-03">Кстати, довольно популярно сейчас, в эпоху смартфонов, так называемый стиль программирования и дизайна MobileFirst.</p>
               <p className="sp-01">Я лично не люблю такой подход, хотя если я верстаю сайт в первую очередь для мобильных устройств (заказ еды и т.д.), то такой вариант возможен. Но все-таки десктопная версия у меня в приоритете. Но, она делается сразу из расчета на мобильные устройства, и как я сказал выше, используются блоки, кнопки и прочее, которые сразу будут хорошо смотреться на мобиле, а все остальное уже подстраивается. </p>
               <p className="sp-04">Стратегия Mobile First, как следует из названия, предполагает проектирование интерфейса сайта, в первую очередь ориентированного на мобильные устройства. Философия подхода заключается в том, что сначала создается прототип дизайна для самого маленького экрана, а затем разработчики переходят к дисплеям боольших размеров. В этом состоит главное отличие от классического подхода Desktop First, который используют веб-специалисты уже много лет. </p>

               <img className="image" src="../../content/st-012/004.jpg" alt=""/>
               <p className="sp-01">И теперь переходим к главному – а какие же тогда размеры экрана принимать при создании шаблонов или вёрстке, какие должны быть чекпоинты. А тут все довольно просто:</p>
               <p className="sp-01"><b>От 1280px(1180px) до 1920(1824px)</b> – это экраны мониторов. </p>
               <p className="sp-01">На самом деле, обычно я использую 1280px, а 1920 – это уже для больших мониторов и экранов телевизоров. Их я использую крайне редко, и самый стандартная вёрстка в районе 1280px. Но если вдруг очень надо сделать пошире – то пожалуйста, особенно если вы делаете сайт «без» отступов по краям, а во всю ширину. </p>
               <p className="sp-01">Значение в скобочках – это конкретная часть. Обычно в любом шаблоне вы можете нарисовать сам экран, как бы, монитора, и выделить по середине контентную часть, отступив по краям по 20-50px, на разные скроллы, какие-то заметки, у некоторых мониторы настроены криво. В общем это хорошая практика, чтобы контент не выходил за эти «линии».</p>
               <p className="sp-01"><b>От 1024 (992) px до 1280 (1180) px </b> – это тоже небольшие мониторы, а также большие планшеты, нет-буки и т.д., а также планшеты в горизонтальном положении</p>
               <p className="sp-01"><b>780 (768) px – 1024 (992) px </b> – это экраны планшетов</p>
               <p className="sp-01"><b>480px – 780 (768) px </b> – экраны смартфонов</p>
               <p className="sp-01"><b>320px – 480px</b> – экраны смартфонов, старенькие обычно. Их иногда не рисуют, а делают все под 480px и так фиксируют минимальную ширину. На экране более старых смартфонов контент просто уменьшится подстроится под размер. Если вы не страдаете минимализмом (а многие новички, наоборот, делают все гигантскими), то это вполне нормальный вариант. </p>
               <p className="sp-01">Итого, на практике, обычно можно нарисовать три-четыре вида дизайна, скажем 1280px, 780px и 480px. Для самого простого варианта это отличное решение. </p>
               <p className="sp-03">Для более продвинутых можно добавить еще и 1024 и 320px. И в особых случаях 1920px, если вам нужен широкий экран </p>
               <p className="sp-01">Что касается высоты экрана, то тут тоже не все однозначно. Если вам очень нужно разработать дизайн, чтобы он был только в один блок экрана, или например, первый блок был красиво оформлен, без разрывов, то чаще всего применяют 600*800px в высоту для экранов мониторов и 400-500px для смартфонов. Но там лучше использовать показатель vh, если вы понимаете, как он работает. </p>
             
         

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x012