import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/ZakazPage/zakkorpstyle.scss';
import jsheadLg from '../../assets/language/zakkorpstyle-lang.json';

function ZakKorpstyle ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="zakkorpstyle">
         <div className="zakkorpstyle-center">

            {/* название раздела */}
            <div className="zakkorpstyle-span">
               <div className="zakkorpstyle-span-num">
                  <p>2</p>
               </div>
               <div className="zakkorpstyle-span-txt">
                  <p>{lang.menu_01}</p>
               </div>
            </div>

            {/* общий блок с картинками */}
            <div className="zakkorpstyle-obblock">
               <div className="zakkorpstyle-block blopart-01">
                  <div className="zakkorpstyle-block-img">
                     <img className="image" src="img\m_01.jpg" alt=""/>
                  </div>
                  <div className="zakkorpstyle-block-txt">
                     <p>{lang.menu_02}</p>
                     <ul>
                        <li>{lang.menu_03}</li>
                        <li>{lang.menu_04}</li>
                        <li>{lang.menu_05}</li>
                     </ul>
                  </div>
                  <div className="zakkorpstyle-block-price">
                     <p>free</p>
                  </div>
               </div>

               <div className="zakkorpstyle-block blopart-02">
                  <div className="zakkorpstyle-block-img">
                     <img className="image" src="img\m_02.jpg" alt=""/>
                  </div>
                  <div className="zakkorpstyle-block-txt">
                     <p>{lang.menu_06}</p>
                     <ul>
                        <li>{lang.menu_07}</li>
                     </ul>
                  </div>
                  <div className="zakkorpstyle-block-price">
                     <p>free</p>
                  </div>
               </div>


               <div className="zakkorpstyle-block blopart-03">
                  <div className="zakkorpstyle-block-img">
                     <img className="image" src="img\m_03.jpg" alt=""/>
                  </div>
                  <div className="zakkorpstyle-block-txt">
                     <p>{lang.menu_08}</p>
                     <ul>
                        <li>{lang.menu_09}</li>
                        <li>{lang.menu_10}</li>
                        <li>{lang.menu_11}</li>
                     </ul>
                  </div>
                  <div className="zakkorpstyle-block-price">
                     <p>free</p>
                  </div>
               </div>

            </div>



         </div>
      </section>
   )
}

export default ZakKorpstyle;