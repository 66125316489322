import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/PrimerPage/primertop.scss';
import jsheadLg from '../../assets/language/primerpage-lang.json';
import { useRef, useState } from 'react';

// модуль для свайпера (вертушки)
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { Autoplay, Navigation } from 'swiper/modules';



function PrimerTop ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];
   // всплывающий сладйер большой кратинки сайт 1
   const [isVissait, setIsVissait] = React.useState(false);
   // всплывающий сладйер маленький кратинки сайт 2
   const [isVissait_m1, setIsVissait_m1] = React.useState(false);
  // всплывающий сладйер маленький кратинки сайт 3
  const [isVissait_m2, setIsVissait_m2] = React.useState(false);
  // всплывающий сладйер маленький кратинки сайт 4
  const [isVissait_m3, setIsVissait_m3] = React.useState(false);

   function fnCallSayt (count) {
      setIsVissait(!isVissait);
   };
   function fnCallSayt_m1 (count) {
      setIsVissait_m1(!isVissait_m1);
   };
   function fnCallSayt_m2 (count) {
      setIsVissait_m2(!isVissait_m2);
   };
   function fnCallSayt_m3 (count) {
      setIsVissait_m3(!isVissait_m3);
   };

   return (
      <section className="primertop">
         <div className="primertop-center">

            <div className="primertop-big">
               <img className="image im-1180" onClick={() => {fnCallSayt(0)}} src="prim\pr_01-1180.jpg" alt=""/>
               <img className="image im-780" onClick={() => {fnCallSayt(0)}} src="prim\pr_01-780.jpg" alt=""/>
               <img className="image im-480" onClick={() => {fnCallSayt(0)}} src="prim\pr_01-480.jpg" alt=""/>
            </div>

            <div className="primertop-min">
               <Swiper 
                  slidesPerView={'auto'}
                  spaceBetween={30}
                  autoplay={{
                     delay: 5000,
                     disableOnInteraction: false,
                  }}
                  rewind={true}
                  navigation={true} 
                  modules={[Autoplay, Navigation]}     
                  className="mySwiper"
               >
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_02-1180.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_02-780.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_02-480.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_03-1180.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_03-780.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_03-480.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_04-1180.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_04-780.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_04-480.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_02-1180.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_02-780.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_02-480.jpg" onClick={() => {fnCallSayt_m1(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_03-1180.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_03-780.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_03-480.jpg" onClick={() => {fnCallSayt_m2(0)}} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                     <img className="image im-1180" src="prim\pr_04-1180.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-780" src="prim\pr_04-780.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                     <img className="image im-480" src="prim\pr_04-480.jpg" onClick={() => {fnCallSayt_m3(0)}} alt=""/>
                  </SwiperSlide>                  
               </Swiper>
            </div>

         {/* всплывающий слайдер большой кратинки сайт 1*/}
            {isVissait &&  (
               <div className="primertop-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-005-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-006-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-007-r.jpg" alt=""/></SwiperSlide>            
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide langID={langID}><img className="image" src="prim\s-001\s_01-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-005-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-006-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-001\s_01-007-e.jpg" alt=""/></SwiperSlide>            
               </Swiper>)}

               <div className="primertop-saitfor-img" onClick={() => {fnCallSayt(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}


         {/* всплывающий слайдер большой кратинки сайт 2*/}
         {isVissait_m1 &&  (
               <div className="primertop-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-005-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-006-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-007-r.jpg" alt=""/></SwiperSlide>            
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide langID={langID}><img className="image" src="prim\s-002\s_02-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-005-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-006-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-002\s_02-007-e.jpg" alt=""/></SwiperSlide>            
               </Swiper>)}

               <div className="primertop-saitfor-img" onClick={() => {fnCallSayt_m1(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}

         {/* всплывающий слайдер большой кратинки сайт 3*/}
         {isVissait_m2 &&  (
               <div className="primertop-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-005-r.jpg" alt=""/></SwiperSlide>             
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-003\s_03-005-e.jpg" alt=""/></SwiperSlide>             
               </Swiper>)}

               <div className="primertop-saitfor-img" onClick={() => {fnCallSayt_m2(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}

         {/* всплывающий слайдер большой кратинки сайт 3*/}
         {isVissait_m3 &&  (
               <div className="primertop-saitfor" >
               {langID===false ? (
               <Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-002-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-003-r.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-004-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-005-r.jpg" alt=""/></SwiperSlide>    
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-006-r.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-007-r.jpg" alt=""/></SwiperSlide>            
               </Swiper>) : 
               (<Swiper rewind={true} navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-001.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-002-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-003-e.jpg" alt=""/></SwiperSlide>
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-004-e.jpg" alt=""/></SwiperSlide>   
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-005-e.jpg" alt=""/></SwiperSlide>  
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-006-e.jpg" alt=""/></SwiperSlide>  
                  <SwiperSlide><img className="image" src="prim\s-004\s_04-007-e.jpg" alt=""/></SwiperSlide>             
               </Swiper>)}

               <div className="primertop-saitfor-img" onClick={() => {fnCallSayt_m3(0)}}>
                  <img className="image" src="icon\icon-03.svg" alt=""/>
               </div>
            </div>)}

         </div>

         {/* блок с цветная полоса */}
         <div className="primertop-col"></div>
      </section>
   )
}

export default PrimerTop;