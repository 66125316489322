import React from 'react' ;
import { Link } from "react-router-dom";
import '../../scss/MainPage/dopuslug.scss';
import jsheadLg from '../../assets/language/dopuslug-lang.json';

function DopUslug ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <section className="dopuslug">
         <div className="dopuslug-center">

            <div className="dopuslug-block blopart-01">
               <div className="dopuslug-block-img">
                  <img className="image" src="../../img/m_01.jpg" alt=""/>
               </div>
               <div className="dopuslug-block-txt">
                  <p>{lang.menu_01}</p>
                  <ul>
                     <li>{lang.menu_02}</li>
                     <li>{lang.menu_03}</li>
                     <li>{lang.menu_04}</li>
                  </ul>
               </div>
            </div>

            <div className="dopuslug-block blopart-02">
               <div className="dopuslug-block-img">
                  <img className="image" src="../../img/m_02.jpg" alt=""/>
               </div>
               <div className="dopuslug-block-txt">
                  <p>{lang.menu_05}</p>
                  <ul>
                     <li>{lang.menu_06}</li>
                     <li>{lang.menu_07}</li>
                     <li>{lang.menu_08}</li>
                  </ul>
               </div>
            </div>


            <div className="dopuslug-block blopart-03">
               <div className="dopuslug-block-img">
                  <img className="image" src="../../img/m_03.jpg" alt=""/>
               </div>
               <div className="dopuslug-block-txt">
                  <p>{lang.menu_09}</p>
                  <ul>
                     <li>{lang.menu_10}</li>
                     <li>{lang.menu_11}</li>
                     <li>{lang.menu_12}</li>
                  </ul>
               </div>
            </div>

         </div>
      </section>
   )
}

export default DopUslug;