import React from "react";
import '../../scss/BlogPage/blogtopmenu.scss';

const BlogSearch = ({searchId, setSearchId, setSearchPage}) => {

   function butShechText (event) {
      setSearchPage(9)
      setSearchId(event.target.value)
   }

   return (
      <div>
         <img className="bclogsearch-icon" src="icon\icon-04.svg" alt=""/>
         <input 
            value={searchId}
            onChange={(event) => butShechText(event)}
            className="blogsearch-inp" 
            placeholder="поиск..." 
         />
      </div>
      
   )
}

export default BlogSearch
