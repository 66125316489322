import React from 'react' ;
import jsheadLg from '../assets/language/headDown-lang.json';
import HeadDown from '../Components/HeadDown';
import PrimerTop from '../Components/PrimerPage/PrimerTop';
import RaytMe from '../Components/MainPage/RaytMe';
import FormZaya from '../Components/MainPage/FormZaya';
import PrimerMid from '../Components/PrimerPage/PrimerMid';
import DopUslug from '../Components/MainPage/DopUslug';
import PrimerDow from '../Components/PrimerPage/PrimerDow';
import Kamorka from '../Components/Kamorka';


function PrimerRa( {langID, tipPageHeader} ) {
   window.scroll(0, 600)
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 

   return (
      <section>
         <HeadDown langID={langID} tipPageHeader='primernHeader'/>
         <PrimerTop langID={langID}/>  
         <RaytMe langID={langID}/>
         <FormZaya langID={langID}/>
         <PrimerMid langID={langID}/> 
         <DopUslug langID={langID}/>
         <PrimerDow langID={langID}/> 
         <Kamorka langID={langID} />
      </section>
   )
}

export default PrimerRa;