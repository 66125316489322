import React from 'react' ;
import { Link } from "react-router-dom";
import '../scss/primer.scss';
import jsheadLg from '../assets/language/primer-lang.json';

function Primer ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 
   let changeStylePole01, changeStylePole02, changeStylePole03
   let [changeStilePrime, setChangeStilePrime] = React.useState(0);

   function changePrimerPlus (item) {
      if (item === 2) {
         setChangeStilePrime(0) 
      } else {
         setChangeStilePrime((item + 1))
      }
   };
   function changePrimerMinus (item) {
      if (item === 0) {
         setChangeStilePrime(2) 
      } else {
         setChangeStilePrime((item - 1))
      }
   };
   switch (changeStilePrime) {
      case 0: 
         changeStylePole01 = "primer-down-prim-01 pr-01"
         changeStylePole02 = "primer-down-prim-01 pr-02"
         changeStylePole03 = "primer-down-prim-01 pr-03"
         break
      case 1: 
         changeStylePole01 = "primer-down-prim-02 pr-01"
         changeStylePole02 = "primer-down-prim-02 pr-02"
         changeStylePole03 = "primer-down-prim-02 pr-03"
         break
      case 2: 
         changeStylePole01 = "primer-down-prim-03 pr-01"
         changeStylePole02 = "primer-down-prim-03 pr-02"
         changeStylePole03 = "primer-down-prim-03 pr-03"
         break
   }



   return (
      <section className="primer">
         <div className="primer-center">
            {/* верхний блок с названием и котом */}
            <div className="primer-top">
               <div className="primer-top-span"> 
               <Link to="/primerrabot" title={lang.menu_01} rel="noreferrer"><p>{lang.menu_01}</p></Link>
               </div>
               <div className="primer-top-img">
                  <img className="image" src="img\g_004.gif" alt=""/>
               </div>
            </div>
            
            {/* нижний блок с вертушкой */}
            <div className="primer-down">
               <div className="primer-down-str left-str" onClick={() => {changePrimerMinus(changeStilePrime)}}>
                  <img className="image" src="icon/icon-02.svg" alt=""/>
               </div>
               <Link to="/primerrabot" title={lang.menu_01} rel="noreferrer">
               <div className="primer-down-prim">
                  <div className={changeStylePole01}>
                     <div className="primer-down-prim-col col-blue   "></div>
                     <div className="primer-down-prim-img img-blue">
                        <img className="image" src="fon\Pr_01.jpg" alt=""/>
                     </div>
                  </div>
                  <div className={changeStylePole02}>
                     <div className="primer-down-prim-col col-yell"></div>
                     <div className="primer-down-prim-img img-blue">
                        <img className="image" src="fon\Pr_02.jpg" alt=""/>
                     </div>
                  </div>
                  <div className={changeStylePole03}>
                     <div className="primer-down-prim-col col-green"></div>
                     <div className="primer-down-prim-img img-blue">
                        <img className="image" src="fon\Pr_03.jpg" alt=""/>
                     </div>
                  </div>
               </div>
               </Link>
               <div className="primer-down-str" onClick={() => {changePrimerPlus(changeStilePrime)}}>
                  <img className="image" src="icon/icon-01.svg" alt=""/>
               </div>
            </div>

         </div>
      </section>
   )
}

export default Primer;