import React from 'react' ;
import { Link } from "react-router-dom";
import '../scss/header.scss';
import jsheadLg from '../assets/language/header-lang.json';
import FormZaya from '../../src/Components/MainPage/FormZaya'

function Header ( {langID, onClickIdLang} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0]; 
   const [isVismenu, setIsVismenu] = React.useState(false);

   //форма отправки заяки
   const [isViszayv, setIsViszayv] = React.useState(false);

   return (

      <header>
         
         <div className="top-header-menu" id="main">
            {/* логотип и назвнаие */}
            <div className="top-header-menu-logo-img">
               <Link to="/" title={lang.menu_07}><img className="image" src="../../logo/l_013.gif" alt=""/></Link>
               
            </div>
            <div className="top-header-menu-logo">
               <p><em>e</em>b-leodesign</p>
            </div>
            {/* блок с меню */}
            <div className="top-header-menu-main">

               {/* - - - - блок верхнего меню - - - - */}
               <div className="top-header-menu-main-top">
                  {/* Переключатель языка */}
                  <div className="menu-main-top-lang">
                     <p className="menu-main-top-lang-txt">RU</p>
                     <label className="switch">
                        <input type="checkbox" onClick={() => onClickIdLang(!langID)}/>
                        <span className="slider round"></span>
                     </label>
                     <p>EN</p>
                  </div>
                  {/* Значки для звонков соц.сетей */}
                  <div className="menu-main-top-soc">
                     <a href="https://t.me/MLeontiev25" title="Telegram" target="_blank" rel="noreferrer">
                        <div className="menu-main-top-soc-img">
                           <img className="image" src="../../icon/i_007.gif" alt=""/>
                        </div>
                     </a>
                     <a href="https://wa.me/+79167020406" title="WatsApp" target="_blank" rel="noreferrer">
                        <div className="menu-main-top-soc-img">
                           <img className="image" src="../../icon/i_008.gif" alt=""/>
                        </div>
                     </a>
                     <a href="tel:+79167020406" title="Позвонить" target="_blank" rel="noreferrer">
                        <div className="menu-main-top-soc-img">
                           <img className="image" src="../../icon/i_017.gif" alt=""/>
                        </div>
                     </a>
                  </div>
                  {/* Номер телефона */}
                  <a href="tel:+79167020406" title={lang.menu_11} target="_blank" rel="noreferrer">
                  <div className="menu-main-top-tel">
                     <p>+7(916)702-04-06</p>
                  </div>
                  </a>
                  {/* Кнопка отправки заявки */}
                  <div className="menu-main-top-but" onClick={() => setIsViszayv(!isViszayv)}>
                     <a href="#" className="main-top-but">{lang.menu_06}</a>
                  </div>
                  {/* мобильное меню 480px*/}
                  <div className="menu-main-mobilecss">
                        <div className="menu-main-top-soc-img" onClick={() => setIsVismenu(!isVismenu)}>
                           <img className="image" src="../../icon/i_015.gif" alt=""/>
                        </div>
                  </div>
               </div>

               {/* - - - - - блок нижнего меню - - - - - */}
               <div className="top-header-menu-main-low">


                  <div className="low-header-menu-txt">
                     <div className="menu-txt-butt b01">
                        <Link to="/" title={lang.menu_01}><p >{lang.menu_01}</p></Link>
                     </div>
                  </div>
                  <div className="low-header-menu-txt">
                     <div className="menu-txt-butt b02">
                     <Link to="primerrabot" title={lang.menu_02}><p>{lang.menu_02}</p></Link>
                     </div>
                  </div>
                  <div className="low-header-menu-txt">
                     <div className="menu-txt-butt b03">
                     <Link to="sdelatzakaz" title={lang.menu_03}><p>{lang.menu_03}</p></Link>
                     </div>
                  </div>
                  <div className="low-header-menu-txt">
                     <div className="menu-txt-butt b04">
                        <Link to="kamorkaprogrammista" title={lang.menu_04}><p >{lang.menu_04}</p></Link>
                     </div>
                  </div>
                  <div className="low-header-menu-txt">
                     <div className="menu-txt-butt b05">
                        <a href="#end" title={lang.menu_05}><p >{lang.menu_05}</p></a>
                     </div>
                  </div>


                  {/* номер телефона мобильая версия */}
                  <a href="tel:+79167020406" title="Позвонить" target="_blank" rel="noreferrer">
                  <div className="menu-main-top-tel-mobile">
                     <p>+7(916)702-04-06</p>
                  </div>
                  </a>
                  {/* Переключатель языка - мобильная версия*/}
                  <div className="menu-main-top-lang-mobile">
                     <p className="menu-main-top-lang-txt">RU</p>
                     <label className="switch">
                        <input type="checkbox" onClick={() => onClickIdLang(!langID)} />
                        <span className="slider round"></span>
                     </label>
                     <p>EN</p>
                  </div>
                  {/* Значки для звонков соц.сетей - мобильная версия */}
                  <div className="menu-main-top-soc-mob">
                     <a href="https://t.me/MLeontiev25" title="Telegram" target="_blank" rel="noreferrer">
                        <div className="menu-main-top-soc-img">
                           <img className="image" src="../../icon/i_007.gif" alt=""/>
                        </div>
                     </a>
                     <a href="https://wa.me/+79167020406" title="WatsApp" target="_blank" rel="noreferrer">
                        <div className="menu-main-top-soc-img">
                           <img className="image" src="../../icon/i_008.gif" alt=""/>
                        </div>
                     </a>
                     <a href="tel:+79167020406" title="Позвонить" target="_blank" rel="noreferrer">
                        <div className="menu-main-top-soc-img">
                           <img className="image" src="../../icon/i_017.gif" alt=""/>
                        </div>
                     </a>
                     {/* + мобильное меню */}
                        <div className="menu-main-top-soc-img mobile-vers" onClick={() => setIsVismenu(!isVismenu)}>
                           <img className="image" src="../../icon/i_015.gif" alt=""/>
                        </div>
                  </div>

               </div>
            </div>
         </div>
            {/* блок мобильного меню */}
            {isVismenu && 
               (<div className="menu-mobile-fon">
                  <div className="menu-mobile-fon-punkt">
                     <Link to="/" title={lang.menu_01}><div className="menu-mobile-fon-punkt-txt" onClick={() => setIsVismenu(!isVismenu)}>
                        <p >{lang.menu_01}</p>
                     </div></Link>
                     <div className="menu-mobile-fon-punkt-round round-red"></div>
                  </div>
                  <div className="menu-mobile-fon-punkt" onClick={() => setIsVismenu(!isVismenu)}>
                     <Link to="primerrabot" title={lang.menu_02}><div className="menu-mobile-fon-punkt-txt">
                        <p>{lang.menu_02}</p>
                     </div></Link>
                     <div className="menu-mobile-fon-punkt-round round-green"></div>
                  </div>
                  <div className="menu-mobile-fon-punkt">
                     <Link to="sdelatzakaz" title={lang.menu_03}><div className="menu-mobile-fon-punkt-txt" onClick={() => setIsVismenu(!isVismenu)}>
                        <p>{lang.menu_03}</p>
                     </div></Link>
                     <div className="menu-mobile-fon-punkt-round round-blue"></div>
                  </div>
                  <div className="menu-mobile-fon-punkt">
                     <Link to="kamorkaprogrammista" title={lang.menu_04}><div className="menu-mobile-fon-punkt-txt"  onClick={() => setIsVismenu(!isVismenu)}>
                        <p >{lang.menu_04}</p>
                     </div></Link>
                     <div className="menu-mobile-fon-punkt-round round-yell"></div>
                  </div>
                  <div className="menu-mobile-fon-punkt">
                     <a href="#end" title={lang.menu_05}><div className="menu-mobile-fon-punkt-txt"  onClick={() => setIsVismenu(!isVismenu)}>
                        <p >{lang.menu_05}</p>
                     </div></a>
                     <div className="menu-mobile-fon-punkt-round round-grey"></div>
                  </div>
                  {/* Кнопка отправки заявки */}
                  <div className="menu-main-top-but menu-but" onClick={() => setIsViszayv(!isViszayv)}>
                     <a href="#" className="main-top-but" onClick={() => setIsVismenu(!isVismenu)}>{lang.menu_06}</a>
                  </div>
                  <div className="menu-main-close" onClick={() => setIsVismenu(!isVismenu)}>
                     <p>закрыть</p>
                  </div>
               </div>
            )}

      {/*  - - - - - отправка заяки - - - - - */}
      {isViszayv && (
         <div className="forma-zayv">
            <div className="form-zayv-top">
               <div className="form-zayv-top-left">
                  <span>{lang.menu_08}</span>
                  <p>{lang.menu_09}</p>
               </div>
               <div className="form-zayv-top-right" onClick={() => setIsViszayv(!isViszayv)}>
                  <p>{lang.menu_10}</p>
                  <div className="form-zayv-top-right-line"></div>
               </div>
            </div>
            <div className="form-zayv-cneter">
               <FormZaya langID={langID}/>
            </div>
            
         </div>
      )}

      </header>
   )
}

export default Header;