import React from 'react' ;
import '../../scss/BlogPage/blogsocial.scss';
import jsheadLg from '../../assets/language/blog-lang.json';

function BlogSocial ( {langID} ) {
   const lang = langID===true ? jsheadLg[1]: jsheadLg[0];

   return (
      <div className="blogsocial">
         <div className="blogsocial-center">
            <div className="blogsocial-container">
 
               <div className="blogsocial-cont">
                  <div className="blogsocial-cont-top">
                     <div className="blogsocial-cont-top-img">
                        <img className="image" src="icon\i_016.gif" alt=""/>
                     </div>
                     <div className="blogsocial-cont-top-span">
                        <a href="https://www.youtube.com/@user-jj1zo1dk2k" title="YouTube" target="_blank" rel="noreferrer">
                           <p>YouTube</p>
                        </a>
                     </div>
                  </div>
                  <div className="blogsocial-cont-down">
                     <p>{lang.menu_03}</p>
                  </div>
               </div>

               <div className="blogsocial-cont">
                  <div className="blogsocial-cont-top">
                     <div className="blogsocial-cont-top-img">
                        <img className="image" src="icon\i_014.gif" alt=""/>
                     </div>
                     <div className="blogsocial-cont-top-span">
                        <a href="https://www.instagram.com/kamorka_Programmista/" title="Instagram" target="_blank" rel="noreferrer">
                           <p>Instagram</p>
                        </a>
                     </div>
                  </div>
                  <div className="blogsocial-cont-down">
                     <p>{lang.menu_04}</p>
                  </div>
               </div>

               <div className="blogsocial-cont">
                  <div className="blogsocial-cont-top">
                     <div className="blogsocial-cont-top-img">
                        <img className="image" src="icon\i_011.gif" alt=""/>
                     </div>
                     <div className="blogsocial-cont-top-span">
                        <a href="https://t.me/+4ch39yeGdP8xYzMy" title="Telegram" target="_blank" rel="noreferrer">
                           <p>Telegram</p>
                        </a>
                     </div>
                  </div>
                  <div className="blogsocial-cont-down">
                     <p>{lang.menu_05}</p>
                  </div>
               </div>

               <div className="blogsocial-cont">
                  <div className="blogsocial-cont-top">
                     <div className="blogsocial-cont-top-img">
                        <img className="image" src="icon\i_013.gif" alt=""/>
                     </div>
                     <div className="blogsocial-cont-top-span">
                        <a href="https://vk.com/kamorkaprogrammista" title="Vkontakte" target="_blank" rel="noreferrer">
                           <p>Vkontakte</p>
                        </a>
                     </div>
                  </div>
                  <div className="blogsocial-cont-down">
                     <p>{lang.menu_06}</p>
                  </div>
               </div>

            </div>
         </div>
      </div>
   )
}

export default BlogSocial;