import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x005 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 2   // первая статья
   const i2 = 3   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil

   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>Битые ссылки вредны для SEO</span>
               <img className="image" src="../../content/st-005/001.jpg" alt=""/>
               
               <p className="sp-01"><b>Битая ссылка</b> – это гиперссылка на страницу, адрес которой был изменен или она была удалена с сайта. Иными словами, по данному адресу страницы больше нет, и при запросе к серверу он возвращает ошибку, напрмиер, «404 Not Found». Битыми ссылками можно назвать любые линки, при открытии которых появляется 404-ая страница. В свою очередь, сами битые URL можно разделить на внутренние, которые ведут, а точнее, не ведут, на страницы вашего сайта, и внешние, напрмиер, на страницы соц.сетей или дополнительные материалы (или сайты ваших рекламодателей). </p>
               <p className="sp-01">Причинами появления ссылок на несуществующие адреса являются:</p>
               <ul>
                  <li>удаление контента из-за его неактуальности</li>
                  <li>изменение URL-адреса</li>
                  <li>ошибки в настройке веб-сервера</li>
                  <li>технические ошибки на сайте</li>
                  <li>специальный/случайный ввод в адресную строку браузера несуществующих адресов</li>
               </ul>
               <p className="sp-03">Как утверждает сам гугл, битые ссылки, с точки зрения SEO, напрямую не влияют на ранжирование сайта в поисковой выдаче. Однако они косвенно влияют на поведенческие характеристики сайта, показатель отказов, уход пользователя с сайта. Поэтому оставлять их без внимания точно не стоит.</p>
               <p className="sp-01">Поисковые системы совершенствуют алгоритмы ранжирования и стараются выводить в результатах поиска наиболее качественные ресурсы, отвечающие запросам пользователей. Соответственно, владельцы сайтов вкладывают много сил, чтобы именно их ресурсы занимали наивысшие позиции. Давайте представим, что вы ищете беговые кроссовки из последней коллекции известного бренда. Вы кликаете по нужной ссылке в результатах поиска и попадаете не на страницу магазина с желаемым товаром, а на экран с печальной надписью «404 Not Found», «Страница не найдена», «Нет такой страницы» и все в таком духе. </p>
               <p className="sp-04">Бытие ссылки могут привести к снижению метрик «Время на сайте» или «Отказы» просто по причине поведенческих факторов и раздражения аудитории. Как результат - потеря трафика. Плюсом сюда можно отнести более медленная индексация поисковиками. У каждого поискового робота имеется собственный лимит по суммарному количеству запросов, который он может отправить в рамках одного домена (число запросов в секунду, например). Если битых URL очень много, то робот просто расходуют такой лимит на пустые страницы, а по-настоящему полезные страницы с контентом остаются без его внимания.</p>
               <p className="sp-02">Как найти битые ссылки?</p>
               <p className="sp-01">1. Самый просто вариант: <b>проверка битых ссылок в «Яндекс.Вебмастере» и «Google Search Console»</b></p>
               <p className="sp-01">В панелях «Яндекс.Вебмастер» и Google Search Console вы сможете увидеть страницы с 404-й ошибкой, которые были исключены из индекса, проанализировать, что явилось причиной их появления и принять решение о необходимости их исправления.</p>
               <p className="sp-01">Открываем «Яндекс.Вебмастер» и выбираем «Индексирование», далее «Статистика обхода». Теперь нам необходимо отфильтровать результаты таким образом, чтобы мы увидели только те страницы, которые отдают 404-ый код. Для этого кликаем по иконке с изображением воронки.</p>
               <p className="sp-01">Чтобы увидеть битые ссылки в Google Search Console, открываем группу отчетов «Индексирование» и выбираем пункт «Страницы». Откроется анализ покрытия, сразу листаем страницу вниз, пока не увидим «Почему эти страницы не индексируются». Это страницы, которые не удалось проиндексировать краулеру Google, они не могут появляться в результатах поиска Google.</p>
               <img className="image" src="../../content/st-005/002.jpg" alt=""/>

               <p className="sp-01">2. Второй по "простоте" способ, это <b>онлайн сервисы проверки битых ссылок на сайте</b></p>
               <p className="sp-01">Онлайн-сервисов достаточно много, но не все хорошо справляются со своей задачей.</p>
               <p className="sp-01"><b>Online Broken Link Checker</b>. Имеет бесплатную версию для проверки до 3000 URL, но с ограничениями и без возможности экспорта результатов. Коммерческая версия обладает более широкими возможностями, плюс есть возможность персональной настройки.</p>
               <p className="sp-01"><b>Deadlinkchecker</b>. Сервис дает проверить бесплатно до 2001-й ссылки.</p>
               <p className="sp-01"><b>Ahrefs</b>. Это удобный инструмент, который не только ищет битые бэклинки (внешние ссылки), но и пытается соотнести их с анкорами. В июне 2023 года инструмент работает только через VPN, но функционал полностью сохраняется.</p>
               <p className="sp-01"><b>Check My Links</b>. Удобное расширение для Google Chrome, позволяющее проверить страницу на наличие битых ссылок. Особенно удобно, если вы работаете сразу с большим количеством ссылок. Расширение подчеркивает работающие ссылки зеленым, а битые — красным. Коды ответов HTTP и полные URL-адреса неработающих ссылок расширение отправляет в консоль Google Chrome.</p>
               <p className="sp-01">3. Третий вариант - это <b>специальные программы</b>, как платыне, так и бесплатные. </p>
               <p className="sp-01"><b>Screaming Frog SEO Spider Tool</b>. Программа имеет широкий спектр настроек и анализирует много параметров, в том числе показывает, на каких страницах и какие ссылки битые. Подходит для анализа не очень больших сайтов, т.к. даже платная версия имеет ограничение по количеству сканируемых URL-адресов. В бесплатном варианте вы сможете проверить лишь 500 УРЛов.</p>
               <p className="sp-01"><b>Netpeak Spider</b>. Также очень удобная платная программа с большим набором параметров для анализа.</p>
               <p className="sp-01"><b>Xenu’s Link Sleuth</b>. Программа предназначена только для операционной системы Windows и имеет свои недостатки, но является бесплатной. Можно восользоваться еще одной - <b>Majento SiteAnalyzer</b>. Также бесплатная программа для анализа вашего сайта.</p>
               <img className="image" src="../../content/st-005/003.jpg" alt=""/>

               <p className="sp-02">Как исправить битые ссылки</p>
               <p className="sp-01">Самый банальный вариант это замена внутренних ссылок на работающие или их удаление. Для этого мы заходим на проблемную страницу, смотрим ее адрес и меняем путь ссылки на актуальный вариант. Если же страница вообще перестала быть актуальной, просто удаляем ее. Если общее количество сломанных URL не превышает нескольких десятков, то справится с этой задачей можно и вручную. Если же битых ссылок очень много, то стоит использовать тот же Netpeak Spider или Ahrefs.</p>
               <p className="sp-01">Но бывает так, что удаленная/перенесенная страница находилась в продвижении, приносила трафик или на нее идет ссылочный вес, который вы хотели бы сохранить и перенаправить на другой адрес. Тогда лучшим решением будет настройка 301-го редиректа с несуществующего адреса на актуальную страницу или максимально близкую по содержанию. </p>
               <p className="sp-03">При настройке 301 редиректа, последовательные переадресации не должны иметь более 3 переадресаций — это снижает передаваемый вес и замедляет скорость загрузки страниц.</p>
               <p className="sp-01">Вариант c 301-ым перенаправлением может пригодиться в том случае, когда:</p>
               <ul>
                  <li>меняется уровень вложенности веб-страниц</li>
                  <li>переименовываются разделы сайта</li>
                  <li>вносятся какие-либо иные изменения в его структурный элемент</li>
               </ul>
               <p className="sp-01">Влияют ли битые ссылки на ранжирование интернет-ресурса? Напрямую – нет, потому что не существует такого фактора ранжирования, когда за подобные ссылки вы однозначно получите понижение позиций. Однако это не значит, что битые ссылки на сайте никак не скажутся на результатах вашего продвижения.</p>
               <p className="sp-01">Битые ссылки можно сравнить с дверью, за которой пользователь натыкается на кирпичную стену. Это явно не добавляет ему расположения к сайту. А что если таких ссылок достаточно много и посетитель за одно посещение сталкивается с несколькими из них или ваша страница 404 ничего об это не говорит, а представлена в стандартном варианте?</p>
               <p className="sp-01">После такого большинство посетителей просто закроет вкладку и покинет сайт раз и навсегда, так как вы не открыли перед ним другие «двери». Упущенный клиент, упущенная выгода, прерванная сессия повлекут за собой ухудшение поведенческих факторов. А поведенческие факторы, в свою очередь, имеют весомое значение для ранжирования интернет-ресурса в поисковых системах.</p>
              

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
   )
}

export default Statya1x005