import React from "react";
import '../../../scss/BlogPage/blogtopmenu.scss';
import '../../../scss/BlogPage/blogstatya.scss';
import BlogOpis from "../BlogOpis";
import RaytMe from "../../MainPage/RaytMe";
import { Link } from 'react-router-dom';
import jsstaties from "../../../assets/content/mini-staties.json"

const Statya1x002 = ({langID}) => {
   window.scroll(0, 0)
   const i1 = 0   // первая статья
   const i2 = 2   // вторая статья

   const stat01 = jsstaties[i1]
   const stat02 = jsstaties[i2]
   const stim01 = "../" + jsstaties[i1].image
   const stim02 = "../" + jsstaties[i2].image
   const st01 = "/kamorkaprogrammista/" + jsstaties[i1].ssil
   const st02 = "/kamorkaprogrammista/" + jsstaties[i2].ssil


   return (
      <div className="blogstatya">
         <div className="blogstatya-center">
            <div className="container-st">
            <div className="st-top-menu">
                  <Link to="/kamorkaprogrammista">
                     <div className="st-top-menu-left">
                        <img className="image icon" src="../../icon/icon-02.svg" alt=""/>
                        <p>назад к списку статей</p>
                     </div>
                  </Link>
                  <Link to={st01}>
                     <div className="st-top-menu-right">
                        <p>следующая статья</p>
                        <img className="image icon" src="../../icon/icon-01.svg" alt=""/>
                     </div>
                  </Link>
               </div>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
               <span>VBA#02 - Полное меню VisualBasic</span>
               <img className="image" src="../../content/st-002/001.jpg" alt=""/>
               <p className="sp-01"><b>Microsoft Visual Basic</b> — язык программирования, разработанный корпорацией Microsoft. Как мы видим, от простого институтского проекта он переродился и плотно стал завязанным с Майкрософт.  Язык Visual Basic унаследовал дух, стиль и отчасти синтаксис своего предка — языка BASIC, у которого есть немало диалектов. При этом важной особенностью, унаследованной от предка, является простота и дружелюбность с пользователями, например инструменты для визуального проектирования пользовательского интерфейса, редактор кода с возможностью IntelliSense. IntelliSense — технология автодополнения Microsoft, наиболее известная в Microsoft Visual Studio, которая дописывает название функции при вводе начальных букв. Также реализована подсветка синтаксиса, и инструменты для отладки приложений.</p>
               <p className="sp-01">Для работы в VBA нам нужно для начала добавить пункт меню Разработчик на панель в Екселе:</p>
               <code><p className="sp-05">Файл –&gt; Параметры -&gt; Настроить ленту –&gt; ставим галочку напротив Разработчик</p></code>

               <img className="image" src="../../content/st-002/002.jpg" alt=""/>
               <p className="sp-02">Основные элементы:</p>
               <p className="sp-01"><b>Visual Basic</b> – непосредственно открывает редактор VBA</p>
               <p className="sp-01"><b>Макросы</b> – меню макросов, через нее можно вызвать уже готовый макрос или создать новый, и вас опять же перекинет в сам редактор</p>
               <p className="sp-01"><b>Запись макроса</b> – это кнопочка позволяет записывать макрос автоматически, в зависимости от ваших действияй в екселе. Часто используется для записи макросов редактирования ячеек или формы. Простыми словами, вы нажимаете на кнопочку, начинается запись макроса, вы что-то делаете в екселе, меняете цвет ячейки, заносите данные, складываете, вычитаете ячейки, и он сам пишет за вас макрос. На самом деле используется редко, для определенных вещей, о ктороых мы поговрим позже, но какие-то элементарные действия легко можно выполнить. Он, кстати, дублируется и внизу листа.</p>
               <p className="sp-01"><b>Относительные ссылки</b> – нужен для записи макросов кнопкой выше. Суть его в чем: например, при записи макроса у вас активная ячейка А1, и вы пишите макрос, меняя цвет ячейки А2 на желтый. Если эта кнопка не включена, то при активации макроса после записи, неважно, какая ячейка у вас активная в данный момент, цвет ячейки будет меняться именно А2. Но если кнопка включена, то если у вас будет активная ячейка не А1, а В1, то и цвет поменяется относительно нее, то есть В2 (как бы следующая). Определяет работу макроса (его воздействие) по координатам относительно активной ячейки. </p>

               <img className="image" src="../../content/st-002/003.jpg" alt=""/>
               <p className="sp-02">Безопасность макросов:</p>
               <p className="sp-01">Так как макросы это не только эффективный инструмент по улучшению своей работы, но и среда для создания вредоносных программ и т.д. (да-да, даже скачанный из интернета ексель может содержать вирус), Майкрософт снабдило файлы некоторыми средствами безопасности. </p>
               <p className="sp-01">Во-первых, если абстрагироваться от меню, то нужно запомнить, что в обычном екселе макросы не работают, и после написания макроса саму книгу надо сохранить в особом формате – ексель с поддержкой макросов (имеет расширение не xlsx, а xlsm). Помните, что если вы напишите макрос и сохраните его без поддержки макросов, то все что вы написали не сохраниться и исчезнет. Поэтому заранее сохраняйте книги в нужном формате. Ну и при скачке файлов из интернета обращайте внимание на формат, если вы понимаете, что какая-то скачанная таблица, по идее, макросы содержать не должна. </p>
               <p className="sp-01">Помимо этого, в Excel существует несколько режимов безопасности макросов, выбрать один их которых можно в меню параметров приложения. Вот эти режимы:</p>
               <p className="sp-01">Отключить все макросы без уведомления (<b>Disable all macros without notification</b>). Запрет на выполнение любых макросов. При открытии книги Excel не уведомляет о том, что эта книга содержит макросы, поэтому пользователь может не понять причину, по которой книга Excel не работает так, как должна.</p>
               <p className="sp-01">Отключить все макросы с уведомлением (<b>Disable all macros with notification</b>). Запрет на выполнение макросов. Однако, если в рабочей книге есть макрос, появится предупреждение о том, что макрос присутствует, но отключен.</p>
               <p className="sp-01">Отключить все макросы без цифровых подписей (<b>Disable all macros except digitally signed macros</b>). Разрешено выполнение макросов только из доверенных источников. Другими словами, если макрос написан кем-то знакомым и имеет определнную подпись (ее можно поставить в настройках в VBA) и цифровой сертификат. Это все необходимо в крупных компаниях и все это делает администратор вашей сети. Все прочие макросы выполняться не будут. При открытии книги Excel не уведомляет о том, что эта книга содержит макросы, поэтому пользователь может не понять причину, по которой книга Excel не работает так, как должна.</p>
               <p className="sp-01">Включить все макросы (<b>Enable all macros</b>). Разрешено выполнение всех макросов. При открытии книги Excel не появляется уведомление о том, что в ней содержатся макросы. Соответственно пользователь может не знать, что какой-либо макрос выполняется, пока открыт файл.</p>
               <p className="sp-01">Вариант "Отключить все макросы с уведомлением" – позволяет при открытии файла сделать выбор: разрешить запуск макросов или отключить их. При этом вверху листа появляется жёлтая полоса с уведомлением и кнопкой:</p>
               <p className="sp-01">Чтобы разрешить выполнение макросов нужно просто кликнуть по кнопке Включить содержимое (<b>Enable Content</b>).</p>
               <p className="sp-03">"Доверять доступ к объектной модели Ексель" – это означает разрешить менять свойства объектов Екселя (книгами, листами и т.д.) из кода, то есть програмным путем. Обычно используется для сторонних разработчиков, сторонних программ, а также для последующего администрирования. </p>

               <img className="image" src="../../content/st-002/004.jpg" alt=""/>
               <p className="sp-02">Элементы управления</p>
               <p className="sp-01"><b>Вставить</b> — выпадает менюха с различными элементами, которые можно добавить на лист. Чекбоксы, полосы прокрутки и так далее. Самый популярный элемент для вставки — это, конечно же, кнопка. При ее помощи можно, например, запустить написанный макрос.</p>
               <p className="sp-01">Элементы управления формы появились в Excel раньше всего и поддерживаются в предыдущих выпусках Excel (начиная с версии 5.0). Их также можно использовать на листах макросов XLM. Элементы управления формы используются, если вы хотите легко ссылаться на данные ячеек и взаимодействовать с ними, не используя код VBA, а также добавлять их на листы диаграмм. С помощью элементов управления формы можно также выполнять макросы. Можно назначить элементу управления существующий макрос либо создать или записать новый. Когда пользователь формы щелкает элемент управления, запускается макрос.</p>
               <p className="sp-01">Однако эти элементы управления невозможно добавить в пользовательские формы, использовать для управления событиями или изменить для запуска веб-сценариев на веб-страницах. Для этого есть более усовершенствованные <b>Элементы ActiveX</b>, которые можно использовать на формах листа (без кода VBA или с ним), а также на пользовательских формах VBA. Также если требуются более гибкие возможности, чем у элементов управления формы. Многочисленные свойства элементов ActiveX позволяют настраивать их внешний вид, поведение, шрифты и другие характеристики.</p>
               <p className="sp-01">Можно также управлять событиями, которые происходят при взаимодействии с элементом ActiveX. Например, можно выполнять различные действия в зависимости от того, какой элемент выбирается из списка, или отправлять запрос базе данных для заполнения поля со списком элементами при нажатии кнопки. Можно также создавать макросы, которые реагируют на события, связанные с элементами ActiveX. При выборе элемента управления будет выполняться код VBA, обрабатывающий все назначенные ему события.</p>
               <p className="sp-04">Важно: Не все элементы ActiveX можно использовать непосредственно на листах; некоторые из них можно применять только к пользовательским формам Visual Basic для приложений (VBA).</p>
               <p className="sp-01"><b>Режим конструктора</b> — если вы вставили кнопку, то вам нужно управлять ее свойствами и размерами, это возможно в режиме конструктора.</p>
               <p className="sp-01"><b>Свойства</b> — так же нам нужно управлять свойствами кнопок или другого объекта, в том числе листа Excel. Чтобы открыть меню свойств кнопки, запустите режим конструктора, а затем нажмите Свойства. А здесь уже выбор неплохой — цвет, шрифт, границы, печатать объект или нет и т.д. и т.п.</p>
               <p className="sp-01"><b>Просмотр кода</b> — во многом дублирует кнопку Visual Basic из раздела "Код", но при нажатии этой кнопки, вы сразу попадаете на код привязанный к объекту. А когда объектов много, это очень удобно!</p>
               <p className="sp-01"><b>Отобразить окно</b> — если вам не хватило имеющихся элементов управления, вы можете создать свой. Мы еще научимся создавать отдельные формы, окошки и т.д., и его можно будет потом просто привязать к этой кнопке.</p>
               <p className="sp-01"><b>XML</b> (Extensible Markup Language) — расширяемый язык разметки, разработанный специально для размещения информации в World Wide Web. Довольно часто применяется для разного рода обменов данными, как универсальный язык. Например, между сайтом и Excel, 1C и Access и так далее.</p>
               <p className="sp-01"><b>Кнопка Источник</b> — при помощи Excel мы можем перевести данные в XML и после использовать их в веб, например, на сайте. Если вы создали таблицу, то для нее нужно прописать карту XML. </p>
               <p className="sp-01"><b>Пакеты расширений</b> — по умолчанию подключен пакет Actions Pane 3.</p>
               <p className="sp-01"><b>Импорт/Экспорт</b> — если вы настроили импорт или экспорт данных из XML, вы можете передавать или получать данные этими кнопками.</p>
               <p className="sp-01">Простыми словами это работает примерно так – вы создаете таблицу с данными, потом ее вам нужно выгрузить в XML для загрузки в другую программу или на сайт, вы эту таблицу экспортируете, заодно создается карта XML. Кто не знает, XML это больше такой вордовский документ, а ексель это же ячейки, и вот карта она как бы прописывает, какая куда ячейка встанет, ее формат и т.д. А при выгрузке потом обратно в ексель, это карта позволит обратно собрать из XML в ексель по ячейкам. Наподобии инструкции по сборке. </p>
               <p className="sp-01"><b>Надстройки</b> - это отдельные подпрограммы, которые вы так же можете создать при помощи VBA, свои личные, и которые отличаются от обычного макроса тем, что это отдельная как бы программа, которая имеет отдельный файл. Ее проще передать от компьютера к компьютеру, она как бы ставится на ексель на другой комп как побочная программа, ее отсюда можно вызвать и т.д. Опять же, нет защиты от отключения макросов и т.д., не нужно код переписывать или перекопировать. В общем это как будто вы ваш макрос превращаете в отдельную программку, но поставить ее можно на ексель соответственно или другие программы офиса. Плюс — это отдельная кнопочка или форма и т.д. Они есть разные, от самого майкрософт, от екселя (там же и будут ваши из макроса), и сторонние.</p>

               <img className="image" src="../../content/st-002/005.jpg" alt=""/>
               <p className="sp-02">Меню VBA</p>
               <p className="sp-05">Файл (File) - это меню содержит команды для сохранения, импорта, экспорта и удаления файлов.</p>
               <p className="sp-01"><b>Сохранить</b> (Ctrl+S) - сохраняет текущий проект и все его модули и формы вместе с вашим документом.</p>
               <p className="sp-01"><b>Импорт файла</b> (Ctrl+M) - отображает диалоговое окно «Импорт файла». Позволяет добавить в проект существующий модуль или форму.</p>
               <p className="sp-01"><b>Экспорт файла</b> (Ctrl+E) - отображает диалоговое окно «Экспорт файла». Позволяет извлечь активный модуль или форму в отдельный внешний файл. Это отключено, если ни один модуль или форма не активны в Project Explorer.</p>
               <p className="sp-01"><b>Удалить модуль</b> — безвозвратно удаляет активный модуль или форму. Это отключено, если ни один модуль или форма не активны в Project Explorer. Вы не можете отменить это действие, поэтому сначала стоит экспортировать модули. Вас всегда будут спрашивать, хотите ли вы экспортировать, прежде чем элемент будет удален.</p>
               <p className="sp-01"><b>Распечатать</b> (Ctrl + P) - отображает диалоговое окно «Печать». Печатает активный модуль или форму.</p>
               <p className="sp-01"><b>Закрыть и вернуться в Excel</b> — закрывает редактор и возвращает вас в основное приложение. Редактор будет скрыт, но останется в памяти.</p>
               
               <p className="sp-05">Редактировать (Edit) - это меню содержит команды для форматирования и написания кода в редакторе кода.</p>
               <p className="sp-01"><b>Отменить ввод</b> (Ctrl + Z) - отменяет последнее действие. Вы также можете использовать сочетание клавиш (Alt + Backspace). Вы не можете отменить операцию Cut.</p>
               <p className="sp-01"><b>Повторить действие</b> — восстанавливает последнее действие. Вы можете восстановить до 20 правок.</p>
               <p className="sp-01"><b>Вырезать</b> (Ctrl+X) - удаляет элемент и помещает его в буфер обмена. Это отключено, если ничего не выбрано. Вы также можете использовать сочетание клавиш (Shift + Delete).</p>
               <p className="sp-01"><b>Копировать</b> (Ctrl C) - копирует элемент и помещает его в буфер обмена. Это отключено, если ничего не выбрано. Вы не можете отменить команду «Копировать» в окне «Код». Вы также можете использовать сочетание клавиш (Ctrl + Insert).</p>
               <p className="sp-01"><b>Вставить</b> (Ctrl+V) - вставляет элемент, который в данный момент находится в буфере обмена. Вы можете отменить команду «Вставить» в окне «Код». Вы также можете использовать сочетание клавиш (Shift + Ins).</p>
               <p className="sp-01"><b>Очистить</b> (Del) - удаляет текст по одному символу за раз.</p>
               <p className="sp-01"><b>Выделить все</b> (Ctrl+A) - выбирает весь код в активном окне кода или все элементы управления в форме.</p>
               <p className="sp-01"><b>Найти</b> (Ctrl+F) - отображает диалоговое окно «Найти», позволяющее искать определенный текст.</p>
               <p className="sp-01"><b>Найти Далее</b> (F3) - находит следующее вхождение последнего искомого элемента. Отображает диалоговое окно «Найти», если вы не открыли диалоговое окно «Найти».</p>
               <p className="sp-01"><b>Заменить</b> (Ctrl+H) - отображает диалоговое окно «Заменить», позволяющее искать и заменять определенный текст.</p>
               <p className="sp-04">Для редактирования кода: <br></br>
               Отступ (Вкладка) - смещает все строки (в текущем блоке) на следующую позицию табуляции.<br></br>
               Выступ (Shift+Tab) - смещает все строки (в текущем блоке) на предыдущую позицию табуляции.</p>
               <p className="sp-04">
               Для подсказок и помощи:<br></br>
               Список свойств/методов (Ctrl + J) - это отображает всплывающую подсказку, показывающую список методов и свойств. Это называется Auto List Members в диалоговом окне «Параметры». Вы также можете использовать сочетание клавиш (Ctrl + пробел). По типу помошника для отображения свойств элементов и т.д. Поможет в будущем. Также ест на панели быстрого доступа. <br></br>
               Список констант (Ctrl + Shift + J) - это отображает всплывающую подсказку, показывающую список встроенных констант перечисления. <br></br>
               Краткая информация (Ctrl + I) - это отображает всплывающую подсказку IntelliSense, показывающую подпись для метода, содержащего курсор. Это называется Auto Quick Info в диалоговом окне Options.<br></br>
               Информация о параметрах (Ctrl+Shift+I) - это отображает всплывающую подсказку, показывающую список аргументов для активного метода. Курсор должен стоять перед открывающей скобкой, чтобы это работало.<br></br>
               Завершить слово (Ctrl+Пробел) - заполняет оставшуюся часть слова, которое вы вводите, после того, как вы введете достаточно символов, чтобы редактор мог определить правильное слово.
               </p>
               <p className="sp-04">
               Закладки для кода: <br></br>
               Закладки — это меню, которое содержит команды: "Переключить закладку", "Следующая закладка", "Предыдущая закладка" и "Очистить все закладки".
               </p>

               <img className="image" src="../../content/st-002/006.jpg" alt=""/>
               <p className="sp-05">Вид (View) - это меню содержит команды для отображения и скрытия различных компонентов.</p>
               <p className="sp-01"><b>Код</b> (F7) - отображает окно кода для записи, отображения и редактирования кода VBA.</p>
               <p className="sp-01"><b>Объект</b> (Shift+F7) - отображает активный элемент.</p>
               <p className="sp-01"><b>Определение</b> (Shift+F2) - отображает место в коде, где определена переменная, подпрограмма или функция. Если определение находится в библиотеке, на которую ссылаются, отображается обозреватель объектов.</p>
               <p className="sp-01"><b>Последняя позиция</b> (Ctrl+Shift+F2) - позволяет быстро перейти к предыдущему местоположению в вашем коде. Позиция будет циклически проходить через последние 8 местоположений.</p>
               <p className="sp-01"><b>Браузер объектов</b> (F2) - отображает окно обозревателя объектов, если оно не отображается.</p>
               <p className="sp-01"><b>Проводник проекта</b> (Ctrl+R) - отображает окно Project Explorer, если оно не отображается.</p>
               <p className="sp-01"><b>Окно свойств</b> (F4) - Отображает окно свойств, если оно не отображается.</p>
               <p className="sp-01"><b>Панель инструментов</b> — отображает плавающие окна панели инструментов, когда у вас выбрана пользовательская форма.</p>
               <p className="sp-01"><b>Порядок вкладок</b> - отображает диалоговое окно «Порядок вкладок», когда у вас выбрана пользовательская форма. Позволяет изменить порядок активации элементов управления.</p>
               <p className="sp-01"><b>Панели инструментов</b> — это меню содержит флажки: Отладка, Редактировать, Стандартная, Пользовательская форма, Настроить.</p>
               <p className="sp-01"><b>Microsoft Excel</b> (Alt+F11) - переключает представление между окном приложения Microsoft Excel и редактором VBA.</p>

               <p className="sp-05">Вставить (Insert) - это меню содержит команды для вставки подпрограмм, функций и различных типов модулей.</p>
               <p className="sp-01"><b>Процедура</b> — отображает диалоговое окно « Добавить процедуру ». Позволяет вставить новую подпрограмму, функцию или процедуру свойств в активный модуль.</p>
               <p className="sp-01"><b>Пользовательская форма</b> — вставляет пользовательскую форму в активный проект.</p>
               <p className="sp-01"><b>Модуль</b> — вставляет новый стандартный модуль кода в активный проект.</p>
               <p className="sp-01"><b>Модуль класса</b> — вставляет новый модуль класса в активный проект.</p>
               <p className="sp-01"><b>Файл</b> — отображает диалоговое окно «Вставить файл». Позволяет вставлять коды текстового файла в окно кода.</p>

               <img className="image" src="../../content/st-002/007.jpg" alt=""/>
               <p className="sp-05">Формат (Format) - это меню содержит команды для разработки и размещения элементов управления в пользовательской форме.</p>
               <p className="sp-01"><b>Выравнивание</b> — отображает подменю команд для выравнивания нескольких элементов управления UserForm. Это меню содержит команды: Слева, Центры, Справа, Верх, Середина, Низ, В сетку. Это доступно только в том случае, если у вас выбрана пользовательская форма.</p>
               <p className="sp-01"><b>Make Same Size</b> —	 отображает подменю команд для изменения размера нескольких элементов управления UserForm. Это меню содержит команды: Ширина, Высота, Обе. Это доступно только в том случае, если у вас выбрана пользовательская форма.</p>
               <p className="sp-01"><b>Размер по размеру</b> — изменяет высоту и ширину объекта в соответствии с его содержимым.</p>
               <p className="sp-01"><b>Размер по сетке</b> — регулирует высоту и ширину выбранного объекта, чтобы он соответствовал ближайшим линиям сетки в форме. Вы можете изменить размер сетки на вкладке «Общие» диалогового окна «Параметры».</p>
               <p className="sp-01"><b>Горизонтальный интервал</b> - отображает подменю команд для одновременной настройки горизонтального интервала между несколькими элементами управления UserForm. Это меню содержит команды: Сделать равным, Увеличить, Уменьшить, Удалить.</p>
               <p className="sp-01"><b>Интервал по вертикали</b> — отображает подменю команд для одновременной настройки интервала по вертикали между несколькими элементами управления пользовательской формы. Это меню содержит команды: Сделать равным, Увеличить, Уменьшить, Удалить.</p>
               <p className="sp-01"><b>Центрировать в форме</b> — отображает подменю команд для центрирования одного или нескольких выбранных элементов управления пользовательской формы по центральным осям пользовательской формы. Это меню содержит команды: Горизонтально, Вертикально.</p>
               <p className="sp-01"><b>Упорядочить кнопки</b> — отображает подменю для размещения одной или нескольких выбранных кнопок внизу или по краю пользовательской формы. Это меню содержит команды: Снизу, Справа.</p>
               <p className="sp-01"><b>Группа</b> - создает группу выбранных объектов, которые затем можно перемещать и форматировать как единое целое.</p>
               <p className="sp-01"><b>Разгруппировать</b> — разгруппировать ранее сгруппированные объекты.</p>
               <p className="sp-01"><b>Order</b> — отображает подменю для изменения порядка выбранного элемента управления UserForm. Это меню содержит команды: «На передний план», «На задний план», «Вперед» и «Назад».</p>

               <p className="sp-05">Отладка (Debug) - это меню содержит команды для отладки и пошагового выполнения кода.</p>
               <p className="sp-01"><b>Compile VBAProject</b> — компилирует ваш проект и проверяет наличие синтаксических ошибок.</p>
               <p className="sp-04">Пошаговое выполнение программы для отладки:<br></br>
               Шагнуть внутрь (F8) - выполняет код по одному оператору за раз.<br></br>
               Перешагнуть (Shift+F8) - выполняет следующий оператор кода. Единственная разница между этим и Step Into заключается в том, что текущий оператор содержит вызов процедуры. Доступно только в режиме перерыва.<br></br>
               Шаг в сторону (Ctrl+Shift+F8) - выполняет оставшиеся строки функции, в которой находится текущая точка выполнения. Следующим отображаемым оператором является оператор, следующий за вызовом процедуры. Весь код выполняется между текущей и конечной точками выполнения. Доступно только в режиме перерыва.<br></br>
               Беги к курсору (Ctrl + F8) - в режиме разработки это выбирает оператор ниже в вашем коде, где вы хотите остановить выполнение. Ваше приложение будет запущено от текущего оператора к выбранному оператору, а текущая строка индикатора маржи исполнения появится на панели индикатора маржи. Это полезно, чтобы избежать пошагового выполнения ненужного кода.</p>
               <p className="sp-01"><b>Добавить отслеживание</b> — отображает диалоговое окно « Добавить отслеживание ». Позволяет добавлять выражения наблюдения в окно Watches.</p>
               <p className="sp-01"><b>Редактировать часы </b> (Ctrl+W) - отображает диалоговое окно « Добавить контроль ». Позволяет редактировать или удалять выражения просмотра.</p>
               <p className="sp-01"><b>Быстрый просмотр</b> (Shift + F9) - отображает быстрый просмотрчат. Позволяет отображать текущее значение выбранного выражения.</p>
               <p className="sp-01"><b>Переключить точку останова</b> (F9) - устанавливает или удаляет точку останова в текущей строке. Вы не можете установить точку останова на строках, содержащих комментарии, операторы объявления или пустые строки.</p>
               <p className="sp-01"><b>Очистить все точки останова</b> (Ctrl+Shift+F9) - удаляет все точки останова в активном проекте. Ваш код все еще может быть прерван, если у вас есть какие-либо выражения наблюдения или выбран параметр «Разрывать все ошибки».</p>
               <p className="sp-01"><b>Установить следующее заявление</b> (Ctrl + F9) - перемещает точку выполнения на другую строку кода. Эта строка кода может быть до или после выбранного оператора. Вы можете использовать это только внутри одной и той же подпрограммы или функции. Вы также можете перетащить индикатор поля Current Execution Line на строку кода, которую хотите выполнить.</p>
               <p className="sp-01"><b>Показать следующий оператор</b> — выделяет следующий оператор, который будет выполнен.</p>

               <img className="image" src="../../content/st-002/008.jpg" alt=""/>
               <p className="sp-05">Действие (Run) - это меню содержит команды для тестирования кода и отображения пользовательских форм.</p>
               <p className="sp-01"><b>Запустить Sub/Userform</b> (F5) - запускает текущую процедуру, если курсор находится в процедуре, или запускает форму, если форма в данный момент активна. Эта команда становится командой «Продолжить», когда вы находитесь в режиме приостановки. Если окно кода или пользовательская форма неактивны, эта команда становится командой «Выполнить макрос». Пока вы выполняете свой код, это становится командой «Продолжить».</p>
               <p className="sp-01"><b>Перерыв</b> (Ctrl + Перерыв) - останавливает выполнение программы во время ее выполнения и переключается в режим приостановки.</p>
               <p className="sp-01"><b>Сброс</b> — очищает стек вызовов и очищает переменные уровня модуля.</p>
               <p className="sp-01"><b>Режим дизайна</b> - Переключает режим дизайна. Режим разработки — это время, в течение которого код из проекта не выполняется и события из хоста или проекта не выполняются. Вы можете выйти из режима разработки, выполнив макрос или используя окно Immediate.</p>
               <p className="sp-05"><b></b>Инструменты (Tools) - это меню содержит команды для доступа к дополнительным ссылкам и параметрам пользователя.</p>
               <p className="sp-01"><b>Ссылки</b> — отображает диалоговое окно Ссылки . Позволяет выбрать объекты другого приложения, которые должны быть доступны в вашем коде, установив ссылку на библиотеку объектов этого приложения. Например: подключаемые модули для взаимодествия из макроса с аутлук</p>
               <p className="sp-01"><b>Дополнительные элементы управления</b> — отображает диалоговое окно « Дополнительные элементы управления », в котором можно добавить элементы управления или вставляемые объекты (например, документ Microsoft Word).</p>
               <p className="sp-01"><b>Макросы</b> — отображает диалоговое окно «Макросы». Позволяет запускать, редактировать или удалять выбранный макрос.</p>
               <p className="sp-01"><b>Параметры</b> — отображает диалоговое окно « Параметры ».</p>
               <p className="sp-01"><b>Свойства VBAProject</b> — отображает диалоговое окно VBAProject.</p>
               <p className="sp-01"><b>Цифровая подпись</b> — отображает диалоговое окно «Цифровая подпись».</p>

               <p className="sp-05">Надстройки (add-Ins) - это меню содержит команды для загрузки и выгрузки дополнительных надстроек COM.</p>
               <p className="sp-01"><b>Диспетчер надстроек</b> — отображает диалоговое окно «Диспетчер надстроек». Позволяет регистрировать, загружать и выгружать надстройки и устанавливать режим их загрузки.</p>

               <p className="sp-05">Окно (Window) - это меню содержит команды для быстрого переключения между всеми открытыми окнами.</p>
               <p className="sp-01"><b>Разделить</b> — включает разделение окна кода пополам по горизонтали. Доступно только при активном окне кода.</p>
               <p className="sp-01"><b>Мозаика по горизонтали</b> — размещает все открытые окна документов в вашем проекте по горизонтали.</p>
               <p className="sp-01"><b>Мозаика по вертикали</b> — размещает все открытые окна документов в вашем проекте по вертикали.</p>
               <p className="sp-01"><b>Каскад</b> — расположение всех открытых окон документов в вашем проекте таким образом, что они перекрываются каскадом.</p>
               <p className="sp-01"><b>Упорядочить значки</b> — упорядочивает значки любых окон документов, которые вы свернули, аккуратно в левом нижнем углу окна.</p>
               <p className="sp-01"><b>Список окон</b> — отображает все открытые окна документов вашего проекта.</p>

               <p className="sp-05">Помощь - это меню содержит команды для доступа к встроенным файлам справки.</p>
               <p className="sp-01"><b>Справка Microsoft Visual Basic</b> (F1) - отображает окно справки Excel.</p>
               <p className="sp-01"><b>MSDN в Интернете</b> — отображает домашнюю страницу msdn (docs.microsoft.com).</p>
               <p className="sp-01"><b>О Microsoft Visual Basic</b> — отображает диалоговое окно «О Microsoft Visual Basic».</p>

   {/* - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            </div>
         </div>

         <div className="blogspisok-center-01">
            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim01} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat01.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat01.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat01.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st01} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="blogspisok-statya-01">
               <div className="blogspisok-statya-img">
                  <img className="image" src={stim02} alt=""/>
               </div>
               <div className="blogspisok-statya-text">
                  <div className="statya-text-span">
                     <p>{stat02.title}</p>
                  </div>
                  <div className="statya-text-opis">
                     <p>{stat02.description}</p>
                  </div>
                  <div className="statya-text-snoska">
                     <div className="statya-text-snoska-hesh">
                        <p>{stat02.hesh}</p>
                     </div>
                     <div className="statya-text-snoska-ssil" 
                     // onClick={() => setSearchNumstat(ssil)}
                     >
                        <Link to={st02} target="_blank" ><p>продолжение...</p></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         

         <BlogOpis langID={langID}/>
         <RaytMe langID={langID}/>
      </div>
      
      
   )
}

export default Statya1x002